<app-video-player
  [title]="currentTitle"
  [backUrl]="backUrl"
  [source]="currentCourseUrl"
  [enablePrev]="enablePrev"
  [enableNext]="enableNext"
  (prev)="playPrev()"
  (next)="playNext()"
  (ended)="playNext()"
  >
</app-video-player>
