<div class="modules-login position-relative" style="background-color: black;">
    <img src="assets/inicioSesion/IMAGEN-DE-FONDO.png" class="w-100 h-auto"
        alt="Encuentra balance y disfruta la vida con">
    <div class="d-flex justify-content-center position-absolute" style="top: 8vh; left: 0; right: 0;">
        <div class="container">
            <div class="row justify-content-start">
                <div class="col-lg-7 col-md-7 d-none d-sm-block justify-content-center alinear_centro mt-10">
                    <div class="pt-5">
                        <hr class="linea_azul" style="width: 50%; float: right;">
                        <p class="register_login_main_title">Cambia tu contraseña y sigue navegando el universo Kuaá
                        </p>
                    </div>
                    <hr class="linea_azul">
                </div>
                <div class="col-lg-5 col-md-5 col-sm-12 pb-5 mt-10">


                    <div class="register_login_form">
                        <form [formGroup]="formRegister">
                            <fieldset>
                                <legend class="register_login_form_main_title">Recupera tu contraseña</legend>
                                <div class="form-group pb-3">
                                    <div class="col-12">
                                        <input id="newPassword" name="newPassword" type="password"
                                            placeholder="Contraseña" class="form-control input_poopins_black"
                                            formControlName="newPassword" (change)="checkPassword()">
                                        <small id="errorMessage" style="display: none;">
                                            <p class="mb-2" class="text-danger">Valide la contraseña registrada.</p>
                                            <ul>
                                                <li id="length" class="text-danger">Longitud mínimo de 9 caracteres.
                                                </li>
                                                <li id="upperCase" class="text-danger">Mínimo una letra mayúscula.
                                                </li>
                                                <li id="lowerCase" class="text-danger">Mínimo una letra minúscula.
                                                </li>
                                                <li id="number" class="text-danger">Mínimo un número.</li>
                                                <li id="caracter" class="text-danger">Mínimo un carácter especial
                                                    (<b>!#$%&/()=?¡¿~&gt;&lt;/+</b>)</li>
                                            </ul>
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group pb-3">
                                    <div class="col-12">
                                        <input id="confirmNewPassword" name="confirmNewPassword" type="password"
                                            placeholder="Confirmar contraseña" class="form-control input_poopins_black"
                                            formControlName="confirmNewPassword" (change)="passwordValidator()">
                                        <div>
                                            <small id="match" style="display: none;"
                                                class="text-danger">Las contraseñas no coinciden</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group pt-3">
                                    <div class="col-12">
                                        <a class="btn btn-primary btn_poopins_black" style="letter-spacing: 1px;"
                                            (click)="resetPassword()">CAMBIAR CONTRASEÑA</a>
                                    </div>
                                </div>

                                <div class="form-group pt-3 pb-3 row">
                                    <div class="col-12">
                                        <label routerLink="/login" style="cursor: pointer;" class="control-label"
                                            for="textinput">Volver</label>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>