<div *ngIf="serie" class="modules">
  <div class="container-fluid px-0">

    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="preview-hero-video carousel-item active">
          <img src="{{serie.banner2}}" class="img-fluid" style="width: 100% !important;" >
          <div class="btn-back carousel-caption d-md-block" style="top: 0 !important; left: 0 !important; ">
            <a routerLink="{{url}}"> <img id="back" src="/assets/back_icon.png"></a>
          </div>
          <a *ngIf="serie && serie.objListSerieContent" [routerLink]="['/home/series', this.serieId, 'videos', serie.objListSerieContent[0].contentId]" class="btn btn-link carousel-caption preview-hero-video__play">
            <span class="material-icons play-video">play_circle_outline</span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="div container-fluid main-font-family pt-5">
    <div class="container">
      <div class="row mb-5">
        <div class="col-md-8">
          <div class="row">
            <div class="col-12 mb-3">
              <h1 class="sections_title">{{serie.title}}</h1>
            </div>
            <div class="col-12">
              <p *ngIf="objCourseDto.sinopsis; else descSerie" style="height: 230px; overflow:hidden;">
                {{objCourseDto.sinopsis}}
              </p>
              <ng-template #descSerie>
                <div style="text-align: justify;">
                  <p *ngFor="let s of sinopsis"> {{s}}</p>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="col-12 mb-3 d-flex">
              <h1 class="sections_title facilita_title" style="margin-top: 2.5rem!important;">Facilita:</h1>
            </div>
            <div class="col-12 d-flex">
              <ul>
                <li *ngFor="let instructor of instructorsName"><a class="pe-auto" title="Conocer guía" data-toggle="tooltip" routerLink="/home/instructor/{{instructor.id}}/{{idVideoSerie}}" routerLinkActive="router-link-active">{{instructor.name}}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="objCourseDto.contentType != 'solo'">
      <div *ngIf="seasonsNumber.length >= 1">
        <div class="row">
          <div class="col-12">
            <div class="sec-center col-12 col-sm-6 col-md-4" (click)="toggleDropdown(e)">
              <input class="dropdown" type="checkbox" id="dropdown" name="dropdown" [checked]="dropdownOpen"
                (change)="toggleDropdown()" />
              <label class="for-dropdown p-3" for="dropdown">
                SESIÓN {{seasonName}}
                <i class="fa fa-sort-desc" aria-hidden="true"></i>
              </label>
              <div class="section-dropdown" id="allSeasons">
                <div class="season" *ngFor="let season of seasonsNumber"
                  (click)="loadSeasonChapter(season, true, true)">
                  SESIÓN {{season}} </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<ng-template #withoutSeasons> <b>No hay temporadas disponibles</b> </ng-template>-->
    </div>
    <div class="container mb-5">
      <div class="row mt-5" *ngFor="let chapter of seasonChapter; let i = index">
        <div class="col-12">
          <div class="card card-chapter">
            <div class="card-chapter__banner">
              <img [src]="chapter.banner2" alt="" />
              <a [routerLink]="['/home/series', this.serieId, 'videos', chapter.id]" class="btn btn-link card-chapter__play">
                <span class="material-icons play-video">play_circle_outline</span>
              </a>
            </div>
            <div class="card-body">
              <h3 class="card-title">{{chapter.title}}</h3>
              <p class="card-text">{{ chapter.type === 'Video' ? chapter.sinopsis : chapter.description }}</p>
              <span class="card-chapter__duration">{{chapter.durationContent}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 ps-5 pe-5">
      <div class="row">
        <div class="col-12 justify-content-start  ">
          <h1 class="sections_title"> Contenido <b>relacionado</b></h1>
        </div>
      </div>

      <app-slider *ngIf="slides"
        [slides]="slides"
        [navigation]="true"
      >
      </app-slider>
    </div>
  </div>
</div>
