import { InstructorDto } from 'src/app/model/dto/instructor/instructor-dto'

import { Component, NgZone, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'

import { CategoryDto } from '../../../model/dto/category/category-dto'
import { CourseDto } from '../../../model/dto/course/course-dto'
import { ModuleDto } from '../../../model/dto/module/module-dto'
import { TokenService } from '../../../service/authorization/token.service'
import { CategoryService } from '../../../service/category/category.service'
import { CourseService } from '../../../service/course/course.service'
import { InstructorService } from '../../../service/instructor/instructor.service'
import { SerieService } from '../../../service/serie/serie.service'
import { AuthService } from '../../../shared/services/auth.service'
import { ICarouselItem } from '../../shared/carousel/Icarousel-item.metadata'

@Component({
  selector: 'app-hostel',
  templateUrl: './hostel.component.html',
  styleUrls: ['./hostel.custom.css']
})
export class HostelComponent implements OnInit {


  dataCarousel: any[] = [];
  objListCourses: any[] = [];
  objListSeries: any[] = [];
  objListModules: ModuleDto[] = [];
  objListInstructor: any[] = [];
  objListCategories: CategoryDto[] = [];
  objListCatAlimens: CategoryDto[] = [];
  objListCatArte: CategoryDto[] = [];
  objListCatMenteCuerpo: CategoryDto[] = [];
  public carouselData: ICarouselItem[] = [];

  @ViewChild('myCarousel') myCarousel;


  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    private objCategoryService: CategoryService,
    private objCourseService: CourseService,
    private objInstructorService: InstructorService,
    private objSerieService: SerieService,
    private tokenService: TokenService
  ) {
    this.getDataCarousel()
  }

  ngOnInit(): void {
    this.loadCoursesDefault();
    /*
    this.loadCategory();
    this.loadCoursesByCatCAA();
    this.loadCoursesByCatCAB();
    this.loadCoursesByCatCAC();
    */
  }

  getDataCarousel(){
    this.dataCarousel = [
      {
        BackgroungImage: 'https://storage.googleapis.com/publickuaa/static/img/banner/Come-y-salva-el-planeta.png',
        urlImage: 'https://storage.googleapis.com/publickuaa/static/img/banner/TEXTO-COME-Y-SALVA-PLANETA.png',
        title: 'Aprovecha al máximo los ingredientes que tienes en casa y juntos pongámosle fin al desperdicio cocinando los platos más deliciosos.',
        btn: '¡Ver ahora!',
        href: '/home/preview/5985fbc0-4d52-4e48-8763-c197ee701728/0',
        class: 'active',
        alt: 'Come y salva el planeta',
        new: true
      },
      {
        BackgroungImage: 'https://storage.googleapis.com/publickuaa/static/img/banner/MUJER-RADIANTE.jpg',
        urlImage: 'https://storage.googleapis.com/publickuaa/static/img/banner/TEXTO-M-RADIANTE.png',
        title: 'Redescubre tu rol como mujer empoderada en tiempos de convulsión, a través de diferentes prácticas de meditación, respiración y sanación energética.',
        btn: '¡Ver ahora!',
        href: '/home/preview/f8219d3d-3c41-4719-b6fc-c18f7dabbb16/0',
        class: '',
        alt: 'Mujer Radiante',
        new: false
      },
      {
        BackgroungImage: 'https://storage.googleapis.com/publickuaa/static/img/banner/SECRETOS.jpg',
        urlImage: 'https://storage.googleapis.com/publickuaa/static/img/banner/TEXTO-SECRETOS.png',
        title: '¿Qué estás comiendo? Conoce los ingredientes, el origen y el contenido real de lo que llevas a tu boca. Guía para leer el etiquetado de los alimentos.',
        btn: '¡Ver ahora!',
        href: '/home/preview/9ae7d3c0-5c43-46cf-8882-5c9cb3575b51/0',
        class: '',
        alt: 'Secretos de las etiquetas nutricionales',
        new: false
      },
      {
        BackgroungImage: 'https://storage.googleapis.com/publickuaa/static/img/banner/EXPERIENCIAS-PARA-TI.jpeg',
        urlImage: 'https://storage.googleapis.com/publickuaa/static/img/banner/TEXTO-EXPERIENCIAS.png',
        title: 'Explora y construye tu propio camino hacia la felicidad, el balance y el bienestar. ',
        btn: '¡Navega ahora!',
        href: '/home#exploByModules',
        class: '',
        alt: 'Experiencias pensadas para ti',
        new: false
      },

    ]
  }

  loadCoursesDefault(): void{
    const sToken = this.tokenService.getToken();
    this.objCourseService.getCoursesDefault( sToken ).subscribe(
      data => {
          this.objListCourses = data;
          let counter = 0;
          this.objListCourses.forEach(element => {
             //element.position = "transform: translateX( "+ counter + "px); width: 140px;";
             element.position = "transform: translateX( "+ counter + "px); width: 200px;";
             counter = counter+205;
          });

        },
        err => {
          console.error(err);
        }
    );
  };

  loadCategory(): void {
    const sToken = this.tokenService.getToken();
    this.objCategoryService.getCategories(sToken).subscribe(
      data => {
        this.objListCategories = data
        this.carouselData.push({
          id: 0,
          title: {
            first: this.objListCategories[3].title,
            second: this.objListCategories[3].title
          },
          subtitle: this.objListCategories[3].subtitle,
          link: '/',
          image: this.objListCategories[3].banner2
        },
          {
            id: 1,
            title: {
              first: this.objListCategories[2].title,
              second: this.objListCategories[2].title
            },
            subtitle: this.objListCategories[2].subtitle,
            link: '/',
            image: this.objListCategories[2].banner2
          }
          , {
            id: 2,
            title: {
              first: this.objListCategories[1].title,
              second: this.objListCategories[1].title
            },
            subtitle: this.objListCategories[1].subtitle,
            link: '/',
            image: this.objListCategories[1].banner2
          }, {
          id: 3,
          title: {
            first: this.objListCategories[0].title,
            second: this.objListCategories[0].title
          },
          subtitle: this.objListCategories[0].subtitle,
          link: '/',
          image: this.objListCategories[0].banner2
        }
        )
      },
      err => {
        console.error(err);
      }
    );
  };

  loadCoursesByCatCAA(): void {
    const sToken = this.tokenService.getToken();
    this.objCourseService.getByCategory(sToken, 'f5557664-8332-45f8-90e1-7e9b3f371272').subscribe(
      data => {
        this.objListCatAlimens = data
      },
      err => {
        console.error(err);
      }
    );
  };

  loadCoursesByCatCAB(): void {
    const sToken = this.tokenService.getToken();
    this.objCourseService.getByCategory(sToken, '892ce5e7-8835-4cb9-88ee-c567fc3f1813').subscribe(
      data => {
        this.objListCatArte = data
      },
      err => {
        console.error(err);
      }
    );
  };


  loadCoursesByCatCAC(): void {
    const sToken = this.tokenService.getToken();
    this.objCourseService.getByCategory(sToken, '3b44bbb9-6fd5-45a2-996e-fdc7bfa830df').subscribe(
      data => {
        this.objListCatMenteCuerpo = data
      },
      err => {
        console.error(err);
      }
    );
  };

  onMouseOver(item): void {
  }


  /*
  handleCarouselEvents(event) {
   if (event.name === "transitionend") {
       this.myCarouselThumbs.select(this.myCarousel.slideCounter)
   }
 }
 */

  onLogOut(): void {
    this.tokenService.logOut();
    window.location.reload();
    this.router.navigate(['/']);
  }

}

