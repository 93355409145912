<div class="modules-login position-relative" style="background-color: black;">
  <img src="assets/inicioSesion/IMAGEN-DE-FONDO.png" class="w-100 background-image h-auto"
    alt="Encuentra balance y disfruta la vida con Kuaá">
  <img src="assets/inicioSesion/IMAGEN-DE-FONDO-MOBILE.jpg" class="w-100 background-image-mobile h-auto"
    alt="Encuentra balance y disfruta la vida con Kuaá">
  <div class="d-flex justify-content-center position-absolute main-menu">
    <div class="container">
      <div class="row justify-content-start">
        <div class="col-xl-7 col-lg-7 col-md-6 col-sm-12 d-none d-sm-block justify-content-center alinear_centro mt-10">
          <div class="pt-5">
            <hr class="linea_azul" style="width: 50%; float: right;">
            <p class="register_login_main_title">
              ¡Estás de vuelta! <br><br>

              Ingresa y disfruta del contenido creado para ti.
            </p>
          </div>
          <hr class="linea_azul">
        </div>
        <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 mt-10">
          <div class="register_login_form">
            <fieldset>

              <legend class="register_login_form_main_title">INICIA SESIÓN</legend>

              <div class="form-group pt-3 pb-3">
                <div class="col-12">
                  <input type="text" placeholder="Correo electrónico" class="form-control input_poopins_black" #userName
                    require>
                </div>
              </div>

              <div class="form-group pb-3">
                <div class="col-12">
                  <input type="password" id="password" placeholder="Contraseña" class="form-control input_poopins_black" #userPassword
                    required>
                </div>
                <div class="col-12 pt-2">
                  <input type="checkbox" id="showPassword" name="showPassword" class="form-check-input password_input" (click)="showPassword()" >
                  <span style="color: #14b8ec; margin-left: 5px;">
                    Mostrar contraseña
                  </span>
                </div>
              </div>

              <div class="form-group">
                <div class="col-12">
                  <button class="btn btn-primary btn_poopins_black"
                    (click)="authService.SignIn(userName.value, userPassword.value)">INICIAR SESIÓN</button>
                </div>
              </div>

              <!--<div class="form-group pt-2">
                  <div class="col-12">
                    <label class="col-12 col-md-4 control-label-p text-center">O continúa con</label>
                    <label class="col-6 col-md-4 control-label-p text-center" (click)="authService.GoogleAuth()" for="textinput"><img style="margin-right: 10px; width: 20px;" src="/assets/google_icon.png"> Google</label>
                    <label class="col-6 col-md-4 control-label-p text-center" for="textinput"><img style="margin-right: 10px; width: 20px;" src="/assets/facebook_icon.png">Facebook</label>
                  </div>
                </div>-->

              <div class="form-group pt-3">
                <div class="col-12">
                  <label class="col-12 control-label" for="textinput" (click)="goTo('/login/forgot-password')"
                    style="cursor: pointer;">Olvidé mi contraseña</label>
                </div>
              </div>

              <div class="form-group pb-3 mt-3">
                <div class="col-12">
                  <label class="col-12 control-label" for="textinput">¿Es la primera vez que nos visitas? <br> <span
                      class="col-12 control-label-p" style="cursor: pointer;" for="textinput"
                      (click)="goTo('/login/signup')">Crea tu cuenta</span> </label>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
