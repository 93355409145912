import { DateTime } from 'luxon'
import { ToastrService } from 'ngx-toastr'
import { UserService } from 'src/app/service/payments/user.service'
import Swal from 'sweetalert2'

import { Component, OnInit } from '@angular/core'

import { Helperutil } from '../../../common/helper/helperutil'
import { KuaaTransactionDto } from '../../../common/trx/kuaa-transaction-dto'
import { KuaaTransactionGenericDto } from '../../../common/trx/kuaa-transaction-generic-dto'
import { TokenService } from '../../../service/authorization/token.service'
import { SuscriptorService } from '../../../service/suscriptor/suscriptor.service'

@Component({
  selector: 'app-profile-home',
  templateUrl: './profile-home.component.html',
  styleUrls: ['./profile-home.component.css', './bootstrap.css']
})

export class ProfileHomeComponent implements OnInit {

  objKuaaTransactionGenericDto: KuaaTransactionGenericDto = new KuaaTransactionGenericDto();
  objKuaaTransactionDto: KuaaTransactionDto = new KuaaTransactionDto();
  public firstName = ''
  public userData: object = { firstName: '', lastName: '', cityCode: '', displayName: '', email: '', billingAddress: { phone: '' }, activePlanName: '', subscriptionExpiration: '', photoURL: '' }
  public nextBilling
  public imageClass: string = 'img-profile img-profile-without-image d-flex justify-content-center align-content-center'


  constructor(
    public suscriptorService: SuscriptorService,
    private tokenService: TokenService,
    private toastr: ToastrService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.getSuscriptor();
  }


  private getSuscriptor(): void {
    this.userService.get().subscribe(
      data => {
        if(data['photoURL'].length > 0){
          this.imageClass = 'img-profile img-profile-with-image d-flex justify-content-center align-content-center'
        }
        const d1 = DateTime.fromSeconds(data['subscriptionExpiration']['_seconds'])
        this.nextBilling = d1.plus({ month: 2 }).toFormat('yyyy-MM-dd H:mm a')
        this.userData = data
      }
    )
  }

  public save() {
    let firstName = <HTMLInputElement>document.getElementById('firstName')
    let lastName = <HTMLInputElement>document.getElementById('lastName')
    //let cityCode = <HTMLInputElement>document.getElementById('cityCode')
    //let displayName = <HTMLInputElement>document.getElementById('displayName')
    let photoURL = <HTMLInputElement>document.getElementById('photoURL')
    const newUserData = {
      firstName: firstName.value,
      lastName: lastName.value,
      photoURL: photoURL.value,
    }
    this.userService.update(newUserData).subscribe(
      data => {
        let mainImage = <HTMLImageElement>document.getElementById('main-image')
        document.getElementById('icon-main-user').style.display = 'none'
        document.getElementById('main-image').style.display = 'block'
        mainImage.src=photoURL.value
        Swal.fire({
          title: 'Actualización exitosa',
          text: 'Datos de perfil actualizados correctamente.',
          icon: 'success',
          background: 'black',
          color: '#14b8ec',
          confirmButtonColor: '#14b8ec',
          confirmButtonText: 'OK'
        })
      }
    )
  }

  public showImages() {
    const sToken = this.tokenService.getToken();
    this.suscriptorService.getAllIconst(sToken).subscribe(
      data => {
        this.objKuaaTransactionGenericDto = data;
        const allIcons = this.objKuaaTransactionGenericDto.entityTransfer;
        let html = `<div class="container">
                  <div class="row">`
        if (allIcons.length > 0) {
          allIcons.forEach(icon => {
            html += `<img class="col-4 col-sm-4 col-md-2 img-fluid mt-2" src="${icon.url}" style="height: auto;" onClick="selecteImage('${icon.url}')">`
          });
        } else {
          html += `<div class="col-3 mt-5 ms-2 me-2 avatares"  >
                        <p>No hay iconos disponibles en el momento</p>
                    </div>`
        }
        html += ` </div>
            </div>`

        Swal.fire({
          html: html,
          width: '100%',
          heightAuto: true,
          background: 'black',
          color: '#14b8ec',
          confirmButtonColor: '#14b8ec',
          confirmButtonText: 'Aceptar'
        })
      },
      err => {
        console.error(err);
      }
    )
  }
}
