

<div class="div container-fluid seccion_podcast pb-5">
    <div class="container">
      <div class="row">
        <div class="col col-lg-9 col-md-8 co-sm-6 justify-content-start ">
            <a href="/home" >
                <h1 class="section_title_podcast"><i class="fas fa-arrow-left icon_h1 align-middle"></i>Regresa para ver más contenidos</h1>
            </a>
        </div>
        <div class="col col-lg-3 col-md-4 col-sm-6 justify-content-end logo_footer btns_right">
          <img src="../../../../assets/line_avatar.png" class="line_avatar_big">
          <img src="../../../../assets/podcast_icon.png" class="podcast_icon">
        </div>
      </div>
      <div class="row alinear_centro pb-5">
        <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 justify-content-center alinear_centro">
          <div *ngIf="urlPodcast" >
            <ngx-audio-player  [playlist]="msaapPlaylist" [displayTitle]="msaapDisplayTitle" [autoPlay]="false" 
            muted="muted" [displayPlaylist]="msaapDisplayPlayList" [pageSizeOptions]="msaapPageSizeOptions" (trackEnded)="onEnded($event)"
                [displayVolumeControls]="msaapDisplayVolumeControls" [displayRepeatControls]="msaapDisplayRepeatControls"
                [disablePositionSlider]="msaapDisablePositionSlider" [displayArtist]="msaapDisplayArtist" 
                [displayDuration]="msaapDisplayDuration" [expanded]="true"></ngx-audio-player> 
          </div>
          <div id="outer">
            

            <div class="videoWrapper" >
              <!--
              <video controls class="video" src="{{objPodcastDto.banner3}}"  poster="{{objPodcastDto.banner1}}"  >
              </video>
              -->
              <!--
              <img id="wrap_video" src="{{objPodcastDto.banner1}}" >
              <audio controls  class="video" *ngIf="objPodcastDto.banner3"  >
                <source src="{{objPodcastDto.banner3}}" type="audio/ogg">
                <source src="{{objPodcastDto.banner3}}" type="audio/mpeg">
                Your browser does not support the audio element.
              </audio>
              -->
         

              <img src="../../../../assets/label_podcast_icon.png" class="label_podcast_icon">
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 justify-content-center alinear_centro">
          <div class="titulo_podcast">
            <h1 class="section_title_podcast_titulo"> {{objPodcastDto.title}} </h1>
          </div>
          <div class="descripcion_podcast">
            <p class="section_title_podcast_descripcion"> {{ objPodcastDto.subtitle }} </p>
            <p class="section_title_podcast_descripcion"> {{ objPodcastDto.description }} </p>
            <div class="wave">
              <img src="../../../../assets/icon_wave.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<footer class="container-fluid py-5 div_footer">
  <div class="container">
      <div class="row">
          <div class="col-12 col-md col-sm-12 mb-5 logo_footer">
              <img src="../../../../assets/logo_footer.png" class="logo_footer">
          </div>
          <div class="col-6 col-md">
              <h5 class="title_footer">EXPLORAR</h5>
              <ul class="list-unstyled text-small">
                  <li><a class="link-secondary" href="#">Módulos</a></li>
                  <li><a class="link-secondary" href="#">Lecciones</a></li>
                  <li><a class="link-secondary" href="#">Instructores</a></li>
              </ul>
          </div>
          <div class="col-6 col-md">
              <h5 class="title_footer">LEGAL</h5>
              <ul class="list-unstyled text-small">
                  <li><a class="link-secondary" href="#">Privacidad</a></li>
                  <li><a class="link-secondary" href="#">Términos</a></li>
                  <li><a class="link-secondary" href="#">Condiciones</a></li>
                  <li><a class="link-secondary" href="#">Soporte</a></li>
              </ul>
          </div>
          <div class="col-6 col-md">
              <h5 class="title_footer">SOCIAL</h5>
              <img src="../../../../assets/social_icons.png">
          </div>
          <div class="col-6 col-md mb-5">
              <h5 class="title_footer">CENTRO DE AYUDA</h5>
          </div>
          <div class="col-12 col-md col-sm-12">
              <ul class="list-unstyled text-small">
                  <li><a class="link-secondary" href="#">© KUAÁ 2021. Todos los derechos reservados</a></li>
              </ul>
          </div>
      </div>
  </div>
</footer>

<style>
  .mat-card {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
    background-color: aliceblue !important;
}
</style>