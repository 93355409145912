<div class="modules-login position-relative" style="background-color: black;">
  <img src="assets/inicioSesion/IMAGEN-DE-FONDO.png" class="w-100 background-image h-auto" alt="Encuentra balance y disfruta la vida con Kuaá">
  <img src="assets/inicioSesion/IMAGEN-DE-FONDO-MOBILE.jpg" class="w-100 background-image-mobile" alt="Encuentra balance y disfruta la vida con Kuaá">
  <div class="d-flex justify-content-center position-absolute" style="top: 8vh; left: 0; right: 0;">
    <div class="container">
      <div class="row justify-content-start">
        <div class="col-lg-7 d-none d-md-block d-sm-block justify-content-center alinear_centro">
          <div class="pt-5">
            <hr class="linea_azul" style="width: 50%; float: right;">
            <p class="register_login_main_title">¡Hola! <br>

              Estás a un paso de ser parte del universo Kuaá. Regístrate y disfruta 7 días gratis.</p>
            <hr class="linea_azul">
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12">
          <div class="pb-5">
            <div class="register_login_form">
              <form [formGroup]="formRegister">
                <fieldset>

                  <legend class="register_login_form_main_title">REGÍSTRATE</legend>

                  <div class="form-group pt-3 pb-3">
                    <div class="col-12">
                      <input [class.is-invalid]="formRegister.get('email').invalid && formRegister.get('email').touched"
                        id="textinput" formControlName="email" name="textinput" type="email"
                        placeholder="Correo electrónico" class="form-control input_poopins_black">
                      <!-- <span class="help-block">Ayuda</span> -->
                      <div *ngIf="(email.invalid && email.touched) || email.dirty">
                        <small *ngIf="email.errors?.required" class="text-danger">Correo electrónico es
                          requerido.</small>
                        <!--question mark(?) is a safe navigation operator-->
                        <small *ngIf="email.errors?.pattern" class="text-danger">Valide el email registrado.</small>
                      </div>
                    </div>
                  </div>

                  <div class="form-group pb-3">
                    <div class="col-12">
                      <input id="passwordinput" formControlName="password" name="passwordinput" type="password" (change)="checkPassword()"
                        placeholder="Contraseña" class="form-control input_poopins_black">
                      <div *ngIf="(password.invalid && password.touched) || password.dirty">
                        <small *ngIf="password.errors?.required" class="text-danger">
                          Contraseña es requerida.
                        </small>
                        <!--question mark(?) is a safe navigation operator-->
                        <small id="errorMessage" style="display: none;" >
                          <p class="mb-2" class="text-danger">Valide la contraseña registrada.</p>
                          <ul>
                            <li id="length" class="text-danger">Longitud mínimo de 9 caracteres.</li>
                            <li id="upperCase" class="text-danger">Mínimo una letra mayúscula.</li>
                            <li id="lowerCase" class="text-danger">Mínimo una letra minúscula.</li>
                            <li id="number" class="text-danger">Mínimo un número.</li>
                            <li id="caracter" class="text-danger">Mínimo un carácter especial (<b>!#$%&/()=?¡¿~&gt;&lt;/+</b>)</li>
                          </ul>
                        </small>
                      </div>
                      <!-- <span class="help-block">Ayuda</span> -->
                    </div>
                  </div>

                  <!-- Input contraseña -->
                  <div class="form-group pb-3">
                    <div class="col-12">
                      <input id="passwordinpucf" formControlName="confirmation" name="passwordinput" type="password"
                        placeholder="Repetir contraseña" class="form-control input_poopins_black" (change)="passwordValidator()" >
                      <div *ngIf="(confirmation.invalid && confirmation.touched) || confirmation.dirty">
                        <small *ngIf="confirmation.errors?.required" class="text-danger">Contraseña es
                          requerida.</small>
                        <!--question mark(?) is a safe navigation operator-->
                        <div>
                          <small *ngIf="confirmation.value != password.value" class="text-danger">Las contraseñas no coinciden</small>
                        </div>
                      </div>
                      <!-- <span class="help-block">Ayuda</span> -->
                    </div>
                  </div>

                  <div class="form-group pb-3">
                    <div class="col-12">
                      <input id="acceptedPrivacyPolicy" name="acceptedPrivacyPolicy" type="checkbox"
                        class="form-check-input terms-privacy" formControlName="acceptedPrivacyPolicy">
                      <span style="color: #14b8ec; margin-left: 5px;">
                        <a class="link-terms-privacy" href="https://www.kuaa.co/autorizacion-aviso-de-datos-personales/"
                          target="_blank">Autorizo</a> el tratamiento de mis datos personales descrito en la <a class="link-terms-privacy"
                          href="https://www.kuaa.co/politica-de-privacidad/" target="_blank">Política de Privacidad</a>
                      </span>
                    </div>
                    <div class="col-12">
                      <input id="acceptedTermsAndConditions" name="acceptedTermsAndConditions" type="checkbox"
                        class="form-check-input terms-privacy" formControlName="acceptedTermsAndConditions">
                      <span style="color: #14b8ec; margin-left: 5px;">
                        <a class="link-terms-privacy" href="https://www.kuaa.co/terminos-y-condiciones/"
                          target="_blank">Acepto los Términos y Condiciones</a>
                      </span>
                    </div>
                    <div class="col-12">
                      <p id="errorCheck" class="text-danger" style="display: none;" >Debe autorizar el tratamiento de los datos personales y aceptar los términos y condiciones</p>
                    </div>
                  </div>

                  <!-- CTA -->
                  <div class="form-group pt-3">
                    <div class="col-12">
                      <button type="button" [disabled]="formRegisterValid" class="btn btn-primary btn_poopins_black"  (click)="register()">CONTINUAR</button>
                    </div>
                  </div>

                  <div class="form-group pb-3 mt-30">
                    <div class="col-12">
                      <label class="col-12 control-label" for="textinput">¿Ya tienes cuenta en Kuaá? <br>
                        <span class="col-12 control-label-p" style="cursor: pointer;" routerLink="/login">Iniciar
                          sesión</span>
                      </label>
                    </div>
                  </div>

                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>