<!-- Main content -->
<main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
    <div class="inner-adjust">

        <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">Crear Curso: </h1>
        </div>
        <!-- Show user data when logged in -->
        <div class="row">
            <div class="col-md-12">
                <div class="media">
                    <div class="media-body">
                        <h1>+ <strong></strong></h1>
                        <form class="row g-3" novalidate #f="ngForm">
                            <div class="col-md-4">
                                <label for="inputNombre" class="form-label">Nombre:</label>
                                <input  type="text" class="form-control" id="inputNombre" name="inputNombre" [(ngModel)]="objCourseDto.name">
                            </div>
                            <div class="col-md-4">
                                <label for="inputPassword4" class="form-label">Titulo</label>
                                <input type="text" class="form-control" id="inputTitulo" name="inputTitulo" [(ngModel)]="objCourseDto.title">
                            </div>
                            <div class="col-md-4">
                                <label for="inputSubTitulo" class="form-label">Sub-Titulo</label>
                                <input type="text" class="form-control" id="inputSubTitulo" name="inputSubTitulo" [(ngModel)]="objCourseDto.subtitle" placeholder="">
                            </div>

                            <div class="col-md-4">
                                <label for="inputArea" class="form-label">Area:</label>
                                <select id="inputArea" class="form-select" name="inputArea" [(ngModel)]="objCourseDto.knowledgeArea">
                                    <option selected>Seleccione...</option>
                                    <option value="Area 1">Area. 1</option>
                                    <option value="Area 2">Area. 2</option>
                                    <option value="Area 3">Area. 3</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label for="inputDepartament" class="form-label">Departamento:</label>
                                <select id="inputDepartament" class="form-select" name="inputDepartament" [(ngModel)]="objCourseDto.department">
                                    <option selected>Seleccione...</option>
                                    <option value="Departament 1">Dep. 1</option>
                                    <option value="Departament 2" >Dep. 2</option>
                                    <option value="Departament 3" >Dep. 3</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label for="inputCategory" class="form-label">Categoria:</label>
                                <select id="inputCategory" class="form-select" name="inputCategory" [(ngModel)]="objCourseDto.category">

                                    <option selected>Seleccione...</option>

                                    <option value="{{item.code}}" *ngFor="let item of objListCategories" >{{item.name}}</option>
                                    <!--
                                    <option selected>Seleccione...</option>
                                    <option value="CAT001" >Alimentacion</option>
                                    <option value="CAT002" >Mente & cuerpo</option>
                                    <option value="CAT003" >Habitats sostenibles</option>
                                    <option value="CAT004" >Artes Y oficios</option>
                                -->
                                </select>
                            </div>

                            <div class="col-md-4">
                                <label for="inputLevel" class="form-label">Nivel:</label>
                                <input type="text" class="form-control" id="inputLevel" name="inputLevel" [(ngModel)]="objCourseDto.lavel">
                            </div>
                            <div class="col-md-4">
                                <label for="inputDurationContent" class="form-label">Duracion Del contenido:</label>
                                <input type="text" class="form-control" id="inputDurationContent" name="inputDurationContent" [(ngModel)]="objCourseDto.durationContent" >
                            </div>
                            <div class="col-md-4">
                                <label for="inputStartDate" class="form-label">Fecha Inicio:</label>
                                <input type="text" class="form-control" id="inputStartDate" name="inputStartDate" [(ngModel)]="objCourseDto.startDate" >
                            </div>
                            <div class="col-12">
                                <label for="inputDescription" class="form-label">Descripcion:</label>
                                <textarea type="text" class="form-control" id="inputDescription" name="inputDescription" [(ngModel)]="objCourseDto.description"
                                    placeholder="Descripcion del curso: ">
                                </textarea>
                            </div>

                            <div class="col-md-4">
                                <div class="mb-3">
                                    <label for="file" class="form-label">Banner home:</label>
                                    <input class="form-control" type="file" id="file"  name="file" (change)="onFileSelect($event,'b1')" >
                                  </div>
                            </div>
                            <div class="col-md-4">
                                <div class="mb-3">
                                    <label for="fileBanner2" class="form-label">Banner detail:</label>
                                    <input class="form-control" type="file" id="fileBanner2" name="fileBanner2" (change)="onFileSelect($event,'b2')">
                                  </div>
                            </div>
                            <div class="col-md-4">
                                <div class="mb-3">
                                    <label for="fileBanner4" class="form-label">Banner icon:</label>
                                    <input class="form-control" type="file" id="fileBanner4" name="fileBanner2" (change)="onFileSelect($event,'b3')" >
                                  </div>
                            </div>

                            <div class="col-md-4">
                                <label for="inputContentType" class="form-label">Tipo contenido:</label>
                                <select id="inputContentType" class="form-select" name="inputContentType" [(ngModel)]="objCourseDto.contentType" >
                                    <option selected>Seleccione...</option>
                                    <option value="ct01" >Audio</option>
                                    <option value="ct02" >Video</option>
                                    <option value="ct03" >Document</option>
                                </select>
                            </div>
                            <div class="col-md-8">
                                <div class="mb-3">
                                    <label for="fileContent" class="form-label">Contenido:</label>
                                    <input class="form-control" type="file" id="fileContent" name="fileContent" (change)="onFileSelect($event,'bc')" >
                                  </div>
                            </div>

                            <div class="col-md-2"></div>
                            <div class="col-md-4 d-grid gap-2 col-6 mx-auto">
                                <button  type="button" class="btn btn-primary btn-lg " (click)="saveHead()" >
                                    <span *ngIf="!isSaveProcess" >Guardar</span>
                                    <span  *ngIf="isSaveProcess" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span  *ngIf="isSaveProcess" > Guardando...</span>
                                </button>
                                <!--
                                <button mat-flat-button color="primary" (click)="uploadFile()">Cargar</button>
                            -->
                            </div>

                            <div class="col-md-4 d-grid gap-2 col-6 mx-auto">
                                <a href="/dashboard" class="btn btn-secondary btn-lg">Cancelar</a>
                            </div>
                            <div class="col-md-2"></div>
                        </form>

                    </div>
                </div>
            </div>
        </div>

    </div>
</main>
