<div class="div container-fluid banner_profile">
  <div class="container pb-5">
    <div class="row pb-5 pt-5">
      <div class="col-12 justify-content-start  ">
        <h1 class="sections_title">Cuenta</h1>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <p class="sections_subtitle">Correo electrónico y contraseña</p>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
            <label for="staticEmail" class="col-sm-2 col-form-label control-label w-100">Correo electrónico</label>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
            <p class="form-control form-control-dark border-0"> {{userData['email']}} </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
            <label for="staticEmail" class="col-sm-2 col-form-label control-label w-100">Contraseña</label>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
            <p class="form-control form-control-dark border-0"> ******** </p>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
            <div>
              <button class="btn btn-primary menbresia-btn w-100"
                (click)="authService.ForgotPassword(userData['email'], true)">Cambiar contraseña</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <p class="sections_subtitle">Suscripción y facturación</p>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
            <label for="staticEmail" class="col-sm-2 col-form-label control-label w-100">Plan</label>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
            <p class="form-control form-control-dark border-0"> {{userData['activePlanName']}} </p>
          </div>
          <div *ngIf="userData['activePlanName'] != 'Anual' " class="col-12 col-sm-12 col-md-4 col-lg-4">
            <div>
              <button class="btn btn-primary menbresia-btn w-100"
                (click)="changePlan()">Cambiar plan</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
            <label for="staticEmail" class="col-sm-2 col-form-label control-label w-100">Precio</label>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
            <p class="form-control form-control-dark border-0"> {{planValue}} </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
            <label for="staticEmail" class="col-sm-2 col-form-label control-label w-100">Siguiente fecha de
              facturación</label>
          </div>
          <div class="col-12 col-sm-12 col-md-8 col-lg-8">
            <p class="form-control form-control-dark border-0 {{colorText}} "> {{nextBilling}} </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
            <label for="staticEmail" class="col-sm-2 col-form-label control-label w-100">Método de pago</label>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
            <p class="form-control form-control-dark border-0"> {{methodData}} </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
            <label for="staticEmail" class="col-sm-2 col-form-label control-label w-100">Suscripción</label>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
            <div *ngIf="userData['autoRenewal']; else textCanceledPlan">
              <button class="btn btn-primary menbresia-btn w-100" (click)="cancelPlan()">Cancelar suscripción</button>
            </div>

            <ng-template #textCanceledPlan>
              <p class="form-control form-control-dark border-0">Plan cancelado</p>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
