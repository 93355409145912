<form id="subscribe-form" class="needs-validation" [formGroup]="formRegister" novalidate>
    <div class="form-group pb-3">
        <input type="card_name" class="form-control form-control-dark" type="text" id="card_name"
            placeholder="Nombre en la tarjeta" formControlName="card_name" required>
    </div>
    <div class="form-group pb-3">
        <input type="card_number" class="form-control form-control-dark" type="number" id="card_number"
            placeholder="Número de la tarjeta" maxlength="19" formControlName="card_number" required>
        <div *ngIf="formRegister.controls['card_number'].invalid && (formRegister.controls['card_number'].dirty)">
            <div *ngIf="formRegister.controls['card_number'].status == 'INVALID'">
                <p class="text-danger">Escribe un número de tarjeta válido.</p>
            </div>
        </div>
    </div>

    <div class="form-group pb-3">
        <div class="row">
            <div class="col-6">
                <div class="row">
                    <div class="col label-description">Fecha de vencimiento</div>
                </div>
                <div class="row">
                    <div class="col">
                        <select name="year" id="year" class="form-control form-control-dark" formControlName="year"
                            required>
                            <option value="">Año</option>
                            <option *ngFor="let year of years" value="{{year}}">{{year}}
                            </option>
                        </select>
                    </div>
                    <div class="col">
                        <select name="month" id="month" class="form-control form-control-dark" formControlName="month"
                            required>
                            <option value="">Mes</option>
                            <option *ngFor="let month of months" value="{{month}}">
                                {{month}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group col">
                    <label class="label-description" for="security_code">Código de seguridad</label>
                    <input type="security_code" class="form-control form-control-dark" type="number" id="security_code"
                        placeholder="CVV" formControlName="security_code" required>
                    <div
                        *ngIf="formRegister.controls['security_code'].invalid && (formRegister.controls['security_code'].dirty)">
                        <div *ngIf="formRegister.controls['security_code'].status == 'INVALID'">
                            <p class="text-danger">Escribe un código de seguridad
                                válido.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group pt-3">
        <div class="col-12">
            <input type="submit" class="btn btn-primary btn_poopins_black" value="Registrar tarjeta" (click)="goToPayment()" />
        </div>
    </div>
</form>