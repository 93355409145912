<app-homeheader></app-homeheader>

<body>  
    <header class="page-header">
        <nav>
            <button aria-label="Open Mobile Menu" class="open-mobile-menu fa-lg">
                <i class="fas fa-bars" aria-hidden="true"></i>
            </button>
            <a href="">
                <img class="logo horizontal-logo"
                    src="https://storage.googleapis.com/publickuaa/publickuaa/assets/img/logo1.png"
                    alt="forecastr logo">
                <img class="logo vertical-logo"
                    src="https://storage.googleapis.com/publickuaa/publickuaa/assets/img/icon.png"
                    alt="forecastr logo">
            </a>            
            <div class="top-menu-wrapper">
                <div class="panel panel1"></div>
                <div class="panel panel2"></div>
                <ul class="top-menu">
                    <li class="mob-block">
                        <img class="logo"
                            src="https://storage.googleapis.com/publickuaa/publickuaa/assets/img/icon.png"
                            alt="forecastr horizontal mobile logo">
                        <button aria-label="Close Mobile Menu" class="close-mobile-menu fa-lg">
                            <i class="fas fa-times" aria-hidden="true"></i>
                        </button>
                    </li>
                    <li>
                        <a href="">Nosotros</a>
                    </li>
                    <li>
                        <a href="">Contenidos</a>
                    </li>
                    <li>
                        <a href="">Soporte</a>
                    </li>
                    <li>
                        <a href="">Search</a>
                    </li>
                    <li>
                        <a href="">Contacto</a>
                    </li>
                    <li class="has-dropdown">
                        <a href="">
                            Cursos
                            <i class="fas fa-chevron-down"></i>
                        </a>
                        <ul class="sub-menu">
                            <li >
                                <a href="">Alimentacion</a>
                            </li>
                            <li >
                                <a href="">Mente & cuerpo</a>
                            </li>
                            <li>
                                <a href="">Habitats sostenibles</a>
                            </li>
                            <li>
                                <a href="">Artes Y oficios</a>
                            </li>
                        </ul>
                    </li>
                    <li class="has-dropdown">
                        <a href="">
                            Contactenos
                            <i class="fas fa-chevron-down"></i>
                        </a>
                        <ul class="sub-menu">
                            <li>
                                <a href="">Request a Demo</a>
                            </li>
                            <li>
                                <a href="">Send an Email</a>
                            </li>
                        </ul>
                    </li>
                    <li class="has-dropdown">
                        <a href="">
                            Me
                            <i class="fas fa-chevron-down"></i>
                        </a>
                        <ul class="sub-menu">
                            <li  *ngIf="authService.userData as user" >
                                <a href="/dashboard">
                                    <small> {{user.email}} </small>
                                </a>
                            </li>
                            <li>
                                <a href="">Cuenta</a>
                            </li>
                            <li>
                                <a href="">Perfil</a>
                            </li>
                            <li (click)="onLogOut()" >
                                <a href=""  >Salir</a>
                            </li>
                        </ul>
                    </li>
                    
                    <li class="has-dropdown">
                        <a href="">
                            Me
                            <i class="fas fa-chevron-down"></i>
                        </a>
                        <ul class="sub-menu">
                            <li>
                                <a href="">Request a Demo</a>
                            </li>
                            <li>
                                <a href="">Send an Email</a>
                            </li>
                        </ul>
                    </li>

                </ul>
                <!--
              <button class="search">
                <i class="fas fa-search fa-lg"></i>
                <span>Me</span>
              </button>
              <div class="search-form">
                <div>
                  <input type="search" placeholder="Search Resources">
                  <button aria-label="Search Resources" type="submit">
                    <i class="fas fa-search fa-2x" aria-hidden="true"></i>
                </button>
                </div>
              </div>
              -->
            </div>
        </nav>
    </header>
    <div id="app" class="flex bg-gray-900 text-white min-h-screen">
        <!--
        <div>
            <div>
                <div class="bm-menu" style="width: 0px;">
                    <nav class="bm-item-list">
                        <a href="/about" class="flex items-center mb-10">
                            <div><img src="https://storage.googleapis.com/publickuaa/home/img/avatar.12acf0e4.jpg"
                                    alt="avatar" class="rounded-full w-12 h-12"></div>
                            <div class="text-gray-500 ml-6 hover:text-gray-300">Kevin</div>
                        </a>
                        <a href="/" class="router-link-exact-active router-link-active"><svg fill="currentColor"
                                viewBox="0 0 24 24" width="24" height="24">
                                <path
                                    d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
                                    class="heroicon-ui"></path>
                            </svg><span>Search</span></a>
                        <a href="/" class="router-link-exact-active router-link-active"><svg fill="currentColor"
                                viewBox="0 0 24 24" width="24" height="24">
                                <path
                                    d="M9 22H5a1 1 0 0 1-1-1V11l8-8 8 8v10a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1zm3-9a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                                    class="primary"></path>
                                <path
                                    d="M12.01 4.42l-8.3 8.3a1 1 0 1 1-1.42-1.41l9.02-9.02a1 1 0 0 1 1.41 0l8.99 9.02a1 1 0 0 1-1.42 1.41l-8.28-8.3z"
                                    class="secondary"></path>
                            </svg><span>Home</span></a>
                        <a href="/about" class=""><svg fill="currentColor" viewBox="0 0 24 24" width="24"
                                height="24">
                                <path fill-rule="evenodd"
                                    d="M17 11a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4h4z"
                                    class="secondary"></path>
                            </svg><span>Watchlist</span></a>
                        <a href="/about" class=""><svg fill="currentColor" width="24" height="24"
                                viewBox="0 0 24 24">
                                <path
                                    d="M18 3v2h-2V3H8v2H6V3H4v18h2v-2h2v2h8v-2h2v2h2V3h-2zM8 17H6v-2h2v2zm0-4H6v-2h2v2zm0-4H6V7h2v2zm10 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V7h2v2z">
                                </path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </svg><span>Movies</span></a><a href="/about" class=""><svg fill="currentColor"
                                width="24" height="24">
                                <path
                                    d="M21 6h-8.586l2.293-2.293-1.414-1.414L10 5.586 6.707 2.293 5.293 3.707 7.586 6H3a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1zm-1 3v2h-2V9h2zm-2 4h2v2h-2v-2zM4 8h11v10H4V8z">
                                </path>
                            </svg><span>Series</span></a>
                        <a href="/about" class=""><svg fill="currentColor" width="24" height="24"
                                viewBox="0 0 24 24">
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path
                                    d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                </path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </svg><span>Originals</span></a>
                        <a href="/about" class=""><svg fill="currentColor" viewBox="0 0 24 24" width="24"
                                height="24" class="icon-cog">
                                <path
                                    d="M6.8 3.45c.87-.52 1.82-.92 2.83-1.17a2.5 2.5 0 0 0 4.74 0c1.01.25 1.96.65 2.82 1.17a2.5 2.5 0 0 0 3.36 3.36c.52.86.92 1.8 1.17 2.82a2.5 2.5 0 0 0 0 4.74c-.25 1.01-.65 1.96-1.17 2.82a2.5 2.5 0 0 0-3.36 3.36c-.86.52-1.8.92-2.82 1.17a2.5 2.5 0 0 0-4.74 0c-1.01-.25-1.96-.65-2.82-1.17a2.5 2.5 0 0 0-3.36-3.36 9.94 9.94 0 0 1-1.17-2.82 2.5 2.5 0 0 0 0-4.74c.25-1.01.65-1.96 1.17-2.82a2.5 2.5 0 0 0 3.36-3.36zM12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
                                    class="primary"></path>
                                <circle cx="12" cy="12" r="2" class="secondary"></circle>
                            </svg><span>Settings</span></a>
                        <a href="/salir" class=""><svg fill="currentColor" viewBox="0 0 24 24" width="24"
                                height="24" class="icon-cog">
                                <path
                                    d="M6.8 3.45c.87-.52 1.82-.92 2.83-1.17a2.5 2.5 0 0 0 4.74 0c1.01.25 1.96.65 2.82 1.17a2.5 2.5 0 0 0 3.36 3.36c.52.86.92 1.8 1.17 2.82a2.5 2.5 0 0 0 0 4.74c-.25 1.01-.65 1.96-1.17 2.82a2.5 2.5 0 0 0-3.36 3.36c-.86.52-1.8.92-2.82 1.17a2.5 2.5 0 0 0-4.74 0c-1.01-.25-1.96-.65-2.82-1.17a2.5 2.5 0 0 0-3.36-3.36 9.94 9.94 0 0 1-1.17-2.82 2.5 2.5 0 0 0 0-4.74c.25-1.01.65-1.96 1.17-2.82a2.5 2.5 0 0 0 3.36-3.36zM12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
                                    class="primary"></path>
                                <circle cx="12" cy="12" r="2" class="secondary"></circle>
                            </svg><span>Cerrar sesion</span></a>
                        <div class="ml-8"><img
                                src="https://storage.googleapis.com/publickuaa/home/img/disneyplus_logo.3c30402c.svg">
                        </div>
                    </nav><span class="bm-cross-button cross-style"><span class="bm-cross"
                            style="position: absolute; width: 3px; height: 14px; transform: rotate(-45deg);"></span><span
                            class="bm-cross"
                            style="position: absolute; width: 3px; height: 14px; transform: rotate(45deg);"></span></span>
                </div>
                <div class="bm-burger-button"><span class="bm-burger-bars line-style" style="top: 0%;"></span><span
                        class="bm-burger-bars line-style" style="top: 40%;"></span><span
                        class="bm-burger-bars line-style" style="top: 80%;"></span></div>
            </div>
        </div>
    -->
        <div class="w-full">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-homefooter></app-homefooter>
</body>

