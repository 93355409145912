<div class="div container-fluid banner_profile">
    <div class="container pb-5">
        <div class="row pb-5 pt-5">
            <div class="col-12 justify-content-start  ">
                <h1 class="sections_title">Cambiar plan de suscripción</h1>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-12">
                <div class="row" *ngFor="let plan of plans">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <label for="plan" class="col-sm-2 col-form-label control-label w-100"> {{plan['name']}}
                        </label>
                    </div>
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <div *ngIf="plan['id'] != idPlan; else planSelected">
                            <button class="btn btn-primary menbresia-btn w-100" (click)="updateUserPlan(plan['id'], plan['name'])" >Cambiar a este plan</button>
                        </div>

                        <ng-template #planSelected>
                            <p class="form-control form-control-dark border-0">Plan actual</p>
                        </ng-template>



                    </div>
                </div>
            </div>
        </div>
    </div>
</div>