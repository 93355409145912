import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Helperutil } from '../../common/helper/helperutil'
import { UserService } from './user.service'

@Injectable({
  providedIn: 'root'
})
export class CreditCardsService {

  constructor(
    private httpClient: HttpClient,
    private userService: UserService
  ) { }

  public create(creditCardData) {
    return this.httpClient.post(Helperutil.S_CORE_SERVICE_PAYMENT_CREDIT_CARDS, creditCardData, this.userService.createHeader());
  }

  public select(creditCardId) {
    return this.update(creditCardId, { selected: true });
  }

  public update(creditCardId, creditCardData) {
    return this.httpClient.put(`${Helperutil.S_CORE_SERVICE_PAYMENT_CREDIT_CARDS}/${creditCardId}`, creditCardData, this.userService.createHeader());
  }

  public delete(creditCardId) {
    return this.httpClient.delete(`${Helperutil.S_CORE_SERVICE_PAYMENT_CREDIT_CARDS}/${creditCardId}`, this.userService.createHeader());
  }

  public getAll() {
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_PAYMENT_CREDIT_CARDS, this.userService.createHeader());
  }
}
