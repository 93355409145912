<!DOCTYPE html>
<html lang="en" dir="ltr">

<head>
    <meta charset="utf-8">
    <title>Web Kuaa</title>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.1/css/all.css">
    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap"
        rel="stylesheet">
</head>

<body>
    <div class="div container-fluid header_background">
        <div class="container">
            <div class="header">
                <div class="container_100">
                    <div class="col justify-content-start align-middle" id="branding">
                        <h2 class="logo align-middle">
                            <a href="/">
                                <img src="./../../../assets/logo_header.png" class="desktop logo_width img-fluid" />
                                <img src="./../../../assets/logo_mobile.png" class="mobile logo_width_mobile img-fluid" />
                              </a>
                        </h2>
                    </div>
                    <div class="col justify-content-end align-middle" id="navegacion">
                        <button class="btn-subscribe" (click)="goToLogin()">¡SUSCRÍBETE!</button>
                        <!--
              <span class="select-language">IDIOMA <a href="#">ESP</a> <a href="#">ING</a></span>
              -->
                        <input type="checkbox" id="chk">
                        <label for="chk" class="show-menu-btn">
                            <img src="./../../../assets/profile.png" class="img-fluid profile">
                        </label>
                        <ul class="menu">
                            <!--<a href="#"><img src="./../../../assets/line.png" width="auto" class="line_banner"></a>-->
                            <a href="#"><img src="./../../../assets/profile_admin.png" class="icon_menu">Administrar
                                perfil</a>
                            <a href="#"><img src="./../../../assets/icon_my_courses.png" class="icon_menu">Mis
                                cursos</a>
                            <a href="#"><img src="./../../../assets/profile_admin.png" class="icon_menu">Mis
                                descargas</a>
                            <a href="#"><img src="./../../../assets/icon_my_courses.png"
                                    class="icon_menu">Notificaciones</a>
                            <a href="#"><img src="./../../../assets/icon_my_courses.png" class="icon_menu">Configurar
                                video</a>
                            <a href="#"><img src="./../../../assets/icon_my_courses.png"
                                    class="icon_menu">Suscripción</a>
                            <a href="#"><img src="./../../../assets/icon_my_courses.png" class="icon_menu">Ayuda</a>
                            <a href="#"><img src="./../../../assets/icon_my_courses.png" class="icon_menu">Compartir
                                pantalla</a>
                            <a href="#"><img src="./../../../assets/icon_my_courses.png" class="icon_menu">Buscar</a>
                            <a href="#"><img src="./../../../assets/icon_my_courses.png" class="icon_menu">Salir</a>
                            <label for="chk" class="hide-menu-btn">
                                <i class="fas fa-times"></i>
                            </label>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="div container-fluid section_1 py-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-4 col-sm-12 col-xs-12 justify-content-center alinear_centro">
                    <div class="pt-5">
                        <p class="section_title_web_kuaa_titulo">
                            Política de privacidad 
                        </p>
                    </div>
                    <hr class="linea_blanca">
                    <div class="descripcion_podcast">
                        <p class="section_title_web_kuaa_descripcion">
                            <i>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                            </i>
                        </p>
                        <p class="section_title_web_kuaa_descripcion">
                            <i>
                                It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </i>
                        </p>
                    </div>
                    <div class="mx-auto d-flex justify-content-center">
                        <a href="/login">
                            <img src="./../../../assets/subscribe_now_btn.png" class="img-fluid">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <footer class="container-fluid py-5 div_footer">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md col-sm-12 mb-5 logo_footer">
                    <img src="./../../../assets/logo_footer.png" class="logo_footer">
                </div>
                <div class="col-6 col-md">
                    <h5 class="title_footer">EXPLORAR</h5>
                    <ul class="list-unstyled text-small">
                        <li><a class="link-secondary" href="#">Módulos</a></li>
                        <li><a class="link-secondary" href="#">Lecciones</a></li>
                        <li><a class="link-secondary" href="#">Instructores</a></li>
                    </ul>
                </div>
                <div class="col-6 col-md">
                    <h5 class="title_footer">LEGAL</h5>
                    <ul class="list-unstyled text-small">
                        <li><a class="link-secondary" href="#">Privacidad</a></li>
                        <li><a class="link-secondary" href="#">Términos</a></li>
                        <li><a class="link-secondary" href="#">Condiciones</a></li>
                        <li><a class="link-secondary" href="#">Soporte</a></li>
                    </ul>
                </div>
                <div class="col-6 col-md">
                    <h5 class="title_footer">SOCIAL</h5>
                    <img src="./../../../assets/social_icons.png">
                </div>
                <div class="col-6 col-md mb-5">
                    <h5 class="title_footer">CENTRO DE AYUDA</h5>
                </div>
                <div class="col-12 col-md col-sm-12">
                    <ul class="list-unstyled text-small">
                        <li><a class="link-secondary" href="#">© KUAÁ 2021. Todos los derechos reservados</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</body>

</html>