<!-- Top navigation -->
<nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
    <a class="navbar-brand col-sm-3 col-md-2 mr-0" href="/home">
      <!--
      <span>Kuaa</span>
      -->
      <img _ngcontent-ocv-c63="" src="https://storage.googleapis.com/publickuaa/publickuaa/assets/img/logo1.png" alt="forecastr logo" class="logo horizontal-logo">
    </a>
  </nav>

  <!-- Sidebar navigation -->
<div class="container-fluid">
    <div class="row">
  
      <nav class="col-md-2 d-md-block bg-light sidebar">
        <div class="sidebar-sticky">
          <ul class="nav flex-column">

            <li class="nav-item">
              <a class="nav-link active" href="/dashboard">
                <i class="fas fa-cogs"></i> Dashboard
              </a>
            </li>
            
            <li class="nav-item">
              <hr>
            </li>
            <li class="nav-item">
              <a class="nav-link " href="/dashboard/subscriber-home">
                <i class="fas fa-user"></i>User Profile
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link " routerLink='/dashboard/categorylist' >
                <i class="far fa-folder"></i>Category list
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link "  routerLink='/dashboard/categoryadd' >
                <i class="far fa-file"></i>Category add
              </a>
            </li>
            

            <li class="nav-item">
              <a class="nav-link " href="/dashboard/courselist" >
                <i class="far fa-folder"></i>Courses list
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link " href="/dashboard/courseadd">
                <i class="far fa-file"></i>Courses add
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link ">
                <i class="far fa-edit"></i>Courses edit
              </a>
            </li>

            <!-- Calling SignOut() Api from AuthService -->
            <li class="nav-item">
              <a class="nav-link" (click)="authService.SignOut()">
                <i class="fas fa-sign-out-alt"></i>Log out
              </a>
            </li>
          </ul>
        </div>
      </nav>

    </div>
    </div>
    