<body>
  <div class="divBody">
      <!-- Grid row -->
      <div class="row bodyRow">

          <div class="col-md-2 mx-auto divFakeOne"></div>

          <div class="col-md-4 mx-auto divMsgWelcome">
              <div class="row  ">
                  <div class="col-md-12 mx-auto contentBannerBody">
                      <p class="loginBodyBannerTitle1"><strong>Bienvenido,</strong>
                          <br> Inicia sesión con tu
                          <br>cuenta <strong>KUAÁ</strong>    
                          <!--
                          <small style="font-size: 12px;">{{documentdata}}</small>
                          -->
                      </p>
                      <hr class="lineHorizon">
                      
                  </div>
                  <img class="imgFindYou" src="https://storage.googleapis.com/publickuaa/static/img/login/FRASE-FIND-YOUR.png">
              </div>
          </div>
          <!--
          <div class="col-md-2 mx-auto">
          </div>
          -->
          <div class="col-md-4 mx-auto loginFormContent">
              <div class="row menuItemLoginSelect contentFormBody">
                  <div class="col-md-12 mx-auto loginBodyBannerFormTitle ">
                      <!--
                      
                      <img class="formloginTitle" src="https://storage.googleapis.com/publickuaa/static/img/login/INICIA1.png">
                      -->
                      <span class="formloginTitle" > REGISTRATE </span>
                  </div>
                  <div class="col-md-12 mx-auto">
                      <div class="row">
                          <div class="col-md-12 mx-auto">
                              <input type="text" class="formControlLogin" #username required placeholder="Usuario nombre">
                          </div>
                          <div class="col-md-12 mx-auto">
                            <input type="text" class="formControlLogin" #userEmail required placeholder="Usuario correo">
                        </div>
                          <div class="col-md-12 mx-auto">
                              <input type="password" class="formControlLogin" placeholder="Password" #userPwd required aria-placeholder="Usuario password">
                          </div>


                          <div class="col-md-12 mx-auto">
                              <div class="row">
                                  <div class="col-md-6 loginFormG" title="Ingresa con Google">
                                      <small class="logintextWhrite">
                                          Ingresa con Google 
                                      </small>
                                      <svg xmlns:xlink="http://www.w3.org/1999/xlink"  width="16" height="16" fill="currentColor" class="bi bi-facebook loginIconColor" viewBox="0 0 16 16">
                                          <a >
                                            <text x="0" y="15"  >G</text>
                                          </a> 
                                        </svg>
                                  </div>
                                  <div class="col-md-4 loginFormF" title="Ingresa con Facebook">
                                      <small class="logintextWhrite">
                                          o con Facebook
                                      </small>
                                      <svg xmlns:xlink="http://www.w3.org/1999/xlink"  width="16" height="16" fill="currentColor" class="bi bi-facebook loginIconColor" viewBox="0 0 16 16">
                                          <a >
                                            <text x="0" y="15"  >f</text>
                                          </a> 
                                        </svg>
                                  </div>  
                              </div>
                          </div> 

                          
                          <div class="col-md-12 mx-auto">
                              <br>
                              <!--
                              <a href="#!">
                                  <small class="loginTextColorBlue">
                                      Olvide mi contraseña
                                  </small>
                              </a>
                              -->
                          </div> 
                        

                          <div class="col-md-12 mx-auto">
                            <!--
                              <img title="iniciar sesion" class="loginBTNStart" src="https://storage.googleapis.com/publickuaa/static/img/background/INICIA2.png"
                              (click)="authService.SignIn(userName.value, userPassword.value)" />
                              -->
                              <input type="button" class="btn btnPrimary formControlButton" value="REGISTRAR" (click)="authService.SignUp(userEmail.value, userPwd.value)">
                              
                          </div>


                          <div class="col-md-12 mx-auto">
                              <br>
                              <a href="/login" class="formloginTitle" >
                                  <!--
                                  <img class="loginImgMakeAccount" src="https://storage.googleapis.com/publickuaa/static/img/login/CREAR-TU-CUENTA.png">                                    
                                  -->
                                  <span class="formloginTitle" > Regresar </span>
                              </a>
                          </div>
                          <div class="col-md-12 mx-auto">
                              <br>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row" >
                  <div class="col-md-4 mx-auto">
                      
                  </div>
                  <div class="col-md-4 mx-auto">
                      <hr class="lineHorizon2">
                  </div>
                  <div class="col-md-4 mx-auto">

                  </div>
              </div>
          </div>

          <div class="col-md-2 mx-auto"></div>

      </div>
  </div>
</body>