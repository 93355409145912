<div class="video-player" (mousemove)="mouseMove()">

  <div *ngIf="isLoading" class="video-player__loader">
    <div class="spinner-border text-primary" style="width: 4rem; height: 4rem;" role="status">
      <span class="visually-hidden">Cargando...</span>
    </div>
  </div>

  <div class="w-100" [ngClass]="isLoading ? 'd-none' : ''">
    <div class="video-player__content" [ngClass]="showControls ? 'show-controls' : ''">
      <video #video width="100%" height="auto" autoplay (ended)="videoEnded($event)" (error)="videoError($event)" (pause)="videoPause()" (play)="videoPlay()" (canplaythrough)="videoCanplaythrough()">
        <source *ngIf="source" [src]="source" [type]="type">
      </video>

      <div class="video-player__meta-layer" [ngClass]="!isLoading && paused ? 'visiable-floating-play' : ''">
        <div class="video-player__header">
          <div *ngIf="backUrl" class="video-player__back me-2">
            <a [routerLink]="backUrl" title="Volver" ><img id="back" src="/assets/back_icon.png" alt="←" /></a>
          </div>
          <h3 class="mb-0">{{ title }}</h3>
        </div>

        <div class="d-flex align-items-center justify-content-center video-player__floating-play" (click)="playOrPause()">
          <img id="imgplay" src="/assets/icon_play_video.png" class="img-fluid" />
        </div>

        <div class="video-player__footer">
          <div class="d-flex align-items-center justify-content-end video-player__navigation mb-3">
            <button *ngIf="enablePrev" type="button" class="btn btn-outline-primary" (click)="prevVideo()">Anterior</button>
            <button *ngIf="enableNext" type="button" class="btn btn-outline-primary" (click)="nextVideo()">Siguiente</button>
          </div>

          <div class="d-flex align-items-center justify-content-between video-player__controls">
            <button type="button" (click)="playOrPause()" class="d-flex align-items-center justify-content-center"><span class="material-icons">{{ paused ? 'play_arrow' : 'pause' }}</span></button>

            <div class="d-flex align-items-center flex-grow-1">
              <input type="range" class="form-range" [ngStyle]="{ 'background': progressBarBgColor }" [(ngModel)]="progress" step="1" min="0" max="100" (change)="selectStreamPart()" />
            </div>

            <div *ngIf="video" class="d-flex align-items-center video-player__time">
              <span class="video-player__time-current">{{ currentTimeInSeconds | secondsToDuration }}</span>
              <span>/</span>
              <span class="video-player__time-duration">{{ durationInSeconds | secondsToDuration }}</span>
            </div>

            <div class="d-flex align-items-center video-player__volume">
              <button type="button" (click)="muteOrUnmute()" class="d-flex align-items-center justify-content-center" title="Mute or Unmute"><span class="material-icons">{{ muted || volume === 0 ? 'volume_off' : 'volume_up' }}</span></button>
              <input type="range" class="form-range" [(ngModel)]="volume" step="1" min="0" max="100" (change)="changeVolume()" />
            </div>

            <button type="button" (click)="activeFullscreen()" class="d-flex align-items-center justify-content-center"><span class="material-icons">fullscreen</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
