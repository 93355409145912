import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "../../shared/services/auth.service";
import { TokenService } from '../../service/authorization/token.service';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})

export class SignInComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public router: Router,
    private tokenService: TokenService,
  ) {
    const roles = this.tokenService.getAuthorities();
    if (this.tokenService.getToken() ) {
      this.router.navigate(['/home']);
    }

   }

  ngOnInit() { }

}