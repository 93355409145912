<div class="container mt-5">
    <div class="row">
        <div class="col-12">
            <h2 style="color: #00B1EB; font-weight: 700; padding-bottom: 3px;">Videos relacionados</h2>
            <input type="hidden" id="idModules" value="{{idModules}}">
        </div>
    </div>

    <!--<div class="row">
        <div class="col-4 mt-5" *ngFor="let courses of relatedCourses; let i = index;">
            <div *ngIf="i <= 2" class="card" style="background-color: black; border: 1px #00B1EB solid;">
                <div class="card-img-top"
                    style="background-image: url({{courses.banner1}}); background-size: cover; height: 15rem; border: 1px #00B1EB solid;">
                    <div
                        style="display: flex; flex-direction: column; align-items: center; height: 15rem; justify-content: center;">
                        <span (click)="playVideos(i)" class="material-icons play-video">play_circle_outline</span>
                    </div>
                </div>
                <div class="card-body" style="height: 200px; overflow: hidden;">
                    <p class="card-text"> {{courses.sinopsis}} </p>
                </div>
            </div>
        </div>
    </div>-->

    <div class="row">
        <div class="col-4 mt-5" *ngFor="let courses of relatedCourses; let i = index;">
            <div class="card" style="background-color: black; border: 1px #00B1EB solid;">
                <div class="card-img-top image-related"
                    style="background-image: url({{courses.banner2}}); background-size: cover; height: 15rem; border: 1px #00B1EB solid;" (mouseover)="showSinopsis(i)" (mouseout)="hideSinopsis(i)" >
                    <div
                        style="display: flex; flex-direction: row; align-items: end; height: 15rem; justify-content: end;">
                        <a href="/home/preview/{{courses.id}}/0" class="link-dark" id="play_{{i}}" style="outline: none; display: none;">
                            <span class="material-icons play-video ms-1 me-1" style="color: #00b1eb;" >play_circle_outline</span>
                        </a>
                    </div>
                </div>
                <div class="card-body" id="sinopsis_{{i}}" style="color: white; display: none; ">
                    <div class="d-flex justify-content-start" >
                        <h3>{{courses.title}}</h3>
                    </div>
                    <p class="card-text mt-2"> {{courses.sinopsisCut}} </p>
                </div>
            </div>
        </div>
    </div>
</div>