import { Component, OnInit } from '@angular/core';
import { ModuleDto } from '../../../model/dto/module/module-dto';
import { TokenService } from '../../../service/authorization/token.service';
import { CourseService } from '../../../service/course/course.service';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['../hostel/hostel.custom.css', 'modules.component.scss']
})
export class ModulesComponent implements OnInit {

  objListModules: ModuleDto[] = [];

  constructor(
    private tokenService: TokenService,
    private objCourseService: CourseService,
  ) { }

  ngOnInit(): void {
    this.loadModuleDefault();
  }

  loadModuleDefault(): void {
    const sToken = this.tokenService.getToken();
    this.objCourseService.getModuleList(sToken, 'f5557664-8332-45f8-90e1-7e9b3f371272').subscribe(
      data => {
        this.objListModules = data;
      },
      err => {
        console.error(err);
      }
    );
  };

  showHideVideo(i, type){
    let display = type == 'show' ? 'block' : 'none'
    document.getElementById('video_image_'+i).style.display = display
  }

}
