import { Component, HostListener } from '@angular/core'

import { environment } from '../environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'kuaa';

  constructor() {
    if (environment.production) {
      document.addEventListener('contextmenu', event => event.preventDefault());
      this.noKeyPressF12();
    }
  }

  noKeyPressF12(){
    document.onkeypress = function (event: any) {
      event = (event || window.event);
      if (event.keyCode == 123) {
        return false;
      }
    }
    document.onmousedown = function (event: any) {
      event = (event || window.event);
      if (event.keyCode == 123) {
        return false;
      }
    }
    document.onkeydown = function (event: any) {
      event = (event || window.event);
      if (event.keyCode == 123) {
        return false;
      }
    }
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    if (environment.production) {
      event.preventDefault();
    }
  }


}
