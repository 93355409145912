import { Modal as BootstrapModal } from 'bootstrap'

import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core'

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnChanges {
  @Input() id: string = null;
  @Input() show = false;
  @Input() title = '';
  @Input() variant: 'xl' | 'lg' | 'sm' | '' = '';
  @Input() verticalCentered = true;
  @Output() close = new EventEmitter();

  modalInstance: BootstrapModal = null;
  internalId = null;

  constructor() { }

  ngOnInit(): void {
    this.internalId = this.id ? this.id : 'modal-' + (Math.ceil(Math.random() * 100000));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.internalId && !this.modalInstance) {
      const modalEl = document.getElementById(this.internalId);
      if (modalEl) {
        this.modalInstance = new BootstrapModal(modalEl);
      }
    }
    if (changes.show) {
      this.toggleModal();
    }
  }

  toggleModal() {
    if (this.modalInstance) {
      if (this.show) {
        this.modalInstance.show();
      } else {
        this.modalInstance.hide();
      }
    }
  }

  handleBootstrapClose() {
    if (this.close) {
      this.close.emit();
    }
  }
}
