import { Component, NgZone, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { CourseDto } from '../../../model/dto/course/course-dto'
import { TokenService } from '../../../service/authorization/token.service'
import { CourseService } from '../../../service/course/course.service'
import { AuthService } from '../../../shared/services/auth.service'

@Component({
  selector: 'app-homedefault',
  templateUrl: './homedefault.component.html',
  styleUrls: ['./homedefault.component.css']
})
export class HomedefaultComponent implements OnInit {

  objListCourses: CourseDto[] = [];

  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    private objCourseService: CourseService,
    private tokenService:TokenService
  ) { }

  ngOnInit(): void {
  }

  onLogOut(): void {
    this.tokenService.logOut();
    window.location.reload();
    this.router.navigate(['/']);
  }

}
