import { devActive } from 'src/environments/environment'

import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-hosteldefault',
  templateUrl: './hosteldefault.component.html',
  styleUrls: ['./hosteldefault.component.css','./bootstrap.css']
})
export class HosteldefaultComponent implements OnInit {
  devActive = devActive;
  constructor() { }

  ngOnInit(): void {
  }

}
