export class CourseDto {
    id: string;
    code: string;
    name: string;
    idClient: string;
    title: string;
    subtitle: string;
    description: string;
    link: string;
    banner1: string;
    banner2: string;
    banner3: string;
    banner4: string;
    banner5: string;
    status: string;
    durationContent: string;
    durationPractice: string;
    startDate: string;
    knowledgeArea: string;
    department: string;
    category: string;
    lavel: string;
    classNumber: string;
    codeParent: string;
    contentType: string;
    fileContent: any;
    content: any[];
    position: string;
    sinopsis:string;
    moduleCode?: string;
    videoBaseCode?: string;
}
