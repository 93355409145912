import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'secondsToDuration'
})
export class SecondsToDurationPipe implements PipeTransform {

  transform(totalTimeInSeconds: number, ...args: unknown[]): unknown {
    if (totalTimeInSeconds > 0) {
      let minutes = Math.floor(totalTimeInSeconds / 60)
      let seconds = Math.ceil(totalTimeInSeconds)
      if (minutes >= 1) {
        seconds = Math.ceil(totalTimeInSeconds % 60)
      } else {
        minutes = 0
      }

      return `${(minutes < 10 ? '0' : '') + minutes}:${(seconds < 10 ? '0' : '') + seconds}`
    }

    return '00:00'
  }

}
