import { environment } from '../../../environments/environment';
import { environmentPayment } from '../../../environments/payment/environment';
export class Helperutil {

    private static S_CORE_SERVICE_API_V1_BASE  = environment.S_CORE_SERVICE_API_V1_BASE_URL;
    private static S_CORE_SERVICE_API_PAYMENT_BASE  = environment.S_CORE_SERVICE_API_PAYMENT_BASE_URL
    //private static S_CORE_SERVICE_API_V1_BASE  = 'http://192.168.1.18:8080';
    private static S_CORE_SERVICE_API_V1_BASE_PATH    = '/';

    public static S_CORE_SERVICE_API_V1_COURSE = 'course/';
    public static S_CORE_SERVICE_API_V1_SERIE = 'serie/';
    public static S_CORE_SERVICE_API_PAYMENT_SERIES = 'series/';
    public static S_CORE_SERVICE_API_PAYMENT_INSTRUCTORS = 'instructors/';
    public static S_CORE_SERVICE_API_V1_CATEGORY = 'category/';
    public static S_CORE_SERVICE_API_V1_MODULE = 'module/';
    public static S_CORE_SERVICE_API_V1_INSTRUCTOR = 'instructor/';
    public static S_CORE_SERVICE_API_V1_SUSCRIPTOR = 'suscriptor/';
    public static S_CORE_SERVICE_API_V1_PODCAST = 'podcast/';
    public static S_CORE_SERVICE_API_V1_ARTICLE = 'article/';
    public static S_CORE_SERVICE_API_V1_VIDEOBASE = 'videobase/';
    public static S_CORE_SERVICE_API_V1_MODELS = 'models/';
    public static S_CORE_SERVICE_API_V1_ICONS = 'icons/';
    public static S_CORE_SERVICE_API_PAYMENT_USERS = 'users/';
    public static S_CORE_SERVICE_API_PAYMENT_SUBCRIPTION_PLANS = 'subscription_plans/';
    public static S_CORE_SERVICE_API_PAYMENT_CREDIT_CARDS = 'credit_cards/';
    public static S_CORE_SERVICE_API_PAYMENT_COUNTRIES = 'countries/';
    public static S_CORE_SERVICE_API_PAYMENT_PAYMENTS = 'payments/';

    public static S_CORE_SERVICE_API_V1_COURSE_LIST = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'list';

    public static S_CORE_SERVICE_API_V1_COURSE_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'create';

    public static S_CORE_SERVICE_API_V1_COURSE_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'findbyid';

    public static S_CORE_SERVICE_API_V1_VIDEOBASE_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_VIDEOBASE
                                                      + 'findbyid';

    public static S_CORE_SERVICE_API_V1_COURSE_IMG_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'img/add/';
    public static S_CORE_SERVICE_API_V1_COURSE_GETBYCATEGORY= Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'getbycategory';

    public static S_CORE_SERVICE_API_V1_COURSE_GETCONTENT= Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'getcontent';

    public static S_CORE_SERVICE_API_V1_COURSE__COMPLETE_VIDEOBASE_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                                    + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                                    + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                                    + 'videobase/findbyid';

    public static S_CORE_SERVICE_API_V1_SERIE_FINDBYINSTRUCTOR = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                                    + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                                    + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                                    + 'findby/module';


    public static S_CORE_SERVICE_API_V1_MODULE_LIST = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_MODULE
                                                      + 'list';

    public static S_CORE_SERVICE_API_V1_MODULE_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_MODULE
                                                      + 'findbyid';

    public static S_CORE_SERVICE_API_V1_ADD_TO_MY_LIST = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_MODELS;

    public static S_CORE_SERVICE_API_V1_INSTRUCTOR_LIST = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR
                                                      + 'list';

    public static S_CORE_SERVICE_API_V1_INSTRUCTOR_LIST_BY_SEQUENCE = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR
                                                      + 'list/by/sequence';

    public static S_CORE_SERVICE_API_V1_INSTRUCTOR_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR
                                                      + 'findbyid';
    //public static S_CORE_SERVICE_API_V1_COURSE_STREAMING= 'http://192.168.1.20:8081/video/streaming/';
    //public static S_CORE_SERVICE_API_V1_COURSE_STREAMING= 'https://apistream-cmlscukhsq-uc.a.run.app/video/streaming/';
    public static S_CORE_SERVICE_API_V1_COURSE_STREAMING= 'http://192.168.1.19:8080/streaming/';
    //public static S_CORE_SERVICE_API_V1_COURSE_STREAMING= 'http://192.168.1.21:8080/video/';


    public static S_CORE_SERVICE_API_V1_COURSE_Download = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_COURSE
                                                      + 'videos';


    /** Category **/
    public static S_CORE_SERVICE_API_V1_CATEGORY_LIST = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_CATEGORY
                                                      + 'list';
    public static S_CORE_SERVICE_API_V1_CATEGORY_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_CATEGORY
                                                      + 'create';
    public static S_CORE_SERVICE_API_V1_CATEGORY_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_CATEGORY
                                                      + 'findbyid';

    public static S_CORE_SERVICE_API_V1_CATEGORY_IMG_SAVE = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_CATEGORY
                                                      + 'img/add/';

    public static S_CORE_SERVICE_API_V1_SUSCRIPTOR_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_SUSCRIPTOR
                                                      + 'findbyid';

    public static S_CORE_SERVICE_API_V1_SUSCRIPTOR_UPDATE = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_SUSCRIPTOR
                                                      + 'update';
    /** Podcast */
    public static S_CORE_SERVICE_API_V1_PODCAST_GET_BY_USER_CODE = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_PODCAST
                                                      + 'getby/usercode/';

    public static S_CORE_SERVICE_API_V1_PODCAST_FIND_BY_ID = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_PODCAST
                                                      + 'findbyid/';

    public static S_CORE_SERVICE_API_V1_PODCAST_FIND_BY_MODULE_ID = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_PODCAST
                                                      + 'findby/module/';

    public static S_CORE_SERVICE_API_V1_PODCAST_LIST = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                      + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                      + Helperutil.S_CORE_SERVICE_API_V1_PODCAST
                                                      + 'list';

    /** Article */
    public static S_CORE_SERVICE_API_V1_ARTICLE_GET_BY_USER_CODE = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                                    + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                                    + Helperutil.S_CORE_SERVICE_API_V1_ARTICLE
                                                                    + 'getby/usercode/';

    public static S_CORE_SERVICE_API_V1_ARTICLE_FIND_BY_ID = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                                    + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                                    + Helperutil.S_CORE_SERVICE_API_V1_ARTICLE
                                                                    + 'findbyid/';

    public static S_CORE_SERVICE_API_V1_ARTICLE__FIND_BY_MODULE_ID = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                                    + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                                    + Helperutil.S_CORE_SERVICE_API_V1_ARTICLE
                                                                    + 'findby/module/';

    /** Serie */
    public static S_CORE_SERVICE_API_V1_SERIE_GET_ALL = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                        + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                        + Helperutil.S_CORE_SERVICE_API_V1_SERIE
                                                        + 'list';

  public static S_CORE_SERVICE_GET_ALL_PUBLIC_SERIES = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_SERIES +
                                                        'public'

  public static S_CORE_SERVICE_GET_ALL_MORE_SEEN = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_SERIES +
                                                        'more-seen'

    public static S_CORE_SERVICE_API_V1_SERIE_FINDBYID = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                        + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                        + Helperutil.S_CORE_SERVICE_API_V1_SERIE
                                                        + 'findbyid';

    public static S_CORE_SERVICE_API_V1_SERIE_FINDBYMODULE = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                        + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                        + Helperutil.S_CORE_SERVICE_API_V1_SERIE
                                                        + 'findby/series';

    public static S_CORE_SERVICE_API_V1_ICONS_GET_ALL = Helperutil.S_CORE_SERVICE_API_V1_BASE
                                                        + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH
                                                        + Helperutil.S_CORE_SERVICE_API_V1_ICONS;

    public static S_CORE_SERVICE_GET_ALL_PUBLIC_INSTRUCTORS = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE +
                                                        Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH +
                                                        Helperutil.S_CORE_SERVICE_API_PAYMENT_INSTRUCTORS +
                                                        'public'

    public static S_CORE_AUTORITIE_ROL_STS    = ['{authority:"sts"}'];


    public static S_TOAS_TITLE_HEAD   = 'Kuaa';
    public static S_TOAS_POSITIONCLASS_TOP_CENTER = 'toast-top-center';
    public static S_TOAS_POSITIONCLASS_TOP_RIGHT = 'toast-top-right';
    public static S_TOAS_POSITIONCLASS_BTN_RIGHT = 'toast-bottom-right';
    public static S_TOAS_POSITIONCLASS_BTN_CENTER = 'toast-button-center';
    public static I_TOAS_TIMEOUT = 2000;
    public static S_SESSION_ENDED = 'Session is ended';
    public static S_SESSION_NOT_AUTORIZED = 'Unauthorized';
    public static S_SESSION_MSG_ACCESS_DENIED = 'Access denied!!!';
    public static S_TOAS_DEV_IN   = 'Developing';
    public static S_TOAS_PROCESS  = 'Process...';


    /** Payment */
    //User
    public static S_CORE_SERVICE_API_PAYMENT_USER = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH + Helperutil.S_CORE_SERVICE_API_PAYMENT_USERS

    //Subscription Plans
    public static S_CORE_SERVICE_API_PAYMENT_SUBCRIPTION_PLANS_LIST = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH + Helperutil.S_CORE_SERVICE_API_PAYMENT_SUBCRIPTION_PLANS

    //Credit cards
    public static S_CORE_SERVICE_PAYMENT_CREDIT_CARDS = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH + Helperutil.S_CORE_SERVICE_API_PAYMENT_CREDIT_CARDS

    //Credit cards
    public static S_CORE_SERVICE_API_PAYMENT_COUNTRIES_GET_ALL = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH + Helperutil.S_CORE_SERVICE_API_PAYMENT_COUNTRIES

    //Credit cards
    public static S_CORE_SERVICE_PAYMENT_PAYMENTS = Helperutil.S_CORE_SERVICE_API_PAYMENT_BASE + Helperutil.S_CORE_SERVICE_API_V1_BASE_PATH + Helperutil.S_CORE_SERVICE_API_PAYMENT_PAYMENTS


}
