<div class="container">
    <div class="row mt-5 mb-5">
        <div *ngFor="let podcast of allPodcast" class="col">
            <div class="card" style="width: 22rem;">
                <img src="{{podcast.banner1}} " class="card-img-top podcast-image" alt="...">
                <div class="card-body">
                    <h5 class="card-title podcast-title">{{podcast.title}}</h5>
                    <p class="card-text podcast-description">{{podcast.description}} </p>
                    <a>Escuchar</a>
                </div>
            </div>
        </div>
    </div>
</div>