import SlideContent from 'src/app/shared/types/slide-content'

import { Component } from '@angular/core'

@Component({
  selector: 'app-fullness',
  templateUrl: './fullness.component.html',
  styleUrls: ['./fullness.component.scss']
})
export class FullnessComponent {
  public slides: SlideContent[] = []

  constructor() {
    /**
     * publickuaa/static/img/plenitud/Batidos.jpg -->
     * publickuaa/static/img/plenitud/DietasNutricionales.png -->
     * publickuaa/static/img/plenitud/MoveToTransform.jpg -->
     * publickuaa/static/img/plenitud/MujerRadiante.png -->
     * publickuaa/static/img/plenitud/Semáforos.jpg --> 3
     * publickuaa/static/img/plenitud/DIETAS-PLANETARIAS.png -->
     *
     */
    this.slides = [
      { imgSrc: 'https://storage.googleapis.com/publickuaa/static/img/plenitud/Batidos.jpg', path: '/home/preview/baf168db-86dd-4ef4-8709-11dee63a04f6/0', title: 'Batidos' },
      { imgSrc: 'https://storage.googleapis.com/publickuaa/static/img/plenitud/DietasNutricionales.png', path: '/home/preview/9ae7d3c0-5c43-46cf-8882-5c9cb3575b51/0', title: 'Dietas Nutricionales' },
      { imgSrc: 'https://storage.googleapis.com/publickuaa/static/img/plenitud/MoveToTransform.jpg', path: '/home/preview/eef2fb52-9588-4f99-a859-2c13e06989a7/0', title: 'Move To Transform' },
      { imgSrc: 'https://storage.googleapis.com/publickuaa/static/img/plenitud/MujerRadiante.png', path: '/home/preview/f8219d3d-3c41-4719-b6fc-c18f7dabbb16/0', title: 'Mujer Radiante' },
      { imgSrc: 'https://storage.googleapis.com/publickuaa/static/img/plenitud/Semáforos.jpg', path: '/home/preview/1e31aeef-c655-4d58-9be6-3e95a0d44a34/0', title: 'Semáforos' },
      { imgSrc: 'https://storage.googleapis.com/publickuaa/static/img/plenitud/BOWLS-CERÁMICA.jpg', path: '/home/preview/1e31aeef-c655-4d58-9be6-3e95a0d44a34/0', title: 'BOWLS CERÁMICA' }
    ]
  }
}
