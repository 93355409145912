import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-level6',
  templateUrl: './level6.component.html',
  styleUrls: ['./level6.component.css']
})
export class Level6Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
