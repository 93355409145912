import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
//import { Http, Headers, Response } from '@angular/http';
import { Observable, from } from 'rxjs';
import { CategoryDto } from '../../model/dto/category/category-dto';
import { Helperutil } from '../../common/helper/helperutil';
import { __param } from 'tslib';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private httpClient: HttpClient) { }

  private getTokenSesion(sToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return httpOptions;
  }

  public save( categoryDto: CategoryDto, sToken): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.post<any>(Helperutil.S_CORE_SERVICE_API_V1_CATEGORY_SAVE, categoryDto, httpOptions);
  }

  public uploadFile(file, sToken, id): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    const httpParams = new HttpParams();
    httpParams.append('file', file);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + sToken
      })
    };
    const url = Helperutil.S_CORE_SERVICE_API_V1_CATEGORY_IMG_SAVE + id;
    return this.httpClient.post<any>(url, formData, httpOptions); 
  }

  public getCategories(sToken): Observable<CategoryDto[]> { 
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return this.httpClient.get<CategoryDto[]>(Helperutil.S_CORE_SERVICE_API_V1_CATEGORY_LIST, httpOptions);
  }

  public findById(id: string, sToken: string): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_CATEGORY_FINDBYID + `/${id}`, httpOptions );
  }

  public moduleFindById(id: string, sToken: string): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_MODULE_FINDBYID + `/${id}`, httpOptions );
  }

  

  

}
