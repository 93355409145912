import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from '../../../service/authorization/token.service';
import { KuaaTransactionDto } from '../../../common/trx/kuaa-transaction-dto';
import { KuaaTransactionGenericDto  } from '../../../common/trx/kuaa-transaction-generic-dto';
import { ArticleDto } from '../../../model/dto/article/article-dto';
import { ArticleService } from '../../../service/article/article.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {

  objArticleDto : ArticleDto = new ArticleDto();
  objKuaaTransactionGenericDto: KuaaTransactionGenericDto = new KuaaTransactionGenericDto();
  entityTransfer: ArticleDto[] = []; 

  constructor( private activatedRoute: ActivatedRoute
              , private tokenService:TokenService
               ,private objArticleService: ArticleService) { }

  ngOnInit(): void {
    this.loadArticle();
  }


  loadArticle(){
    const id = this.activatedRoute.snapshot.params.id; 
    const sToken = this.tokenService.getToken();
    this.objArticleService.getById(id, sToken).subscribe(
      data =>{        
        this.objKuaaTransactionGenericDto = data;
            if (this.objKuaaTransactionGenericDto.code == '00000') {
                 this.entityTransfer = [];
                 this.entityTransfer = this.objKuaaTransactionGenericDto.entityTransfer;
                 this.objArticleDto =  new ArticleDto();
                 this.objArticleDto = this.entityTransfer[0];
            }
      }
    );
  }

}
