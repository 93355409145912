import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Helperutil } from '../../common/helper/helperutil';
import { UserService } from './user.service'

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor(
    private httpClient: HttpClient,
    private userService: UserService
  ) { }

  public getAll(){
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_API_PAYMENT_COUNTRIES_GET_ALL, this.userService.createHeader());
  }
}
