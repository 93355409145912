<div class="div container-fluid banner_profile">
  <div class="container pb-5">
    <div class="row pb-5 pt-5">
      <div class="col-12 justify-content-start  ">
        <h1 class="sections_title">Mi perfil</h1>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 mt-3">
        <div class="row">
          <div class="col-12">
            <div id="img-profile" class="{{imageClass}}">
              <div class="align-self-center" style="width: 100%; text-align: center;">
                <img src="{{userData['photoURL']}}" id="imageProfile" style="width: 100%; height: auto;">
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2 mt-2">
          <div class="col-12">
            <button class="btn btn-primary menbresia-btn w-100" (click)="showImages()">Seleccionar imagen de portada</button>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-10 col-lg-10">

        <form id="subscribe-form" class="needs-validation p-2" novalidate>
          <div class="form-group pb-3">
            <label class="col-lg-6 col-md-6 col-sm-12 col-xs-12 control-label" for="firstName">Nombre</label>
            <input class="form-control form-control-dark" type="text" id="firstName" placeholder="Nombre"
              value="{{userData['firstName']}}" required>
          </div>
          <div class="form-group pb-3">
            <label class="col-lg-6 col-md-6 col-sm-12 col-xs-12 control-label" for="lastName">Apellidos</label>
            <input class="form-control form-control-dark" type="text" id="lastName" placeholder="Nombre"
              value="{{userData['lastName']}}" required>
              <input class="form-control form-control-dark" type="hidden" id="photoURL" value="{{userData['photoURL']}}"
              required>
          </div>
          <!--<div class="form-group pb-3">
            <label class="col-lg-6 col-md-6 col-sm-12 col-xs-12 control-label" for="cityCode">Ciudad</label>
            <input class="form-control form-control-dark" type="text" id="cityCode" placeholder="Ciudad"
              value="{{userData['cityCode']}}" required>

          </div>-->
          <div class="form-group pt-3">
            <div class="col-12">
              <input type="submit" class="btn btn-primary btn_poopins_black" value="GUARDAR CAMBIOS" (click)="save()" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
