import User from 'src/app/shared/types/user'

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Helperutil } from '../../common/helper/helperutil'
import { TokenService } from '../authorization/token.service'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {}

  public createHeader() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.tokenService.getToken()}`
      })
    }
  }

  public update(userData) {
    return this.httpClient.put<User>(Helperutil.S_CORE_SERVICE_API_PAYMENT_USER, userData, this.createHeader());
  }

  public unsubscribe() {
    return this.httpClient.put(`${Helperutil.S_CORE_SERVICE_API_PAYMENT_USER}unsubscribe`, {}, this.createHeader());
  }

  public get() {
    return this.httpClient.get<User[]>(`${Helperutil.S_CORE_SERVICE_API_PAYMENT_USER}info`, this.createHeader());
  }

  public activeGracePeriod() {
    return this.httpClient.post<User[]>(`${Helperutil.S_CORE_SERVICE_API_PAYMENT_USER}active_grace_period`, {}, this.createHeader());
  }

  public info() {
    return this.httpClient.get<User>(`${Helperutil.S_CORE_SERVICE_API_PAYMENT_USER}info`, this.createHeader());
  }


}
