import { Observable } from 'rxjs'
import { KuaaResponseDto } from 'src/app/model/dto/kuaa-response/kuaa-response-dto'
import { SerieDto } from 'src/app/model/dto/serie/serie-dto'

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Helperutil } from '../../common/helper/helperutil'

@Injectable({
  providedIn: 'root'
})
export class SerieService {

  constructor(private httpClient: HttpClient) { }

  private getHttpSettings() {
    const httpSettings = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return httpSettings;
  }

  public getList(sToken, code: string): Observable<any[]> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR_LIST, httpSettings);
  }

  public findAll(sToken = ''): Observable<any> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_SERIE_GET_ALL , httpSettings );
  }

  public findById(id: string): Observable<KuaaResponseDto<SerieDto>> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.get<KuaaResponseDto<SerieDto>>(Helperutil.S_CORE_SERVICE_API_V1_SERIE_FINDBYID + `/${id}`, httpSettings );
  }

  public getAll(): Observable<any> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_GET_ALL_PUBLIC_SERIES, httpSettings)
  }

  public getAllMoreSeen(): Observable<any> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_GET_ALL_MORE_SEEN, httpSettings)
  }
}
