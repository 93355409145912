export class InstructorDto {
    id:             string;
    status:         string;
    userCreate:     string;
    userUpdate:     string;
    dateCreate:     string;
    dateUpdate:     string;
    idClient:       string;
    firstName:      string;
    lastName:       string;
    surname:        string;
    secondSurname:  string;
    description:    string;
    email:          string;
    phone:          string;
    moduleCode:     string;
    moduleName:     string;
    professionCode: string;
    professionName: string;
    countryCode:    string;
    countryName:    string;
    smFacebook:     string;
    smInstagram:    string;
    smTwitter:      string;
    urlWeb:         string;
    banner1:        string;
    banner2:        string;
    banner3:        string;
    class1:         string;
    class2:         string;
    class3:         string;
    classImg1:      string;
    classImg2:      string;
    classImg3:      string;
    position:       string;
}


