<app-loader></app-loader>
<div class="modules-login position-relative" style="background-color: black;">
    <img src="assets/inicioSesion/IMAGEN-DE-FONDO.png" class="w-100 background-image h-auto" alt="Encuentra balance y disfruta la vida con Kuaá">
    <img src="assets/inicioSesion/IMAGEN-DE-FONDO-MOBILE.jpg" class="w-100 background-image-mobile h-auto" alt="Encuentra balance y disfruta la vida con Kuaá">
    <div class="d-flex justify-content-center position-absolute" style="top: 2%; left: 0; right: 0;">
        <div class="container">
            <div class="row justify-content-start">
                <div class="col-xl-7 col-lg-7 col-md-12 d-md-block d-none d-sm-none justify-content-center alinear_centro mt-2">
                    <div class="pt-5">
                        <hr class="linea_azul" style="width: 100%; float: right;">
                        <p class="register_login_main_title">¡Hola! <br><br>

                            Estás a un paso de ser parte del universo Kuaá. Regístrate y disfruta 7 días gratis.</p>
                        <hr class="linea_azul">
                    </div>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-12 mt-2">
                    <div class="pt-1 pb-5">
                        <div class="register_login_form" style="padding: 20px 2vw;">
                            <form id="subscribe-form" class="needs-validation p-2" [formGroup]="formRegister" novalidate>
                                <h5 class="font-weight-bold mb-2 mt-2 text-white">1. Selecciona tu plan</h5>

                                <div id="planSelected">
                                    <div class="row">


                                        <div *ngFor="let plan of subscriptionPlans" class="col-12 col-sm-12 col-md-6">
                                            <div class="custom-form-check mb-3">
                                                <input class="custom-form-check-input" type="radio" name="plan"
                                                    id="{{plan.id}}" value="{{plan.id}}" formControlName="plan"
                                                    required>
                                                <label class="custom-form-check-label text-white ps-2"
                                                    for="{{plan.id}}">
                                                    <strong> {{plan.name}} <span
                                                            class="badge badge-main">{{plan.tag}}</span></strong>
                                                    <br>
                                                    <p class="mb-0">COP {{plan.value}}</p>
                                                    <small class="test-days-message"> <b>7 días de prueba gratis</b> </small>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h5 class="font-weight-bold mb-2 mt-2 text-white">2. Datos de la tarjeta de crédito</h5>

                                <div class="form-group pb-3">
                                    <input type="card_name" class="form-control form-control-dark" type="text"
                                        id="card_name" placeholder="Nombre en la tarjeta" formControlName="card_name"
                                        required>
                                </div>
                                <div class="form-group pb-3">
                                    <input type="card_number" class="form-control form-control-dark" type="number"
                                        id="card_number" placeholder="Número de la tarjeta" maxlength="19"
                                        formControlName="card_number" required>
                                    <div
                                        *ngIf="formRegister.controls['card_number'].invalid && (formRegister.controls['card_number'].dirty)">
                                        <div *ngIf="formRegister.controls['card_number'].status == 'INVALID'">
                                            <p class="text-danger">Escribe un número de tarjeta válido.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group pb-3">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col label-description">Fecha de vencimiento</div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <select name="year" id="year" class="form-control form-control-dark"
                                                        formControlName="year" required>
                                                        <option value="">Año</option>
                                                        <option *ngFor="let year of years" value="{{year}}">{{year}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col">
                                                    <select name="month" id="month"
                                                        class="form-control form-control-dark" formControlName="month"
                                                        required>
                                                        <option value="">Mes</option>
                                                        <option *ngFor="let month of months" value="{{month}}">
                                                            {{month}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group col">
                                                <label class="label-description" for="security_code">Código de
                                                    seguridad</label>
                                                <input type="security_code" class="form-control form-control-dark"
                                                    type="number" id="security_code" placeholder="CVV"
                                                    formControlName="security_code" required>
                                                <div
                                                    *ngIf="formRegister.controls['security_code'].invalid && (formRegister.controls['security_code'].dirty)">
                                                    <div
                                                        *ngIf="formRegister.controls['security_code'].status == 'INVALID'">
                                                        <p class="text-danger">Escribe un código de seguridad
                                                            válido.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h5 class="font-weight-bold mb-2 mt-2 text-white">3. Ingresa tus datos</h5>


                                <div class="form-group pb-3">
                                    <div class="row">
                                        <div class="col-6">
                                            <input type="text" class="form-control form-control-dark" type="text"
                                                id="address" placeholder="Dirección de residencia"
                                                formControlName="address" required>
                                        </div>
                                        <div class="col-6">
                                            <input type="state" class="form-control form-control-dark" type="text"
                                                id="postalCode" placeholder="Código postal" formControlName="postalCode"
                                                required>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group pb-3">
                                    <div class="row">
                                        <div class="col-6">
                                            <input type="text" class="form-control form-control-dark" type="text"
                                                id="city" placeholder="Ciudad" formControlName="city" required>
                                        </div>
                                        <div class="col-6">
                                            <select name="state" id="state" class="form-control form-control-dark"
                                                formControlName="state" required>
                                                <option value="">Departamento</option>
                                                <option *ngFor="let state of states" value="{{state}}">{{state}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group pb-3">
                                    <select name="country" id="country" class="form-control form-control-dark"
                                        formControlName="country" required>
                                        <option value="">País</option>
                                        <option *ngFor="let country of countries" value="{{country.alpha2Code}}">
                                            {{country.name}}</option>
                                    </select>
                                </div>

                                <div class="form-group pb-3">
                                    <div class="row">
                                        <div class="col-6">
                                            <input type="state" class="form-control form-control-dark" type="text"
                                                id="phone" placeholder="Número de celular" formControlName="phone"
                                                required>
                                        </div>
                                        <div class="col-6">
                                            <input type="state" class="form-control form-control-dark" type="text"
                                                id="dniNumber" placeholder="Documento de identidad"
                                                formControlName="dniNumber" required>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group pt-3">
                                    <div class="col-12">
                                        <input type="submit" class="btn btn-primary btn_poopins_black"
                                            value="SUSCRÍBETE" (click)="goToPayment()" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
