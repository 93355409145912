import SlideContent from 'src/app/shared/types/slide-content'

import { Component, OnInit } from '@angular/core'

import { SerieService } from '../../../service/serie/serie.service'

@Component({
  selector: 'app-more-popular',
  templateUrl: './more-popular.component.html',
  styleUrls: ['./more-popular.component.scss']
})
export class MorePopularComponent implements OnInit {

  slides: SlideContent[]
  slidesMoreSeen: SlideContent[]

  constructor(
    private objSerieService: SerieService,
  ) { }

  ngOnInit(): void {
    //this.loadCoursesDefault()
    this.getAllMoreSeen()
  }

  async loadCoursesDefault(): Promise<void> {
    try {
      const data = await this.objSerieService.findAll().toPromise()
      const filtered = data.filter((s, i) => i % 2 === 0)
      this.slides = filtered.map((s) => ({
        title: s.title,
        imgSrc: s.banner1,
        path: ['/home/preview', s.id, '0']
      }))
    } catch (error) {
      console.error('Error getting series: ', error)
    }
  }

  public getAllMoreSeen(){
    this.objSerieService.getAllMoreSeen().subscribe(
      response => {
        this.slidesMoreSeen = response.map((s) => ({
          title: s.title,
          imgSrc: s.banner1,
          path: ['/home/preview', s.id, '0']
        }))
      }
    )
  }
}
