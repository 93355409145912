import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '../../../../shared/services/auth.service';
import { Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { UserService } from 'src/app/service/payments/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css','./bootstrap.css']
})
export class RegisterComponent implements OnInit {

  public emailRegistered: ''
  public passwordRegistered: ''
  public formRegisterValid: boolean = true


  constructor(
    public auth : AuthService,
    public router: Router,
    private userService: UserService
  ) { }

  formRegister = new FormGroup({
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    password: new FormControl('',[
      Validators.required]),
    confirmation: new FormControl('',[
      Validators.required]),
      acceptedPrivacyPolicy: new FormControl('',[Validators.required, Validators.pattern('true')]),
      acceptedTermsAndConditions: new FormControl('',[Validators.required, Validators.pattern('true')])
    });

//$@$!%*?&.,$@$!%*?&.,!#$%&/()=?¡¿~></+
  ngOnInit(): void {
  }

  register(){
    if( this.formRegister.valid){
      document.getElementById('errorCheck').style.display = 'none'
      this.auth.SignUp(this.email.value, this.password.value).then((response) => {
        if(response){
          this.auth.SignIn(this.email.value, this.password.value, 'is_payment', this.formRegister.value.acceptedPrivacyPolicy, this.formRegister.value.acceptedTermsAndConditions)
        }
      })
    }else{
      document.getElementById('errorCheck').style.display = 'block'
    }

  }

  get email(){
    return this.formRegister.get('email')
  }
  get password(){
    return this.formRegister.get('password')
  }
  get confirmation(){
    return this.formRegister.get('confirmation')
  }

  public checkPassword(){
    let password = this.formRegister.value.password
    let lowerCase = new RegExp("(?=.*[a-z])")
    let upperCase = new RegExp("(?=.*[A-Z])")
    let number = new RegExp("(?=.*[0-9])")//!#$%&/()=?¡¿~></+
    let car = /[$@$!%*?&;.,!#$%&/()=?¡¿~></+]/
    let caracter = new RegExp(car)
    let length = password.length
    if(password.match(lowerCase) != null && password.match(upperCase) != null && password.match(number) != null && password.match(caracter) != null && length >= 9){
      document.getElementById('errorMessage').style.display = 'none'
    }else{
      document.getElementById('errorMessage').style.display = 'block'
      this.changeColorMessage(password, 'lowerCase', lowerCase)
      this.changeColorMessage(password, 'upperCase', upperCase)
      this.changeColorMessage(password, 'number', number)
      this.changeColorMessage(password, 'caracter', caracter)
      this.changeColorMessage(password, 'length', length)
    }
  }

  public changeColorMessage(password, field, condition){
    if(password.match(condition) != null){
      document.getElementById(field).classList.remove('text-danger')
      document.getElementById(field).classList.add('text-success')
    }else{
      document.getElementById(field).classList.add('text-danger')
      document.getElementById(field).classList.remove('text-success')
    }
  }

  public passwordValidator(){
    if(this.formRegister.value.password != this.formRegister.value.confirmation){
      this.formRegisterValid = true
    }else{
      this.formRegisterValid = false
    }
  }

}
