export class CategoryDto {
    id:          string;
    code:        string;
    name:        string;
    idClient:    string;
    title:       string;
    subtitle:    string;
    description: string;
    banner1:     string;
    banner2:     string;
    banner3:     string;
    status:      string;
}
