export class ArticleDto {
    idClient : string;
    name : string;
    title : string;
    subtitle : string;
    description : string;
	status : string;
    banner1 : string;
    banner2 : string;
    banner3 : string;
    class1 : string;
    class2 : string;
    class3 : string;
    classImg1 : string;
    classImg2 : string;
    classImg3 : string;
    position : string;
    
    instructorCode : string;
    instructorName : string;
    moduleName: string;
    moduleCode: string;

    parrafoA : string;
    parrafoB : string;
    parrafoC : string;
}
