import { CourseDto } from 'src/app/model/dto/course/course-dto'
import { KuaaResponseDto } from 'src/app/model/dto/kuaa-response/kuaa-response-dto'
import { PlayVideosService } from 'src/app/service/videos/play-videos.service'
import { finalize } from 'rxjs/operators'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { ArticleDto } from '../../../model/dto/article/article-dto'
import { InstructorDto } from '../../../model/dto/instructor/instructor-dto'
import { PodcastDto } from '../../../model/dto/podcast/podcast-dto'
import { ArticleService } from '../../../service/article/article.service'
import { TokenService } from '../../../service/authorization/token.service'
import { CourseService } from '../../../service/course/course.service'
import { InstructorService } from '../../../service/instructor/instructor.service'
import { PodcastService } from '../../../service/podcast/podcast.service'
import { SerieService } from '../../../service/serie/serie.service'

@Component({
  selector: 'app-instructor-home',
  templateUrl: './instructor-home.component.html',
  styleUrls: ['./instructor-home.component.scss']
})
export class InstructorHomeComponent implements OnInit {

  objInstructorDto: InstructorDto = new InstructorDto();
  objKuaaTransactionGenericDto: KuaaResponseDto<InstructorDto>;
  entityTransfer: InstructorDto[] = [];
  objListPodcastDto: PodcastDto[] = [];
  objListArticleDto: ArticleDto[] = [];
  relatedCourses = []
  public ids = []
  arrayVideos: any = []
  seasonChapter: any = []
  codeModules: any
  socialMedia: string
  public url: string = '/home'
  public module: string = ''
  description
  public countryCode
  id = this.activatedRoute.snapshot.params.id
  sToken = this.tokenService.getToken();

  constructor(private activatedRoute: ActivatedRoute
    , private tokenService: TokenService
    , private objInstructorService: InstructorService
    , private objPodcastService: PodcastService
    , private objArticleService: ArticleService,
    private objSerieService: SerieService,
    private objCourseService: CourseService,
    private router: Router,
    private playVideosService: PlayVideosService
  ) { }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.idCourse != 0) {
      this.url = `/home/preview/${this.activatedRoute.snapshot.params.idCourse}/0`
    }
    this.loadInstructor()
    this.loadAllSeries()
  }


  loadInstructor() {
    this.objInstructorService.findById(this.id, this.sToken).subscribe(
      data => {
        this.objKuaaTransactionGenericDto = data;
        if (this.objKuaaTransactionGenericDto.code == '00000') {
          this.entityTransfer = [];
          this.entityTransfer = this.objKuaaTransactionGenericDto.entityTransfer;
          this.objInstructorDto = new InstructorDto();
          this.objInstructorDto = this.entityTransfer[0];
          this.countryCode = this.objInstructorDto.countryCode.substr(0, 3)
          this.description = this.objInstructorDto.description.split('salto_linea')
          this.socialMediaURL()
          for (let i = 0; i < this.objInstructorDto.moduleCode.length; i++) {
            this.getCoursesBySerie(this.objInstructorDto.moduleCode[i])
          }
        }
      }
    );
  }

  public socialMediaURL() {
    if (this.objInstructorDto.smInstagram != '#' && this.objInstructorDto.smInstagram != null && this.objInstructorDto.smInstagram != '') {
      this.socialMedia = this.objInstructorDto.smInstagram
    } else if (this.objInstructorDto.smFacebook != '#' && this.objInstructorDto.smFacebook != null && this.objInstructorDto.smFacebook != '') {
      this.socialMedia = this.objInstructorDto.smFacebook
    } else if (this.objInstructorDto.smTwitter != '#' && this.objInstructorDto.smTwitter != null && this.objInstructorDto.smTwitter != '') {
      this.socialMedia = this.objInstructorDto.smTwitter
    } else {
      this.socialMedia = 'No social media'
    }
  }

  loadPodcast() {
    this.objPodcastService.getByUserCode(this.id, this.sToken).subscribe(
      data => {
        this.objListPodcastDto = data;
        let counter = 0;
        this.objListPodcastDto.forEach(element => {
          element.position = "transform: translateX( " + counter + "px); width: 200px;";
          counter = counter + 210;
        });
      }
    );
  }

  loadArticle() {
    this.objArticleService.getByUserCode(this.id, this.sToken).subscribe(
      data => {
        this.objListArticleDto = data;
        let counter = 0;
        this.objListArticleDto.forEach(element => {
          element.position = "transform: translateX( " + counter + "px); width: 200px;";
          counter = counter + 210;
        });
      }
    );
  }



  goTo(sUrl) {
    //window.location.href = sUrl ;
    window.open(sUrl, "_blank");
  }

  goiting(URLSocialMedia) {
    window.open(URLSocialMedia, '_blank');
    //location.href = URLSocialMedia
  }

  public getCoursesBySerie(id) {
    this.objInstructorService.getCoursesByInstructor(this.sToken, id).subscribe(
      data => {
        if (data['entityTransfer'].length > 0) {
          data['entityTransfer'].forEach(element => {
            this.relatedCourses.push(element)
          });
        }
      },
      err => {
        console.error(err);
      }
    );
  }


  public showSinopsis(id) {
    document.getElementById(`sinopsis_${id}`).style.display = 'block'
    document.getElementById(`play_${id}`).style.display = 'block'
  }

  public hideSinopsis(id) {
    document.getElementById(`sinopsis_${id}`).style.display = 'none'
    document.getElementById(`play_${id}`).style.display = 'none'
  }



  loadAllSeries(): void {
    const sToken = this.tokenService.getToken();

    this.objSerieService.findAll(sToken).subscribe(
      data => {
        data.forEach(element => {
          if (element.autores.includes(this.id)) {
            this.module = element.id
            this.ids = []
            element.objListSerieContent.forEach(async e => {
              //await this.getDataVideo(e.contentId, e.season)
              await this.ids.push(e.contentId)
            })
            this.sendID(0)
          }
        })
      },
      err => {
        console.error(err)
      }
    );
  }

  public sendID(i) {
    this.video(this.ids[i], i)
  }

  public video(id, i) {
    this.objCourseService.findById(id)
      .pipe(
        finalize(() => {
          if(i < this.ids.length){
            this.sendID(i + 1)
          }
        })
      )
      .subscribe(
        data => {
          const course: Partial<CourseDto & { moduleCode: any, type: string, videoBaseCode?: string }> = <CourseDto>(data.entityTransfer[0])
          if(course.id != null){
            course.type = 'Video'
            this.arrayVideos.push(course.videoBaseCode)
            this.seasonChapter.push(course)
          }
        },
        err => {
          console.error(err);
        },
      )
  }

  async getDataVideo(id, season, currentIndex = null) {
    //this.idVideos.push(id)
    await this.objCourseService.findById(id)
      .subscribe(
        data => {
          const course: Partial<CourseDto & { moduleCode: any, type: string, videoBaseCode?: string }> = <CourseDto>(data.entityTransfer[0])
          course.type = 'Video'
          this.arrayVideos.push(course.videoBaseCode)
          this.seasonChapter.push(course)
        },
        err => {
          console.error(err);
        },
      );
  }

  playVideos(i = '', videoBaseCode = '') {
    if (videoBaseCode != null) {
      this.playVideosService.playVideos(this.id, videoBaseCode, 'isInstructor=yes')
    }
  }

}
