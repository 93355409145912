import { Tooltip } from 'bootstrap'

import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css']
})
export class PaymentStatusComponent implements OnInit {
  @Input() status: number = 0;
  @Input() error: string;

  constructor() { }

  ngOnInit(): void {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    [].map.call(tooltipTriggerList, tooltipTriggerEl => new Tooltip(tooltipTriggerEl));
  }

}
