import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'

import {
    PublicfooterComponent
} from '../components/login/public/publicfooter/publicfooter.component'
import { PublicheadComponent } from '../components/login/public/publichead/publichead.component'
import { ButtonComponent } from './components/button/button.component'
import { ModalComponent } from './components/modal/modal.component'
import { SliderComponent } from './components/slider/slider.component'
import { SpinnerComponent } from './components/spinner/spinner.component'
import { VideoPlayerComponent } from './components/video-player/video-player.component'
import { DateFromSecondsPipe } from './pipes/date-from-seconds.pipe'
import { SecondsToDurationPipe } from './pipes/seconds-to-duration.pipe'

@NgModule({
  declarations: [
    DateFromSecondsPipe,
    PublicfooterComponent,
    PublicheadComponent,
    ModalComponent,
    SpinnerComponent,
    ButtonComponent,
    VideoPlayerComponent,
    SecondsToDurationPipe,
    SliderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule
  ],
  exports: [
    DateFromSecondsPipe,
    PublicfooterComponent,
    PublicheadComponent,
    ModalComponent,
    SpinnerComponent,
    ButtonComponent,
    VideoPlayerComponent,
    SliderComponent
  ]
})
export class SharedModule { }
