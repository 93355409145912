import { PodcastService } from 'src/app/service/podcast/podcast.service'
import { SerieService } from 'src/app/service/serie/serie.service'

import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { TokenService } from '../../service/authorization/token.service'
import { SuscriptorService } from '../../service/suscriptor/suscriptor.service'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['../hostel/hostel/hostel.custom.css', './search.component.css']
})
export class SearchComponent implements OnInit {

  public series = []
  public podcasts = []
  public sToken = this.tokenService.getToken()
  constructor(
    private router: Router,
    private suscriptorService: SuscriptorService,
    private tokenService: TokenService,
    private serieService: SerieService,
    private podcastService: PodcastService,
  ) { }

  ngOnInit(): void {
    let getRoute = this.router.url.split('/')
    if (getRoute[getRoute.length - 1] === 'myList') {
      this.getSeriesInMyList()
      this.getPodcastInMyList()
    }
  }

  public getSeriesInMyList() {
    this.suscriptorService.getMyList(this.sToken).subscribe(
      data => {
        data.entityTransfer.forEach(element => {
          element.series.forEach(idSerie => {
            this.serieService.findById(idSerie).subscribe(
              data => {
                this.series.push({ banner: data.entityTransfer[0].banner1, title: data.entityTransfer[0].title, subtitle: data.entityTransfer[0].subtitle, id: data.entityTransfer[0].id })
              },
              err => {
                console.error(err)
              }
            )
          })
        })
      }
    )
  }

  public getPodcastInMyList() {
    this.suscriptorService.getMyList(this.sToken).subscribe(
      data => {
        data.entityTransfer.forEach(element => {
          element.podcast.forEach(idPodcast => {
            this.podcastService.getById(idPodcast, this.sToken).subscribe(
              data => {
                this.podcasts.push({ banner: data.entityTransfer[0].banner1, title: data.entityTransfer[0].title, subtitle: data.entityTransfer[0].subtitle, id: data.entityTransfer[0].id })
              },
              err => {
                console.error(err)
              }
            )
          })
        })
      }
    )
  }

}
