<div class="div container-fluid seccion_article pb-5">
    <div class="container">
      <div class="row">
        <div class="col col-lg-9 col-md-8 co-sm-6 justify-content-start ">
          <a href="/home/instructor/{{ objArticleDto.instructorCode }}" >
            <h1 class="section_title_podcast"><i class="fas fa-arrow-left icon_h1 align-middle"></i>Regresa para ver más contenidos</h1>
          </a>
        </div>
        <div class="col col-lg-3 col-md-4 col-sm-6 justify-content-end logo_footer btns_right">
          <img src="../../../../assets/line_avatar.png" class="line_avatar_big">
          <img src="../../../../assets/article_icon.png" class="article_icon">
        </div>
      </div>
      <div class="row alinear_centro pb-5 px-3">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 justify-content-center alinear_centro border_box px-0">
          <div class="article_box">
            <img src="{{ objArticleDto.banner1 }}" class="img-fluid img_article">
            <img src="../../../../assets/label_icon_article.png" class="label_icon_article">
          </div>
          <div class="article_title py-5">
            <div class="row">
              <div class="col-2 px-5 float-left">
                <img src="../../../../assets/icon_info_article.png">
              </div>
              <div class="col px-4 float-left">
                <h3 class="author_name">{{ objArticleDto.instructorName }}</h3>
                <p class="author_profesion"><i> {{ objArticleDto.moduleName }} </i></p>
                <p class="article_title"><i> {{ objArticleDto.name }} </i></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 justify-content-center alinear_centro border_box">
          <div class="px-5 py-5 title_article">
            <h1 class="section_title_article">{{ objArticleDto.title }}</h1>
          </div>
          <div class="px-5 py-3 descripcion_article mb-1">
            <p class="section_descripcion_article">{{ objArticleDto.description }}</p>
          </div>
          <div class="px-5 py-3 descripcion_article mb-1">              
              <div class="section_descripcion_article" [innerHTML]="objArticleDto.parrafoA"></div>
          </div>
          <div class="px-5 py-3 descripcion_article mb-1">              
            <div class="section_descripcion_article" [innerHTML]="objArticleDto.parrafoB"></div>
          </div>
          <div class="px-5 py-3 descripcion_article mb-1">              
            <div class="section_descripcion_article" [innerHTML]="objArticleDto.parrafoC"></div>
          </div>

          <div class="px-5 pb-3 btn_download_pdf">
            <!--
            <img src="../../../../assets/btn_download_pdf.png">
            -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer class="container-fluid py-5 div_footer">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md col-sm-12 mb-5 logo_footer">
                <img src="../../../../assets/logo_footer.png" class="logo_footer">
            </div>
            <div class="col-6 col-md">
                <h5 class="title_footer">EXPLORAR</h5>
                <ul class="list-unstyled text-small">
                    <li><a class="link-secondary" href="#">Módulos</a></li>
                    <li><a class="link-secondary" href="#">Lecciones</a></li>
                    <li><a class="link-secondary" href="#">Instructores</a></li>
                </ul>
            </div>
            <div class="col-6 col-md">
                <h5 class="title_footer">LEGAL</h5>
                <ul class="list-unstyled text-small">
                    <li><a class="link-secondary" href="#">Privacidad</a></li>
                    <li><a class="link-secondary" href="#">Términos</a></li>
                    <li><a class="link-secondary" href="#">Condiciones</a></li>
                    <li><a class="link-secondary" href="#">Soporte</a></li>
                </ul>
            </div>
            <div class="col-6 col-md">
                <h5 class="title_footer">SOCIAL</h5>
                <img src="../../../../assets/social_icons.png">
            </div>
            <div class="col-6 col-md mb-5">
                <h5 class="title_footer">CENTRO DE AYUDA</h5>
            </div>
            <div class="col-12 col-md col-sm-12">
                <ul class="list-unstyled text-small">
                    <li><a class="link-secondary" href="#">© KUAÁ 2021. Todos los derechos reservados</a></li>
                </ul>
            </div>
        </div>
    </div>
  </footer>  