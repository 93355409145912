import { Component, OnInit } from '@angular/core'

import { AuthService } from '../../../../shared/services/auth.service'

@Component({
  selector: 'app-recoverpassword',
  templateUrl: './recoverpassword.component.html',
  styleUrls: ['./recoverpassword.component.css','./bootstrap.css']
})
export class RecoverpasswordComponent implements OnInit {

  constructor(
    public authService: AuthService
  ) { }

  ngOnInit(): void {
  }

}
