import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreditCardsService } from 'src/app/service/payments/credit-cards.service';
import { PaymentsService } from 'src/app/service/payments/payments.service';
import { TokenService } from 'src/app/service/authorization/token.service';
import Swal from 'sweetalert2';
var md5 = require('md5');
@Component({
  selector: 'app-create-credit-cards',
  templateUrl: './create-credit-cards.component.html',
  styleUrls: ['./create-credit-cards.component.css']
})
export class CreateCreditCardsComponent implements OnInit {

  public years = []
  public months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  private deviceSessionId
  public planID

  constructor(
    private creditCardsService: CreditCardsService,
    private paymentsService: PaymentsService,
    private tokenService: TokenService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.createDeviceSessionId()
    this.createArrayYears()
  }

  formRegister = new FormGroup({
    card_name: new FormControl('', [Validators.required]),
    card_number: new FormControl('', [Validators.required, Validators.minLength(14), Validators.maxLength(19)]),
    year: new FormControl('', [Validators.required]),
    month: new FormControl('', [Validators.required]),
    security_code: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.min(3), Validators.min(4)])
  })

  private createDeviceSessionId() {
    const ct = new Date()
    this.deviceSessionId = md5(`${this.tokenService.getUserID()}${ct.toISOString()}`)
    const script = document.createElement('script')
    script.src = `https://maf.pagosonline.net/ws/fp/tags.js?id=${this.deviceSessionId}80200`
    script.async = true
    document.body.appendChild(script)
  }

  private createArrayYears() {
    const cy = new Date().getFullYear()
    for (let i = cy; i <= cy + 20; i++) {
      this.years.push(i)
    }
  }

  public goToPayment() {
    this.planID = <HTMLInputElement>document.getElementById('planID')
    if (!this.formRegister.valid) {
      const error = '2px red solid'
      const ok = '1px #14b8ec solid'
      for (const property in this.formRegister.controls) {
        const field = property == 'plan' ? 'planSelected' : property
        this.formRegister.controls[property].errors === null ? document.getElementById(field).style.border = ok : document.getElementById(field).style.border = error
      }
    } else {
      document.getElementById('loader-kuaa').style.zIndex = '5'
      this.createCreditCard()
    }
  }

  private createCreditCard() {
    const creditCardData = {
      dniNumber: this.formRegister.value.dniNumber,
      number: this.formRegister.value.card_number,
      securityCode: this.formRegister.value.security_code,
      expirationDate: `${this.formRegister.controls['year'].value}/${this.formRegister.controls['month'].value}`,
      name: this.formRegister.value.card_name
    }
    this.creditCardsService.create(creditCardData).subscribe(
      result => {
        this.createPayment()
      },
      error => {
        console.error('Error --> ', error)
      }
    )
  }

  private createPayment() {
    const creditCardData = {
      subscriptionPlanId: this.planID.value,
      deviceInfo: {
        deviceSessionId: this.deviceSessionId,
        cookie: this.tokenService.getUserID()
      }
    }

    this.paymentsService.create(creditCardData).subscribe(
      result => {
        document.getElementById('loader-kuaa').style.zIndex = '-5'
        if(result['status'] == 'rejected'){
          this.printMessage('Error al registrar la tarjeta', `<p>Se presentó el siguiente error con la tarjeta <b>${result['transactionError']}</b><p>`, 'error', 'Revisar mis tarjetas')
        }else{
          this.printMessage('Proceso de inscripción correcto', `<p>Se registró correctamente la tarjeta.<p>`, 'success', 'Aceptar')
        }
      },
      error => {
        console.error('Error -> ', error)
      }
    )
  }

  private printMessage(title, html, icon, textButton){
    Swal.fire({
      title: title,
      html: html,
      icon: icon ,
      confirmButtonColor: '#14b8ec',
      confirmButtonText: textButton,
      background: 'black',
      color: '#14b8ec'
    }).then((res) => {
      this.router.navigate(['/home/credit_cards'])
    })
  }

}
