<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.1/css/all.css">
<div class="div container-fluid header_background">
  <div class="header">
    <div class="header-bar container_100 d-flex align-items-center justify-content-between">
      <div class="" id="branding">
        <h2 class="logo align-middle">
          <a href="/home">
            <img src="/assets/logos/MARCA-KUAÁ-tag-line.png" class="desktop logo_width img-fluid" />
            <img src="/assets/logos/mobile_slogan.png" class="mobile logo_width_mobile img-fluid" />
          </a>
        </h2>
      </div>

      <div class="d-flex align-items-center">
        <div class="search-form input-group" (click)="search($event)">
          <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="search-addon">
          <button type="button" class="btn btn-outline-primary" id="search-addon" title="Search">
            <i class="fa fa-search"></i>
          </button>
        </div>

        <div class="profile-menu-wrapper">
          <button type="button" class="show-menu-btn" (click)="showHideMenu()" title="Profile Menu">
            <i class="far fa-user" id="icon-main-user"></i>
            <img src="" alt="Avatar" id="main-image" style="display: none;" >
          </button>

          <div class="main-menu-kuaa" *ngIf="authService.userData as user" id="main-menu-kuaa" >
            <div class="profile-menu-backdrop" (click)="showHideMenu()"></div>

            <div class="profile-menu">
              <div class="d-link-menu">
                <a class="link-menu" href="home/profile/{{user.uid}}"><i class="fa fa-user icon_menu"></i>Mi perfil</a>
              </div>
              <div class="d-link-menu">
                <a class="link-menu" href="home/account/{{user.uid}}"><i class="fa fa-bullhorn icon_menu"></i>Cuenta</a>
              </div>
              <div class="d-link-menu">
                <a class="link-menu" [routerLink]="['/home/billing-user']"><i class="fa fa-bullhorn icon_menu"></i>Facturación</a>
              </div>
              <div class="d-link-menu">
                <a class="link-menu" style="cursor: pointer;" (click)="onLogOut()"><i
                    class="fa fa-sign-out-alt icon_menu"></i>Salir</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
