import { SerieService } from 'src/app/service/serie/serie.service'
import { PlayVideosService } from 'src/app/service/videos/play-videos.service'
import Swiper from 'swiper'

import { Component, NgZone, OnInit, ViewChild } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'

import { KuaaTransactionGenericDto } from '../../../../common/trx/kuaa-transaction-generic-dto'
import { CategoryDto } from '../../../../model/dto/category/category-dto'
import { ModuleDto } from '../../../../model/dto/module/module-dto'
import { PodcastDto } from '../../../../model/dto/podcast/podcast-dto'
import { ArticleService } from '../../../../service/article/article.service'
import { TokenService } from '../../../../service/authorization/token.service'
import { CategoryService } from '../../../../service/category/category.service'
import { CourseService } from '../../../../service/course/course.service'
import { InstructorService } from '../../../../service/instructor/instructor.service'
import { PodcastService } from '../../../../service/podcast/podcast.service'
import { AuthService } from '../../../../shared/services/auth.service'
import { ICarouselItem } from '../../../shared/carousel/Icarousel-item.metadata'
import SlideContent from 'src/app/shared/types/slide-content'

@Component({
  selector: 'app-category-home',
  templateUrl: './category-home.component.html',
  styleUrls: ['./category-home.component.scss']
})
export class CategoryHomeComponent implements OnInit {

  @ViewChild('myCarousel') myCarousel;
  objCategoryDto: CategoryDto = new CategoryDto();
  objModuleDto: ModuleDto = new ModuleDto();
  //entityTransfer: CategoryDto[] = [];
  entityTransfer: ModuleDto[] = [];
  objKuaaTransactionGenericDto: KuaaTransactionGenericDto = new KuaaTransactionGenericDto();
  objKuaaTransactionGenericPodcastDto: KuaaTransactionGenericDto = new KuaaTransactionGenericDto();
  objKuaaTransactionGenericArticleDto: KuaaTransactionGenericDto = new KuaaTransactionGenericDto();
  public carouselData: ICarouselItem[] = [];
  objListCatAlimens: CategoryDto[] = [];
  objListPodcasts: PodcastDto[] = [];
  entityTransferP: PodcastDto[] = [];

  objArticleDtos: PodcastDto[] = [];
  entityTransferA: PodcastDto[] = [];
  objListCourses = [];
  objListInstructors = [];
  objListSeries: any[] = [];
  id = this.activatedRoute.snapshot.params.id
  public fellowsTraveler = []
  slides: SlideContent[]
  slidesInstructors: SlideContent[]
  public instructor: SafeHtml

  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    private objCategoryService: CategoryService,
    private objCourseService: CourseService,
    private tokenService: TokenService,
    private activatedRoute: ActivatedRoute,
    private objPodcastService: PodcastService,
    private objArticleService: ArticleService,
    private objSerieService: SerieService,
    private instructorService: InstructorService,
    private sanitized: DomSanitizer,
    private playVideosService: PlayVideosService
  ) {
    this.loadSerieDefault()
  }

  ngOnInit(): void {
    this.showLogoModule()
    this.loadCategory()
    this.getFellowsTraveler()
  }

  public showLogoModule() {
    switch (this.id) {
      case '58c72a9a-0c2e-4929-8602-c0260328ab4c':
        this.objModuleDto.banner2 = 'https://storage.googleapis.com/download/storage/v1/b/kuaacontentstreaming/o/kuaacontentstreaming%2Fmodule%2F65ASDAWER545MOl1b2%2F65ASDAWER545MOl1b2.png?generation=1672160139534117&alt=media'
        break
      case 'ec27f08e-7b08-4fd6-85d5-c2b6d576253f':
        this.objModuleDto.banner2 = 'https://storage.googleapis.com/download/storage/v1/b/kuaacontentstreaming/o/kuaacontentstreaming%2Fmodule%2F65ASDAWER545MOl2b2%2F65ASDAWER545MOl2b2.png?generation=1672160209909490&alt=media'
        break
      case 'edc79490-cae7-4cd1-85d6-aeeb10afb1e2':
        this.objModuleDto.banner2 = 'https://storage.googleapis.com/download/storage/v1/b/kuaacontentstreaming/o/kuaacontentstreaming%2Fmodule%2F65ASDAWER545MOl3b2%2F65ASDAWER545MOl3b2.png?generation=1672160256597345&alt=media'
        break
      case 'f45b139b-f77d-42a3-9b21-6d697d66fb50':
        this.objModuleDto.banner2 = 'https://storage.googleapis.com/download/storage/v1/b/kuaacontentstreaming/o/kuaacontentstreaming%2Fmodule%2F65ASDAWER545MOl4b2%2F65ASDAWER545MOl4b2.png?generation=1672160292235279&alt=media'
        break
    }
  }

  public getFellowsTraveler() {
    const url = 'https://storage.googleapis.com/publickuaa/static/img/modules/'
    if (this.id == 'ec27f08e-7b08-4fd6-85d5-c2b6d576253f') {//Alimentación
      this.instructor = this.sanitized.bypassSecurityTrustHtml('<b>María Mónica</b> De La Cuesta')
      this.fellowsTraveler.push({
        src: `${url}Alimentacion/INSTRUCTOR-NIVEL-4.jpg`,
        title: 'Batidos ¡a la carta!',
        text: 'Crea hábitos saludables y mejora la relación que tienes con los alimentos. ¡Nada te hará tanto bien!',
        btn: '¡IR A LA EXPERIENCIA!',
        link: `/home/preview/baf168db-86dd-4ef4-8709-11dee63a04f6/${this.id}`
      })
    } else if (this.id == 'edc79490-cae7-4cd1-85d6-aeeb10afb1e2') {//Mente y cuerpo
      this.instructor = this.sanitized.bypassSecurityTrustHtml('<b>Carlos</b> Zuluaga')
      this.fellowsTraveler.push({
        src: `${url}MenteCuerpo/INSTRUCTOR-NIVEL-4.jpg`,
        title: 'Running... ¡de 0 a 100!',
        text: 'Sé responsable con tu cuerpo porque es tu templo y él te llevará a culminar todas las metas y retos que te propongas si lo haces con responsabilidad y respetando el debido proceso. Correr no es solo correr y triunfar dependerá de muchos factores.',
        btn: '¡IR A LA EXPERIENCIA!',
        link: `/home/preview/b8b88c98-dece-4cdc-84b3-5268de85090f/${this.id}`
      })
    } else if (this.id == '58c72a9a-0c2e-4929-8602-c0260328ab4c') {//Artes y oficios
      this.instructor = this.sanitized.bypassSecurityTrustHtml('<b>María José</b> Ramírez')
      this.fellowsTraveler.push({
        src: `${url}ArteOficio/INSTRUCTOR-NIVEL-4.jpg`,
        title: '¡Manos al barro!',
        text: 'Concentrarnos en lo que hacemos con nuestras manos nos aleja de los pensamientos que nos perturban. Nos volvemos creadores de nuestro propio universo, liberamos la mente. Hacer cerámica es un ejercicio permanente para fluir, sanar, soltar y perdonar.',
        btn: '¡IR A LA EXPERIENCIA!',
        link: `/home/preview/bbee744b-b4ad-4caa-8055-4cfe08e6cd1f/${this.id}`
      })
    } else {//Habitad
      this.instructor = this.sanitized.bypassSecurityTrustHtml('<b>Luisa</b> Vergara')
      this.fellowsTraveler.push({
        src: `${url}Habitats/INSTRUCTOR-NIVEL-4.jpg`,
        title: 'Crea viviendas sostenibles',
        text: 'Existe un valor social y personal a rescatar y es la posibilidad del trabajo colectivo que permite la manipulación de materiales naturales para la construcción de un espacio configurado a partir de técnicas de bioconstrucción',
        btn: '¡IR A LA EXPERIENCIA!',
        link: `/home/preview/6744e1e1-abd0-4212-82e4-7b69c52486ab/${this.id}`
      })
    }
  }

  async loadSerieDefault(): Promise<void> {
    this.slides = []
    this.slidesInstructors = []
    const data = await this.objSerieService.findAll().toPromise()
    let coursesList: any[] = []
    data.forEach((element, index) => {
      if (element.autores.length > 0) {
        element.autores.forEach(async (idInstructor) => {
          const infoInstructor = await this.instructorService.findById(idInstructor).toPromise()
          infoInstructor.entityTransfer.forEach(dataInstructor => {
            if (dataInstructor.moduleCode.includes(this.id)) {
              if (!coursesList.includes(element.id)) {
                coursesList.push(element.id)
                this.slides.push({
                  title: element.title,
                  imgSrc: element.banner1,
                  path: ['/home/preview', element.id, '0']
                })
              }
              this.slidesInstructors.push(
                {
                  title: `${infoInstructor.entityTransfer[0].firstName} ${infoInstructor.entityTransfer[0].lastName} `,
                  imgSrc: infoInstructor.entityTransfer[0].banner1,
                  path: ['/home/instructor', infoInstructor.entityTransfer[0].id, '0']
                }
              )
            }
          })
        })
      }
    })
  }

  loadCategory() {
    //const id = this.activatedRoute.snapshot.params.id;
    const sToken = this.tokenService.getToken();

    this.objSerieService.findById(this.id).subscribe(
      data => {

      },
      err => {
        console.error(err);
      }
    );

    this.objCategoryService.moduleFindById(this.id, sToken).subscribe(
      data => {
        this.objKuaaTransactionGenericDto = data;
        if (this.objKuaaTransactionGenericDto.code == '00000') {
          this.entityTransfer = [];
          this.entityTransfer = this.objKuaaTransactionGenericDto.entityTransfer;
          this.objModuleDto = this.entityTransfer[0];
          this.carouselData.push({
            id: 0,
            title: {
              first: this.objCategoryDto.title,
              second: this.objCategoryDto.subtitle
            },
            subtitle: this.objCategoryDto.subtitle,
            link: '/',
            image: this.objCategoryDto.banner2
          },
            {
              id: 1,
              title: {
                first: this.objCategoryDto.title + " el 2",
                second: this.objCategoryDto.subtitle
              },
              subtitle: this.objCategoryDto.subtitle,
              link: '/',
              image: this.objCategoryDto.banner2
            }
          )
        }
      }
    );
  }

  public playVideos(videoBaseCode = '') {
    if (videoBaseCode != null) {
      this.playVideosService.playVideos(this.id, videoBaseCode, 'isModule=yes')
    }
  }
}
