import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from '../../../service/authorization/token.service';
import { PodcastService } from '../../../service/podcast/podcast.service';
import{ PodcastDto } from '../../../model/dto/podcast/podcast-dto';
import { KuaaTransactionGenericDto  } from '../../../common/trx/kuaa-transaction-generic-dto';
import { Track } from 'ngx-audio-player';   


@Component({
  selector: 'app-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.css','./podcast.audio.css']
})
export class PodcastComponent implements OnInit {

  objKuaaTransactionGenericDto: KuaaTransactionGenericDto = new KuaaTransactionGenericDto();
  entityTransfer: PodcastDto[] =[];
  objPodcastDto: PodcastDto = new PodcastDto();

  msaapDisplayTitle = true;
  msaapDisplayPlayList = false;
  msaapPageSizeOptions = [2,4,6]; 
  msaapDisplayVolumeControls = true;
  msaapDisplayRepeatControls = true;
  msaapDisplayArtist = true;
  msaapDisplayDuration = true;
  msaapDisablePositionSlider = false;
  msaapPlaylist: Track[] = [];
  urlPodcast:any;

  constructor( private activatedRoute: ActivatedRoute
               ,private objPodcastService: PodcastService
               , private tokenService:TokenService
                ) { 

               }

  ngOnInit(): void {
    this.loadPodcast();
  }

  loadPodcast(){
    const id = this.activatedRoute.snapshot.params.id; 
    const sToken = this.tokenService.getToken();
    this.objPodcastService.getById(id, sToken).subscribe(
      data =>{                
        this.objKuaaTransactionGenericDto = data;
            if (this.objKuaaTransactionGenericDto.code == '00000') {
                 this.entityTransfer = [];
                 this.entityTransfer = this.objKuaaTransactionGenericDto.entityTransfer;
                 this.objPodcastDto =  new PodcastDto();
                 this.objPodcastDto = this.entityTransfer[0];
                 this.msaapPlaylist.push(
                  {
                    title: this.objPodcastDto.title,
                    link: this.objPodcastDto.banner3,
                    artist: this.objPodcastDto.instructorCode,
                    //duration: 24
                  }
                 );
                 setTimeout(() => {
                  this.urlPodcast = this.objPodcastDto.banner3;
                }, 1000);
                 
            }  
      }
    );
  }

  onEnded(event){

  }

}
