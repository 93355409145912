import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { ArticleDto } from '../../model/dto/article/article-dto';
import { Helperutil } from '../../common/helper/helperutil';
import { __param } from 'tslib';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { TokenService } from '../authorization/token.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private httpClient: HttpClient
            ,private sanitizer: DomSanitizer
            ,private objTokenService: TokenService,
            ) { }

  private getTokenSesion(sToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return httpOptions;
  }

  public getByUserCode(id: string, sToken: string): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken); 
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_ARTICLE_GET_BY_USER_CODE + `${id}`, httpOptions );
  }


  public getById(id: string, sToken: string): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken); 
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_ARTICLE_FIND_BY_ID + `${id}`, httpOptions );
  }

  public findByModuleId(id: string, sToken: string): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken); 
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_ARTICLE__FIND_BY_MODULE_ID + `${id}`, httpOptions );
  }


}
