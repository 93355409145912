<div class="div container_fluid modules pb-5">
    <h1 class="section_title text-center pt-5 mt-0 mb-0" style="">Contenido en mi lista</h1>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="section_title">Series</h1>
            </div>
            <div class="col-4" *ngFor="let serie of series">
                <div class="card card-myList" style="width: 13rem; background-color: black;">
                    <img src="{{serie.banner}}" class="card-img-top" alt="...">
                    <div class="card-body text-center" style="color: white; ">
                        <h5 class="card-title">{{serie.title}}</h5>
                        <h6 class="card-title">{{serie.subtitle}}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="section_title">Podcasts</h1>
            </div>
            <div class="col-4" *ngFor="let podcast of podcasts">
                <div class="card card-myList" style="width: 13rem; background-color: black;">
                    <img src="{{podcast.banner}}" class="card-img-top" alt="...">
                    <div class="card-body text-center" style="color: white; ">
                        <h5 class="card-title">{{podcast.title}}</h5>
                        <h6 class="card-title">{{podcast.subtitle}}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>