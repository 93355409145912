<footer style="background-color: black;" class="pt-5" >
    <div class="container ps-5 pe-5 div_footer">
        <div class="row d-flex justify-content-center align-items-start mb-5">
            <div class="col-xl-2 col-lg-2 col-md-none col-sm-none d-none d-lg-block height-footer">
                <div class="d-flex flex-column align-items-center justify-content-center text-center height-footer">
                    <img src="/assets/logos/MARCA-KUAÁ-tag-line.png" class="desktop logo_width img-fluid"
                        style="width: 10vw;" />
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 legal height-footer mb-3">
                <div class="d-flex flex-column text-center ">
                    <p class="footer-text text-center"> <b>Legal</b> </p>
                    <p class="footer-text"> <a href="https://www.kuaa.co/politica-de-privacidad/">Política de
                            privacidad</a>
                    </p>
                    <p class="footer-text"> <a href="https://www.kuaa.co/terminos-y-condiciones/">Términos y
                            condiciones</a>
                    </p>
                    <p class="footer-text"> <a
                            href="https://www.kuaa.co/autorizacion-aviso-de-datos-personales/">Autorización /
                            Aviso de Datos Personales</a> </p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 social height-footer mb-3">
                <div class="row text-center">
                    <div class="col-12">
                        <p class="footer-text"> <b>Social</b> </p>
                    </div>
                    <div class="col-12">
                        <div class="d-flex justify-content-center">
                            <div class="m-1">
                                <a href="https://www.instagram.com/kuaa.co/" target="_blank"
                                    class="d-flex align-items-center justify-content-center link-social-media-icons"> <i
                                        class="fa fa-instagram social-media-icon" aria-hidden="true"></i> </a>
                            </div>
                            <div class="m-1">
                                <a href="https://www.facebook.com/KUA%C3%81-102860585333091" target="_blank"
                                    class="d-flex align-items-center justify-content-center link-social-media-icons"> <i
                                        class="fa fa-facebook-f social-media-icon" aria-hidden="true"></i> </a>
                            </div>
                            <div class="m-1">
                                <a href="https://www.linkedin.com/company/78077749/admin/" target="_blank"
                                    class="d-flex align-items-center justify-content-center link-social-media-icons"> <i
                                        class="fa fa-linkedin social-media-icon" aria-hidden="true"></i> </a>
                            </div>
                            <div class="m-1">
                                <a href="https://www.youtube.com/channel/UCrZg7gNRLPaLgoBPwOMmHMQ" target="_blank"
                                    class="d-flex align-items-center justify-content-center link-social-media-icons"> <i
                                        class="fa fa-youtube social-media-icon" aria-hidden="true"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 text-center">
                <p class="footer-text text-center"> <b>¿Necesitas ayuda?</b> </p>
                <p class="footer-text text-center"><a href="mailto:ayuda@kuaa.com.co">ayuda@kuaa.com.co</a></p>
            </div>
        </div>
        <div class="row all-rights-reserved">
            <div class="col">
                <p class="text-center">Kuaá® 2022 – Todos los derechos reservados</p>
            </div>
        </div>
    </div>
</footer>