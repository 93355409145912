import { Component, NgZone, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { CourseDto } from '../../../model/dto/course/course-dto'
import { TokenService } from '../../../service/authorization/token.service'
import { CourseService } from '../../../service/course/course.service'
import { AuthService } from '../../../shared/services/auth.service'

@Component({
  selector: 'app-homeheader',
  templateUrl: './homeheader.component.html',
  styleUrls: ['./homeheader.component.css']
})
export class HomeheaderComponent implements OnInit {


  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    private objCourseService: CourseService,
    private tokenService:TokenService
  ) { }

  ngOnInit(): void {
  }

  onLogOut(): void {
    this.tokenService.logOut();
    window.location.reload();
    this.router.navigate(['/']);
  }

}
