<div class="row p-3 mt-3 d-flex align-items-center justify-content-end">
    <div class="col-12 col-sm-12 col-md-12 col-lg-4">
        <h1 class="sections_title" style="margin-bottom: 1vw !important;">¿Buscas una experiencia sonora?</h1>
        <!--<p class="sections_subtitle">Aprende, relájate y conoce nuevas formas de conectar con tu ser.</p>-->
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-8">
        <div class="main-video-div">
            <div class="row d-flex align-items-center justify-content-center">
                <div class="col-12 col-sm-12 col-md-12 col-lg-8 d-flex align-items-center justify-content-center">
                    <div class="video-div d-flex align-items-center justify-content-center">
                        <video preload="yes" class="video-play" controls width="100%" height="auto" id="videoKuaa" controlsList="nodownload">
                            <source src="https://storage.googleapis.com/kuaastreaming/content/Mente_y_cuerpo/MANTRAS/CLIP_PREVIEW.mp4#t=0.001" type="video/mp4">
                        </video>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-4 pb-3">
                    <div class="video-text d-flex flex-column align-items-center justify-content-start">
                        <img src="https://storage.googleapis.com/publickuaa/static/img/nivel5/TITULO-MANTRAS.png">
                        <p class="sections_subtitle" style="font-size: 0.9rem !important;" >Invoca protección, <br/> sabiduría y felicidad.</p>
                        <a href="/home/preview/25d4d175-07b2-4476-9f4b-11d64eb736cd/0" class="btn btn-more" >¡ESCUCHAR <b>MÁS!</b></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
