import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { TokenService } from '../../../service/authorization/token.service';
import { CategoryService } from '../../../service/category/category.service';
import { CategoryDto } from '../../../model/dto/category/category-dto';



@Component({
  selector: 'app-categorylist',
  templateUrl: './categorylist.component.html',
  styleUrls: ['./categorylist.component.css']
})
export class CategorylistComponent implements OnInit {

  objListCategories: CategoryDto[] = [];

  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    private objCategoryService: CategoryService,
    private tokenService:TokenService
  ) {
  }

  ngOnInit(): void {
    this.loadCoursesDefault();
  }


  loadCoursesDefault(): void{
    //const sToken = this.authService.userData.idToken;
    const sToken = this.tokenService.getToken();
    this.objCategoryService.getCategories( sToken ).subscribe( 
      data => {
          this.objListCategories = data
        },
        err => {
          console.error(err);
        }
    );
  }

}
