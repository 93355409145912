<app-publichead *ngIf="path == 'billing'" ></app-publichead>

<div class="container">
  <div class="row">
    <div class="col-12 mb-4">
      <h1 class="sections_title text-center">Facturación</h1>
    </div>
  </div>

  <subscription-status [userSubscription]="userSubscription" (newPayment)="refreshPayments()" (changeSubscription)="loadSubscriptionInfo()"></subscription-status>

  <div class="row">
    <div class="col-12 col-md-5 mb-4">
      <credit-cards></credit-cards>
    </div>
    <div class="col-12 col-md-7 mb-4">
      <payment-list [payments]="payments"></payment-list>
    </div>
  </div>

  <div class="row" *ngIf="userSubscription && userSubscription.autoRenewal">
    <div class="col-12 mb-4 text-center">
      <app-button variant="outline-secondary" (click)="cancelSubscription()">Cancelar Suscripción</app-button>
    </div>
  </div>
</div>

<app-publicfooter *ngIf="path == 'billing'" ></app-publicfooter>
