import { IvyCarouselModule } from 'angular-responsive-carousel'
import { NgxAudioPlayerModule } from 'ngx-audio-player'
import { EmbedVideo } from 'ngx-embed-video'
import { SlickCarouselModule } from 'ngx-slick-carousel'
import { ToastrModule } from 'ngx-toastr'

import { DragDropModule } from '@angular/cdk/drag-drop'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
// Firebase services + enviorment module
import { AngularFireModule } from '@angular/fire'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore'
import { AngularFireStorageModule } from '@angular/fire/storage'
import { FlexLayoutModule } from '@angular/flex-layout'
// import { SwiperModule } from "swiper/angular";
// Reactive Form
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
//import { MatToolbarModule, MatSidenavModule, MatListModule, MatButtonModule } from "@angular/material";
import { MatToolbarModule } from '@angular/material/toolbar'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { environment } from '../environments/environment'
// App components
import { AppComponent } from './app.component'
import { ArticleComponent } from './components/article/article/article.component'
import { CategoryaddComponent } from './components/category/categoryadd/categoryadd.component'
import { CategorylistComponent } from './components/category/categorylist/categorylist.component'
import {
    CategoryHomeComponent
} from './components/content/category/category-home/category-home.component'
import { PreviewComponent } from './components/content/preview/preview.component'
import {
    RelatedContentComponent
} from './components/content/related-content/related-content.component'
import { ViewComponent } from './components/content/view/view.component'
import { CourseAddComponent } from './components/course/course-add/course-add.component'
import { CourseListComponent } from './components/course/course-list/course-list.component'
import { DashboardComponent } from './components/dashboard/dashboard.component'
import { DefaultComponent } from './components/dashboard/default/default.component'
import { FooterComponent } from './components/dashboard/footer/footer.component'
import { HeaderComponent } from './components/dashboard/header/header.component'
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component'
import { HomeComponent } from './components/home/home/home.component'
import { HomedefaultComponent } from './components/home/homedefault/homedefault.component'
import { HomefooterComponent } from './components/home/homefooter/homefooter.component'
import { HomeheaderComponent } from './components/home/homeheader/homeheader.component'
import { FullnessComponent } from './components/hostel/fullness/fullness.component'
import { HostelComponent } from './components/hostel/hostel/hostel.component'
import { HosteldefaultComponent } from './components/hostel/hosteldefault/hosteldefault.component'
import { HostelfooterComponent } from './components/hostel/hostelfooter/hostelfooter.component'
import { HostelheaderComponent } from './components/hostel/hostelheader/hostelheader.component'
import { InstructorsComponent } from './components/hostel/instructors/instructors.component'
import { Level5Component } from './components/hostel/level5/level5.component'
import { Level6Component } from './components/hostel/level6/level6.component'
import { ModulesComponent } from './components/hostel/modules/modules.component'
import { MorePopularComponent } from './components/hostel/more-popular/more-popular.component'
import { SeriesComponent } from './components/hostel/series/series.component'
import {
    InstructorHomeComponent
} from './components/instructor/instructor-home/instructor-home.component'
import { LoaderComponent } from './components/loader/loader.component'
import { LoginFooterComponent } from './components/login/login-footer/login-footer.component'
import { LoginHeadComponent } from './components/login/login-head/login-head.component'
import { LoginComponent } from './components/login/login/login.component'
import {
    ChangePasswordComponent
} from './components/login/public/change-password/change-password.component'
import {
    LogindefaultComponent
} from './components/login/public/logindefault/logindefault.component'
import {
    PlansPaymentComponent
} from './components/login/public/plans-payment/plans-payment.component'
import {
    RecoverpasswordComponent
} from './components/login/public/recoverpassword/recoverpassword.component'
import { RegisterComponent } from './components/login/public/register/register.component'
import {
    CreateCreditCardsComponent
} from './components/payments/create-credit-cards/create-credit-cards.component'
import { PaymentModule } from './components/payments/payment.module'
import { PodcastComponent } from './components/podcast/podcast/podcast.component'
import { PodcastlistComponent } from './components/podcast/podcastlist/podcastlist.component'
import { PolicyhabeasdataComponent } from './components/policyhabeasdata/policyhabeasdata.component'
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component'
import { AccountComponent } from './components/profile/account/account.component'
import { ProfileHomeComponent } from './components/profile/profile-home/profile-home.component'
import { ShowPlansComponent } from './components/profile/show-plans/show-plans.component'
import { PublicComponent } from './components/public/public.component'
import { SearchComponent } from './components/search/search.component'
import { SerieVideosComponent } from './components/series/serie-videos/serie-videos.component'
import { CarouselComponent } from './components/shared/carousel/carousel.component'
import { SignInComponent } from './components/sign-in/sign-in.component'
import { SignUpComponent } from './components/sign-up/sign-up.component'
import {
    SubscriberHomeComponent
} from './components/subscriber/subscriber-home/subscriber-home.component'
import { VerifyEmailComponent } from './components/verify-email/verify-email.component'
import { AuthenticationInterceptor } from './interceptors/authentication.interceptor'
// App routing modules
import { AppRoutingModule } from './shared/routing/app-routing.module'
//import {NgxTinySliderModule} from 'ngx-tiny-slider';
// Auth service
import { AuthService } from './shared/services/auth.service'
import { SharedModule } from './shared/shared.module'

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    HomeComponent,
    CourseAddComponent,
    CourseListComponent,
    HeaderComponent,
    FooterComponent,
    DefaultComponent,
    PreviewComponent,
    ViewComponent,
    HomeheaderComponent,
    HomefooterComponent,
    HomedefaultComponent,
    CategoryaddComponent,
    CategorylistComponent,
    CategoryHomeComponent,
    CarouselComponent,
    SubscriberHomeComponent,
    LoginComponent,
    LoginHeadComponent,
    LoginFooterComponent,
    HostelheaderComponent,
    HostelfooterComponent,
    HosteldefaultComponent,
    HostelComponent,
    InstructorHomeComponent,
    ProfileHomeComponent,
    PodcastlistComponent,
    PodcastComponent,
    ArticleComponent,
    RegisterComponent,
    RecoverpasswordComponent,
    LogindefaultComponent, PublicComponent, PrivacypolicyComponent, PolicyhabeasdataComponent, ModulesComponent, MorePopularComponent, SeriesComponent, InstructorsComponent, RelatedContentComponent, SearchComponent, PlansPaymentComponent, FullnessComponent, Level5Component, Level6Component, ChangePasswordComponent, CreateCreditCardsComponent, LoaderComponent, AccountComponent, ShowPlansComponent,
    SerieVideosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserAnimationsModule,
    ToastrModule.forRoot( ),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    DragDropModule,
    MatCardModule,
    MatGridListModule,
    MatTabsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatRadioModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    SlickCarouselModule,
    IvyCarouselModule,
    EmbedVideo.forRoot(),
    NgxAudioPlayerModule,
    PaymentModule,
    SharedModule
    // SwiperModule
    //NgxTinySliderModule
  ],
  providers: [AuthService, AngularFireStorageModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})

export class AppModule { }
