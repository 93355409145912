import { Component, OnInit } from '@angular/core';
import { PodcastService } from '../../../service/podcast/podcast.service'
import { TokenService } from '../../../service/authorization/token.service';

@Component({
  selector: 'app-podcastlist',
  templateUrl: './podcastlist.component.html',
  styleUrls: ['./podcastlist.component.css']
})
export class PodcastlistComponent implements OnInit {

  public token = this.tokenService.getToken()
  public allPodcast = []

  constructor(
    private podcastService: PodcastService,
    private tokenService: TokenService
  ) { }

  ngOnInit(): void {
    this.getAllPostcast ()
  }

  public getAllPostcast (){
    this.podcastService.getPodcast(this.token).subscribe(
      data => {
        data.forEach(element => {
          if (element.estado === 'Público'){
            element.description = `${element.description.substr(0,150)} ...`
            this.allPodcast.push(element)
          }
        });
      },
      err => {
        console.error(err);
      }
    );
  }

}
