import { Pipe, PipeTransform } from '@angular/core'

import DateTime from '../utils/datetime'

@Pipe({
  name: 'dateFromSeconds'
})
export class DateFromSecondsPipe implements PipeTransform {

  transform(seconds: number, ...args: unknown[]): string {
    return DateTime.fromSeconds(seconds).toFormat(args[0] ? args[0] : 'LLLL dd, yyyy');
  }

}
