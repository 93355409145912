import { Observable } from 'rxjs'

import { Injectable } from '@angular/core'
import {
    ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot
} from '@angular/router'

import { PaymentsService } from '../../service/payments/payments.service'
import { UserService } from '../../service/payments/user.service'

@Injectable({
  providedIn: 'root'
})
export class PaymentGuard implements CanActivate {

  constructor(
    public userService: UserService,
    public paymentsService: PaymentsService,
    public router: Router,
    public routeParams: ActivatedRoute,
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>((observer) => {
      this.userService.info().subscribe(
        result => {
          if (result['subscriptionExpiration'] !== undefined) {
            const subscriptionExpiration = new Date(result['subscriptionExpiration']['_seconds'] * 1000)
            const currentDate = new Date()
            if (currentDate < subscriptionExpiration) {
              observer.next(true)
            } else {
              this.router.navigate(['/billing'])
            }
          } else {
            this.router.navigate(['/login/plans-payment'])
          }
        },
        error => {
          observer.next(false)
          console.error('Error al revisar el pago del usuario --> ', error)
          this.router.navigate(['/login'])
        }
      )
    })
  }
}
