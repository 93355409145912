import { Component, OnInit } from '@angular/core'
import CreditCard from 'src/app/shared/types/credit-card'
import { CreditCardsService } from '../../../service/payments/credit-cards.service'

@Component({
  selector: 'show-credit-card-active',
  templateUrl: './show-credit-card-active.component.html',
  styleUrls: ['./show-credit-card-active.component.css']
})
export class ShowCreditCardActiveComponent implements OnInit {

  creditCards: CreditCard[] = []

  constructor(
    private creditCardsService: CreditCardsService
  ) { }

  ngOnInit(): void {
    this.getCreditCards()
  }

  public getCreditCards() {
    this.creditCardsService.getAll().subscribe(
      creditCards => {
        creditCards.forEach(element => {
          if(element.selected){
            this.creditCards.push(element)
          }
        })
      },
      error => {
        console.error("Error getting user's credit cards: ", error);
      },
      () => {
      }
    )
  }

}
