import SlideContent from 'src/app/shared/types/slide-content'

import { Component, OnInit } from '@angular/core'

import { SerieService } from '../../../service/serie/serie.service'

@Component({
  selector: 'app-series',
  templateUrl: './series.component.html',
  styleUrls: ['series.component.scss']
})
export class SeriesComponent implements OnInit {

  breakpoints = {
    320: {
      slidesPerView: 1
    },
    576: {
      slidesPerView: 2
    },
    768: {
      slidesPerView: 4
    },
    992: {
      slidesPerView: 5
    },
    1200: {
      slidesPerView: 6
    }
  }
  slides: SlideContent[]
  slidesOrdered: SlideContent[]

  constructor(
    private objSerieService: SerieService,
  ) {
    //this.loadSerieDefault()
    this.getAll()
  }

  ngOnInit(): void {
  }

  async loadSerieDefault(): Promise<void> {
    try {
      const data = await this.objSerieService.findAll().toPromise()
      this.slides = data.map((s) => ({
          title: s.title,
          imgSrc: s.banner1,
          path: ['/home/preview', s.id, '0']
        }))
    } catch (error) {
      console.error('Error getting series: ', error)
    }
  }

  public getAll(){
    this.objSerieService.getAll().subscribe(
      response => {
        this.slidesOrdered = response.map((s) => ({
          title: s.title,
          imgSrc: s.banner1,
          path: ['/home/preview', s.id, '0']
        }))
      }
    )
  }
}
