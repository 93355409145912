<div class="row">
  <div class="col-12 justify-content-start  ">
    <div>
      <h1 class="sections_title" style="margin-bottom: 1vw !important;">Los más vistos</h1>
      <!--<p class="sections_subtitle">Todos en Kuaa están hablando de estos contenidos.</p>-->
    </div>

  </div>
</div>

<!--<app-slider
  *ngIf="slides"
  [loop]="true"
  [navigation]="true"
  [slides]="slides"
  [spaceBetween]="30"
  [extraClasses]="'mt-5'"
  [extraImgClass]="'img-slide'"
>
</app-slider>-->

<app-slider
  *ngIf="slidesMoreSeen"
  [loop]="true"
  [navigation]="true"
  [slides]="slidesMoreSeen"
  [spaceBetween]="30"
  [extraClasses]="'mt-5'"
  [extraImgClass]="'img-slide'"
>
</app-slider>
