import { Component, NgZone, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { CategoryDto } from '../../../model/dto/category/category-dto'
import { CourseDto } from '../../../model/dto/course/course-dto'
import { TokenService } from '../../../service/authorization/token.service'
import { CategoryService } from '../../../service/category/category.service'
import { CourseService } from '../../../service/course/course.service'
import { AuthService } from '../../../shared/services/auth.service'
import { ICarouselItem } from '../../shared/carousel/Icarousel-item.metadata'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

   objListCourses: CourseDto[] = [];
   objListCategories: CategoryDto[] = [];
   objListCatAlimens: CategoryDto[] = [];
   objListCatArte: CategoryDto[] = [];
   objListCatMenteCuerpo: CategoryDto[] = [];
   public carouselData: ICarouselItem[] = [];


  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    private objCategoryService: CategoryService,
    private objCourseService: CourseService,
    private tokenService:TokenService
  ) { }

  ngOnInit(): void {
    this.loadCategory();
    this.loadCoursesDefault();
    this.loadCoursesByCatCAA();
    this.loadCoursesByCatCAB();
    this.loadCoursesByCatCAC();
  }

  loadCategory(): void{
    const sToken = this.tokenService.getToken();
    this.objCategoryService.getCategories( sToken ).subscribe(
      data => {
          this.objListCategories = data
          this.carouselData.push(  {
                                      id: 0,
                                      title: {
                                          first: this.objListCategories[3].title,
                                          second: this.objListCategories[3].title
                                      },
                                      subtitle: this.objListCategories[3].subtitle,
                                      link: '/',
                                      image: this.objListCategories[3].banner2
                                  },
                                  {
                                    id: 1,
                                    title: {
                                      first: this.objListCategories[2].title,
                                      second: this.objListCategories[2].title
                                      },
                                      subtitle: this.objListCategories[2].subtitle,
                                      link: '/',
                                      image: this.objListCategories[2].banner2
                                  }
                                  ,{
                                    id: 2,
                                    title: {
                                      first: this.objListCategories[1].title,
                                      second: this.objListCategories[1].title
                                      },
                                      subtitle: this.objListCategories[1].subtitle,
                                      link: '/',
                                      image: this.objListCategories[1].banner2
                                  },{
                                    id: 3,
                                    title: {
                                      first: this.objListCategories[0].title,
                                      second: this.objListCategories[0].title
                                      },
                                      subtitle: this.objListCategories[0].subtitle,
                                      link: '/',
                                      image: this.objListCategories[0].banner2
                                  }
                              )
        },
        err => {
          console.error(err);
        }
    );
  };

  loadCoursesByCatCAA(): void{
    const sToken = this.tokenService.getToken();
    this.objCourseService.getByCategory( sToken, 'f5557664-8332-45f8-90e1-7e9b3f371272' ).subscribe(
      data => {
          this.objListCatAlimens = data
        },
        err => {
          console.error(err);
        }
    );
  };

  loadCoursesByCatCAB(): void{
    const sToken = this.tokenService.getToken();
    this.objCourseService.getByCategory( sToken, '892ce5e7-8835-4cb9-88ee-c567fc3f1813' ).subscribe(
      data => {
          this.objListCatArte = data
        },
        err => {
          console.error(err);
        }
    );
  };


  loadCoursesByCatCAC(): void{
    const sToken = this.tokenService.getToken();
    this.objCourseService.getByCategory( sToken, '3b44bbb9-6fd5-45a2-996e-fdc7bfa830df' ).subscribe(
      data => {
          this.objListCatMenteCuerpo = data
        },
        err => {
          console.error(err);
        }
    );
  };

  loadCoursesDefault(): void{
    const sToken = this.tokenService.getToken();
    this.objCourseService.getCoursesDefault( sToken ).subscribe(
      data => {
          this.objListCourses = data
        },
        err => {
          console.error(err);
        }
    );
  };



  onLogOut(): void {
    this.tokenService.logOut();
    window.location.reload();
    this.router.navigate(['/']);
  }

}
