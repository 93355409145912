<!-- Slider main container -->
<div #slider [class]="'swiper ' + extraClasses">
  <!-- Additional required wrapper -->
  <div class="swiper-wrapper">
    <!-- Slides -->
    <div *ngFor="let slide of slides" class="swiper-slide">
      <a [routerLink]="slide.path">
        <img [src]="slide.imgSrc" [alt]="slide.title" [class]=" extraImgClass " />
      </a>
    </div>
  </div>

  <div *ngIf="pagination">
    <!-- If we need pagination -->
    <div class="swiper-pagination"></div>
  </div>

  <div *ngIf="navigation" class="swiper-navigation">
    <!-- If we need navigation buttons -->
    <div [ngClass]="isTherePrevSlide ? '' : 'disabled'" class="swiper-button-prev" (click)="prevSlide()"></div>
    <div [ngClass]="isThereNextSlide ? '' : 'disabled'" class="swiper-button-next" (click)="nextSlide()"></div>
  </div>

  <div *ngIf="scrollbar">
    <!-- If we need scrollbar -->
    <div class="swiper-scrollbar"></div>
  </div>
</div>
