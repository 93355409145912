import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-subscriber-home',
  templateUrl: './subscriber-home.component.html',
  styleUrls: ['./subscriber-home.component.css']
})
export class SubscriberHomeComponent implements OnInit {

  constructor(  public authService: AuthService,
    public router: Router,
    public ngZone: NgZone) { }

  ngOnInit(): void {
  }

}
