import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { PodcastDto } from '../../model/dto/podcast/podcast-dto';
import { Helperutil } from '../../common/helper/helperutil';
import { __param } from 'tslib';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { catchError, map, retry, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PodcastService {

  constructor(private httpClient: HttpClient,private sanitizer: DomSanitizer) { }

  private getTokenSesion(sToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return httpOptions;
  }


  public getByUserCode(id: string, sToken: string): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken); 
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_PODCAST_GET_BY_USER_CODE + `${id}`, httpOptions );
  }


  public getById(id: string, sToken: string): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken); 
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_PODCAST_FIND_BY_ID + `${id}`, httpOptions );
  }

  public findByModuleId(id: string, sToken: string): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken); 
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_PODCAST_FIND_BY_MODULE_ID + `${id}`, httpOptions );
  }

  public getPodcast(sToken): Observable<any> { 
    const httpOptions = this.getTokenSesion(sToken); 
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_PODCAST_LIST, httpOptions);
  }


  
  





}
