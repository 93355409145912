<div class="div container-fluid modules">
  <div class="btn-back">
    <a href="{{url}}"> <img id="back" src="/assets/back_icon.png"></a>
  </div>
  <div style="width: 98vw; position: relative; display: inline-block;
  text-align: center;" class="d-flex align-items-end">
    <img class="img-fluid" style="max-width: 98vw;" src="{{ objInstructorDto.banner2 }}" srcset="">
    <p class="instructor-first-name ps-5" style="position: absolute; left: 10px;">{{ objInstructorDto.firstName }}
      {{objInstructorDto.lastName}} </p>
    <p class="instructor-last-name ps-5" style="position: absolute; left: 10px;">{{ objInstructorDto.surname }}
      {{objInstructorDto.secondSurname}} </p>
  </div>


  <div class="div container-fluid main-font-family pt-5">
    <div class="container">
      <div class="row mb-1">
        <div class="col">
          <div class="row">
            <div class="col-12 sinopsis">
              <h1 class="sections_title"> {{ objInstructorDto.professionCode }} ({{countryCode}}) </h1>
            </div>
            <div class="col-12">
              <div style="text-align: justify;">
                <p *ngFor="let s of description"> {{s}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="div container-fluid main-font-family pt-5">
    <div class="container">
      <div *ngIf="socialMedia != 'No social media'" class="btn_seguir" (click)="goiting(socialMedia)"
        style="cursor: pointer;">
        <p>Seguir</p>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col ">
        <h2 class="sections_title">Contenidos que pueden interesarte</h2>
      </div>
    </div>
  </div>

  <div class="container mb-5">
    <div class="row mt-5" *ngFor="let chapter of seasonChapter; let i = index">
      <div class="col-12">
        <div class="card card-chapter">
          <div class="card-chapter__banner">
            <img [src]="chapter.banner2" alt="" />
            <a [routerLink]="['/home/series', this.module, 'videos', chapter.code]" class="btn btn-link card-chapter__play">
              <span class="material-icons play-video">play_circle_outline</span>
            </a>
          </div>
          <div class="card-body">
            <h3 class="card-title">{{chapter.title}}</h3>
            <p class="card-text">{{ chapter.type === 'Video' ? chapter.sinopsis : chapter.description }}</p>
            <span class="card-chapter__duration">{{chapter.durationContent}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>