import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from '../../service/authorization/token.service';

@Injectable({
  providedIn: 'root'
})
export class LoginActionsGuard implements CanActivate {

  constructor(
    public tokenService: TokenService,
    public router: Router
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if(this.tokenService.getToken() === null){
      this.router.navigate(['/login']);
    }


    return true;
  }
  
}
