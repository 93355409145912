import Swal from 'sweetalert2'

import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { CreditCardsService } from '../../../service/payments/credit-cards.service'

@Component({
  selector: 'credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.css']
})
export class CreditCardFormComponent implements OnInit {
  formCard: FormGroup;
  months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  years = [];
  saving = false;
  @Output() create = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private creditCardsService: CreditCardsService
  ) {
    this.formCard = this.formBuilder.group({
      documentType: ['', Validators.required],
      dniNumber: ['', Validators.required],
      number: ['', Validators.required],
      securityCode: ['', Validators.required],
      name: ['', Validators.required],
      expirationMonth: ['', Validators.required],
      expirationYear: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.createArrayYears()
  }

  private createArrayYears() {
    const cy = new Date().getFullYear()
    for (let i = cy; i <= cy + 20; i++) {
      this.years.push(i)
    }
  }

  save(event) {
    const form = event.target as unknown as HTMLFormElement;
    if (form) {
      form.classList.add('was-validated');
    }
    if (this.formCard.valid) {
      this.saving = true;

      const payload = {
        ...this.formCard.value,
        expirationDate: `${this.formCard.value.expirationYear}/${this.formCard.value.expirationMonth}`
      };
      delete payload.expirationMonth;
      delete payload.expirationYear;

      this.creditCardsService.create( payload )
        .subscribe((newCreditCard) => {

          Swal.fire({
            title: '¡Tarjeta guardada!',
            text: 'Tu tarjeta ha sido creada correctamente',
            icon: 'success',
            confirmButtonText: 'OK'
          })


          //Swal.fire('¡Tarjeta Guardada!', '', 'success');
          /*etTimeout(() => {
            Swal.close();
          }, 2000);*/
          this.create.emit(newCreditCard);
        }, (error: { error: { error?: string, description: string } }) => {
          Swal.fire(error.error.error || 'Error', error.error.description, 'error');
        },
        () => {
          this.saving = false;
        });
    }
  }
}
