<head>
    <meta charset="utf-8">
    <title>Kuaa</title>
    <base href="/">
  
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="icon" type="image/x-icon" href="favicon.ico">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap" rel="stylesheet">
  </head>

<div style="height: 80vh;">
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
          <mat-icon>menu</mat-icon>
        </button>
        <div>
            <img _ngcontent-cpw-c146="" 
            src="../../../../assets/logos/MARCA-KUAÁ-tag-line.png" alt="forecastr logo" 
            class="logotipo horizontal-logo" />
            <!--
            <p  class="logoSubText" >Find your balance, <strong> enjoy life</strong> </p>            
            -->
        </div>
        <span class="menu-spacer"></span>
        <div fxShow="true" fxHide.lt-md class="loginHeadMenuitemOpcion" >
          <!-- The following menu items will be hidden on both SM and XS screen sizes -->
          
          <a href="#" mat-button >
            <div class="menuItemLogin" > 
               <span>
                    SUSCRIBIRSE
               </span>  
             </div>
          </a>
          <a href="#" mat-button >
            <div class="menuItemLoginRecovery" > 
               <span>
                OLVIDE MI CONTRASEÑA
               </span>  
             </div>
          </a>
          <a href="#" mat-button >
            <div class="menuItemLoginSelect" > 
               <span>
                INICIAR SESSION
               </span>  
             </div>
          </a>
          <!--
          <a href="#" mat-button>Olvide contraseña</a>
          <a href="#" mat-button>Inicar sesion </a>
          -->
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  
    <mat-sidenav-container fxFlexFill>
      <mat-sidenav #sidenav>
        <mat-nav-list>
          <a (click)="sidenav.toggle()" href="" mat-list-item>
            <img _ngcontent-olg-c88="" src="https://storage.googleapis.com/publickuaa/publickuaa/assets/img/icon.png" alt="forecastr logo" class="logo vertical-logo">
          </a>
          <a href="/login/signup" mat-list-item>Suscribirse</a>
          <a href="/login/forgot-password" mat-list-item>Olvide mi contraseña</a>
          <a href="/" mat-list-item>Iniciar sesion</a>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content fxFlexFill>
        <div>
            <app-login *ngIf="isPathLogin" ></app-login>
            <app-sign-up *ngIf="isPathSignUp" ></app-sign-up>
            <!--
              <app-verify-email *ngIf="isPathForgotPswd" ></app-verify-email>
            -->            
            <app-forgot-password *ngIf="isPathForgotPswd" ></app-forgot-password>
        </div>
        </mat-sidenav-content>
    </mat-sidenav-container>

    <app-login-footer></app-login-footer>

  </div>
  