import Swiper from 'swiper'

import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core'

import SlideContent from '../../types/slide-content'

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnDestroy {
  @Input() breakpoints: {} = {
    320: {
      slidesPerView: 1
    },
    576: {
      slidesPerView: 2
    },
    768: {
      slidesPerView: 3
    },
    992: {
      slidesPerView: 4
    },
    1200: {
      slidesPerView: 5
    }
  }
  @Input() direction: 'horizontal' | 'vertical' = 'horizontal'
  @Input() extraClasses: string = ''
  @Input() extraImgClass: string = ''
  @Input() loop: boolean = false
  @Input() navigation: boolean = false
  @Input() pagination: boolean = false
  @Input() scrollbar: boolean = false
  @Input() slides: SlideContent[] = []
  @Input() spaceBetween: number = 30
  @ViewChild('slider', { read: ElementRef }) sliderRef: ElementRef

  private slider: Swiper
  isTherePrevSlide = true
  isThereNextSlide = true

  constructor() { }

  ngAfterViewInit() {
    if (this.sliderRef && this.sliderRef.nativeElement) {
      this.initSlider()
    }
  }

  ngOnDestroy(): void {
    if (this.slider && this.slider.destroy) {
      this.slider.destroy()
    }
  }

  initSlider() {
    if (!this.slider && this.sliderRef.nativeElement) {
      const settings = {
        spaceBetween: this.spaceBetween,
        direction: this.direction,
        centeredSlides: false,
        loop: this.loop,
        breakpoints: this.breakpoints,
        observer: true,
        observeParents: true,
      }
      this.slider = new Swiper(this.sliderRef.nativeElement, settings)
    }
  }

  nextSlide() {
    if (this.slider) {
      this.slider.slideNext()
      this.updateNavigation()
    }
  }

  prevSlide() {
    if (this.slider) {
      this.slider.slidePrev()
      this.updateNavigation()
    }
  }

  updateNavigation() {
    this.isThereNextSlide = this.slider.activeIndex < (this.slider.slides.length - 1)
    this.isTherePrevSlide = this.slider.activeIndex > 0
  }
}
