<form class="row needs-validation" novalidate [formGroup]="formCard" (ngSubmit)="save($event)">
  <div class="col-12 col-md-6 mb-3">
    <label for="documentType" class="form-label">Tipo de Documento</label>
    <select class="form-select" id="documentType" formControlName="documentType" required>
      <option></option>
      <option>C.C.</option>
    </select>
  </div>

  <div class="col-12 col-md-6 mb-3">
    <label for="dniNumber" class="form-label">Número de Identificación</label>
    <input type="text" class="form-control" id="dniNumber" formControlName="dniNumber" required />
  </div>

  <div class="col-12 col-md-8 mb-3">
    <label for="number" class="form-label">Número de la Tarjeta</label>
    <input type="text" class="form-control" id="number" formControlName="number" aria-label="Número de la tarjeta de crédito" minlength="12" maxlength="20" required />
  </div>

  <div class="col-12 col-md-4 mb-3">
    <label for="securityCode" class="form-label">CVV</label>
    <input type="text" class="form-control" id="securityCode" formControlName="securityCode" aria-label="Código de seguridad de la tarjeta de crédito" minlength="3" maxlength="5" required />
  </div>

  <div class="col-12 mb-3">
    <label for="name" class="form-label">Nombre en la Tarjeta</label>
    <input type="text" class="form-control" id="name" formControlName="name" required />
  </div>

  <div class="col-md-6 mb-3">
    <label for="expirationMonth" class="form-label">Mes de Vencimiento</label>
    <select class="form-select" id="expirationMonth" formControlName="expirationMonth" required>
      <option></option>
      <option *ngFor="let month of months">{{month}}</option>
    </select>
  </div>

  <div class="col-md-6 mb-3">
    <label for="expirationYear" class="form-label">Año de Vencimiento</label>
    <select class="form-select" id="expirationYear" formControlName="expirationYear" required>
      <option></option>
      <option *ngFor="let year of years">{{year}}</option>
    </select>
  </div>

  <div class="d-flex justify-content-end">
    <app-button type="submit" variant="primary" loadingLabel="Guardando" [loading]="saving">Guardar</app-button>
  </div>
</form>
