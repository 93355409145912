import { CourseDto } from 'src/app/model/dto/course/course-dto'
import { SerieDto } from 'src/app/model/dto/serie/serie-dto'
import { CourseService } from 'src/app/service/course/course.service'
import { SerieService } from 'src/app/service/serie/serie.service'

import { Component, OnInit } from '@angular/core'
import { SafeUrl } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-serie-videos',
  templateUrl: './serie-videos.component.html',
  styleUrls: ['./serie-videos.component.css']
})
export class SerieVideosComponent implements OnInit {
  course: CourseDto
  currentCourseId: string
  currentCourseIndex = 0
  currentCourseUrl: string | SafeUrl
  currentTitle: string = ''
  numCourses = 0
  serie: SerieDto
  serieId: string
  enablePrev = false
  enableNext = false
  backUrl: any[] = ['#']

  constructor(
    private routeParams: ActivatedRoute,
    private courseService: CourseService,
    private serieService: SerieService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.serieId = this.routeParams.snapshot.paramMap.get('serieId')
    this.currentCourseId = this.routeParams.snapshot.paramMap.get('id')
    this.backUrl = ['/home/preview', this.serieId, '0']
    this.loadSerie()
  }

  back() {
    this.router.navigate(this.backUrl)
  }

  loadSerie() {
    this.serieService.findById(this.serieId)
      .subscribe(
        (response) => {
          this.serie = response.entityTransfer[0]
          this.numCourses = this.serie.objListSerieContent.length
          this.selectAndLoadCourse()
        },
        (error) => {
          console.error('Error getting serie info: ', error)
          this.back()
        }
      )
  }

  selectAndLoadCourse() {
    if (this.serie && Array.isArray(this.serie.objListSerieContent) && this.serie.objListSerieContent.length) {
      this.currentCourseIndex = this.serie.objListSerieContent.findIndex((course) => course.contentId === this.currentCourseId)
      if (this.currentCourseIndex < 0) {
        this.currentCourseIndex = 0
      }
      this.loadCourse(this.serie.objListSerieContent[this.currentCourseIndex].contentId)
    } else {
      this.back()
    }
  }

  loadCourse(courseId: string) {
    this.courseService.findById(courseId)
      .subscribe(
        (response) => {
          this.course = response.entityTransfer[0]
          if (this.course && this.course.videoBaseCode) {
            this.loadVideo()
          }
        },
        (error) => {
          console.error('Error getting course info: ', error)
          this.back()
        }
      )
  }

  loadVideo() {
    this.currentTitle = `EP. ${(this.currentCourseIndex + 1)} - ${this.course.title}`
    this.currentCourseUrl = this.course.videoBaseCode.replace('https://storage.cloud.google.com', 'https://storage.googleapis.com')
    this.enablePrev = this.currentCourseIndex > 0
    this.enableNext = this.currentCourseIndex < (this.numCourses - 1)
  }

  playPrev() {
    if (this.currentCourseIndex > 0 && this.numCourses > 0) {
      const courseId = this.serie.objListSerieContent[--this.currentCourseIndex].contentId
      this.router.navigate(['/home/series', this.serieId, 'videos', courseId])
      this.loadCourse(courseId)
    }
  }

  playNext() {
    if (this.currentCourseIndex < (this.numCourses - 1)) {
      const courseId = this.serie.objListSerieContent[++this.currentCourseIndex].contentId
      this.router.navigate(['/home/series', this.serieId, 'videos', courseId])
      this.loadCourse(courseId)
    }
  }
}
