import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from "../../shared/services/auth.service";
import { TokenService } from '../../service/authorization/token.service';
import { devActive } from 'src/environments/environment';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.css']
})
export class PublicComponent implements OnInit {

  devActive = devActive;

  constructor(
    public authService: AuthService,
    public router: Router,
    private tokenService: TokenService,
  ) {
    const roles = this.tokenService.getAuthorities();
    if (this.tokenService.getToken() ) {
      this.router.navigate(['/home']);
    }else{
      this.router.navigate(['/']).then(result=>{window.location.href = 'https://www.kuaa.co/';});
      //window.location.href = 'https://www.kuaa.co/'
    }

   }

  ngOnInit(): void { }

  goToLogin(){
    this.router.navigate(['/login']);
  }

}
