<!-- Main content -->
<main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
    <div class="inner-adjust">

        <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">Crear Categoria: </h1>
        </div>
        <!-- Show user data when logged in -->
        <div class="row">
            <div class="col-md-12">
                <div class="media">
                    <div class="media-body">
                        <h1>+ <strong></strong></h1>
                        <form class="row g-3" novalidate #f="ngForm">
                            <div class="col-md-4">
                                <label for="inputNombre" class="form-label">Nombre:</label>
                                <input  type="text" class="form-control" id="inputNombre" name="inputNombre" [(ngModel)]="objCategoryDto.name">
                            </div>
                            <div class="col-md-4">
                                <label for="inputPassword4" class="form-label">Titulo</label>
                                <input type="text" class="form-control" id="inputTitulo" name="inputTitulo" [(ngModel)]="objCategoryDto.title">                                
                            </div>
                            <div class="col-md-4">
                                <label for="inputSubTitulo" class="form-label">Sub-Titulo</label>
                                <input type="text" class="form-control" id="inputSubTitulo" name="inputSubTitulo" [(ngModel)]="objCategoryDto.subtitle" placeholder="">
                            </div>
                            <div class="col-12">
                                <label for="inputDescription" class="form-label">Descripcion:</label>
                                <textarea type="text" class="form-control" id="inputDescription" name="inputDescription" [(ngModel)]="objCategoryDto.description" 
                                    placeholder="Descripcion del curso: ">
                                </textarea>
                            </div>

                            <div class="col-md-4">
                                <div class="mb-3">
                                    <label for="file" class="form-label">Banner home:</label>
                                    <input class="form-control" type="file" id="file"  name="file" (change)="onFileSelect($event,'b1')" >
                                  </div>
                            </div>
                            <div class="col-md-4">
                                <div class="mb-3">
                                    <label for="fileBanner2" class="form-label">Banner detail:</label>
                                    <input class="form-control" type="file" id="fileBanner2" name="fileBanner2" (change)="onFileSelect($event,'b2')">
                                  </div>
                            </div>
                            <div class="col-md-4">
                                <div class="mb-3">
                                    <label for="fileBanner4" class="form-label">Banner icon:</label>
                                    <input class="form-control" type="file" id="fileBanner4" name="fileBanner2" (change)="onFileSelect($event,'b3')" >
                                  </div>
                            </div>

                            <div class="col-md-2"></div>
                            <div class="col-md-4 d-grid gap-2 col-6 mx-auto">
                                <button  type="button" class="btn btn-primary btn-lg " (click)="save()" >
                                    <span *ngIf="!isSaveProcess" >Guardar</span> 
                                    <span  *ngIf="isSaveProcess" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span  *ngIf="isSaveProcess" > Guardando...</span> 
                                </button> 
                                <!--
                                <button mat-flat-button color="primary" (click)="uploadFile()">Cargar</button>
                            -->
                            </div> 
                        
                            <div class="col-md-4 d-grid gap-2 col-6 mx-auto">
                                <a href="/dashboard" class="btn btn-secondary btn-lg">Cancelar</a>
                            </div>
                            <div class="col-md-2"></div>
                        </form>

                    </div>
                </div>
            </div>
        </div>

    </div>
</main>