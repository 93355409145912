//import { Http, Headers, Response } from '@angular/http';
import { from, Observable, throwError } from 'rxjs'
import { catchError, map, retry, tap } from 'rxjs/operators'
import { KuaaResponseDto } from 'src/app/model/dto/kuaa-response/kuaa-response-dto'
import { __param } from 'tslib'

import {
    HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

import { Helperutil } from '../../common/helper/helperutil'
import { CourseDto } from '../../model/dto/course/course-dto'
import { TokenService } from '../authorization/token.service'

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor(private httpClient: HttpClient
    ,private sanitizer: DomSanitizer
    ,private objTokenService: TokenService
    ) { }

  private getHttpSettings() {
    const httpSettings = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return httpSettings;
  }

  public getCoursesDefault(sToken): Observable<any[]> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_API_V1_COURSE_LIST, httpSettings);
  }

  public getRelatedCourses(sToken, id): Observable<any[]> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.get<any[]>(`${Helperutil.S_CORE_SERVICE_API_V1_SERIE_FINDBYINSTRUCTOR}/${id}`, httpSettings);
  }

  public save(CourseDto: CourseDto, sToken): Observable<any> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.post<any>(Helperutil.S_CORE_SERVICE_API_V1_COURSE_SAVE, CourseDto, httpSettings);
  }

  public findById(id: string): Observable<KuaaResponseDto<CourseDto>> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.get<KuaaResponseDto<CourseDto>>(Helperutil.S_CORE_SERVICE_API_V1_COURSE_FINDBYID + `/${id}`, httpSettings );
  }

  public videoFindById(id: string, sToken: string): Observable<any> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_VIDEOBASE_FINDBYID + `/${id}`, httpSettings );
  }

  public getCompleteVideoBaseFindById(id: string ): Observable<any> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_COURSE__COMPLETE_VIDEOBASE_FINDBYID + `/${id}`, httpSettings );
  }

  downloadFile(uel: string): Observable<Blob> {
    const headers = new Headers();
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*');
    const httpSettings = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Methods':'GET',
        'Access-Control-Allow-Origin':'*',
      }),
      responseType: 'blob'
    };

    return this.httpClient.get(uel, { headers:  new HttpHeaders({
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods':'GET',
      'Access-Control-Allow-Origin':'*',
    }), responseType: 'blob' } );
}

  public getContentDownload(code: string, sToken: string)  {
    //const httpSettings = this.getHttpSettings(sToken);
    let url = Helperutil.S_CORE_SERVICE_API_V1_COURSE_Download + `/${code}`;
    //return this.httpClient.get<any>(url, httpSettings );
    //let options = new RequestOptions({responseType: ResponseContentType.Blob });

    const httpSettings = {
      headers: new HttpHeaders({
        /*
        'Content-Type': 'video/mp4',
        'Content-Transfer-Encoding': 'binary',
        'Accept-Ranges': 'bytes',
        'Connection':'keep-alive',
        */
        //'Accept-Ranges':'bytes',
        //'Keep-Alive': 'timeout=60',
        //'Content-Type': 'video/mp4',
        //'Content-Transfer-Encoding':'binary',
        //'Accept': 'video/mp4',
         //'transfer-encoding': 'chunked',
        //'redirect': 'follow',
        //'responseType':'blob',
        //'responseType': ResponseContentType.Blob,
        //'reportProgress': 'true',
        //'vary': 'Origin, Access-Control-Request-Method, Access-Control-Request-Headers',
        //'connection': 'keep-alive',
        Authorization: 'Bearer ' + sToken
      })
    };
    //return this.httpClient.get(url, httpSettings );
    return this.httpClient.get<any>(url, httpSettings ).pipe(
      retry(1), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code  `+error.status);
      console.error(`body was: `+ error.message );
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }



  public getImage(url: string): Observable<SafeResourceUrl> {
    return this.httpClient
      .get(url, { responseType: 'blob' })
      .pipe(
        map(x => {
          const urlToBlob = window.URL.createObjectURL(x) // get a URL for the blob
          return this.sanitizer.bypassSecurityTrustResourceUrl(urlToBlob); // tell Anuglar to trust this value
        }),
      );
  }


  public uploadFile(file, sToken, id): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const httpParams = new HttpParams();
    httpParams.append('file', file);
    //formData.append('file', this.selectedFile, this.selectedFile.name);
    const httpSettings = {
      headers: new HttpHeaders({
        //'Content-Type':  'multipart/form-data; boundary=<calculated when request is sent>',
        Authorization: 'Bearer ' + sToken
      })
    };
    const url = Helperutil.S_CORE_SERVICE_API_V1_COURSE_IMG_SAVE + id;
    /*
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    */
    return this.httpClient.post<any>(url, formData, httpSettings);
    //return this.httpClient.request(req);
    /*
    return this.httpClient.post<any>(url, formData, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + sToken
        }),
        reportProgress: true,
        responseType: 'json',
        params: httpParams
      } );
    */

  }

  public getByCategory(sToken, code: string): Observable<any[]> {
    const httpSettings = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_API_V1_COURSE_GETBYCATEGORY+ `/${code}`, httpSettings);
  }

  public getModuleList(sToken, code: string): Observable<any[]> {
    const httpSettings = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_API_V1_MODULE_LIST, httpSettings);
  }



  public getContent(sToken, code: string): Observable<any[]> {
    const httpSettings = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_API_V1_COURSE_GETCONTENT+ `/${code}`, httpSettings);
  }


  public getContentKuaa( code: string ): Observable<any[]> {
    const httpSettings = {
      headers: new HttpHeaders({})
    };
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_API_V1_COURSE_STREAMING+ `/${code}`, httpSettings);
  }

  public getCoursesBySerie(sToken, id): Observable<CourseDto[]> {
    const httpSettings = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return this.httpClient.get<CourseDto[]>(Helperutil.S_CORE_SERVICE_API_V1_SERIE_FINDBYMODULE + `/${id}`, httpSettings);
  }


}
