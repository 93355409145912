import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from "@angular/fire/auth";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css', '../register/bootstrap.css']
})
export class ChangePasswordComponent implements OnInit {

  public oobCode
  public formRegisterValid = false

  constructor(
    private routeParams: ActivatedRoute,
    private route: Router,
    public afAuth: AngularFireAuth
  ) {
  }

  ngOnInit(): void {
    this.oobCode = this.routeParams.snapshot.queryParamMap.get('oobCode')
  }

  formRegister = new FormGroup({
    newPassword: new FormControl('', [Validators.required]),
    confirmNewPassword: new FormControl('', [Validators.required])
  });

  public resetPassword() {

    let newPassword = <HTMLInputElement>document.getElementById('newPassword')
    let confirmNewPassword = <HTMLInputElement>document.getElementById('confirmNewPassword')
    if (newPassword.value.length == 0 || newPassword.value.length < 8 || newPassword.value !== confirmNewPassword.value) {
      if (newPassword.value.length == 0) {
        this.printMessages('Tienes campos vacíos', 'El campo de contraseña no puede estár vacío.')
      } else if (newPassword.value.length < 8) {
        this.printMessages('Error de longitud', 'La contraseña debe tener más de 8 caracteres.')
      }
      if (newPassword.value !== confirmNewPassword.value) {
        this.printMessages('Las contraseñas no coinciden', 'El campo de contraseña y confirmación de contraseña deben ser iguales.')
      }
    } else {
      this.afAuth.auth.confirmPasswordReset(this.oobCode, newPassword.value)
        .then((result) => {
          Swal.fire({
            title: 'Cambio exitoso',
            text: 'Se cambió correctamente la contraseña, por favor inicia sesión',
            icon: 'success',
            confirmButtonColor: '#14b8ec',
            background: 'black',
            color: '#14b8ec'
          }).then((res) => {
            this.route.navigate(['login']);
          })

        }).catch((error) => {
          this.printMessages('Error al cambiar la contraseña', 'Solicita de nuevo el cambio de contraseña.')
        })
    }


  }

  public printMessages(title, text) {
    Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      confirmButtonColor: '#14b8ec',
      background: 'black',
      color: '#14b8ec'
    })
  }

  public checkPassword() {
    let newPassword = <HTMLInputElement>document.getElementById('newPassword')
    let password = newPassword.value
    let lowerCase = new RegExp("(?=.*[a-z])")
    let upperCase = new RegExp("(?=.*[A-Z])")
    let number = new RegExp("(?=.*[0-9])")//!#$%&/()=?¡¿~></+
    let car = /[$@$!%*?&;.,!#$%&/()=?¡¿~></+]/
    let caracter = new RegExp(car)
    let length = password.length
    if (password.match(lowerCase) != null && password.match(upperCase) != null && password.match(number) != null && password.match(caracter) != null && length >= 9) {
      document.getElementById('errorMessage').style.display = 'none'
    } else {
      document.getElementById('errorMessage').style.display = 'block'
      this.changeColorMessage(password, 'lowerCase', lowerCase)
      this.changeColorMessage(password, 'upperCase', upperCase)
      this.changeColorMessage(password, 'number', number)
      this.changeColorMessage(password, 'caracter', caracter)
      this.changeColorMessage(password, 'length', length)
    }
  }

  public changeColorMessage(password, field, condition) {
    if (password.match(condition) != null) {
      document.getElementById(field).classList.remove('text-danger')
      document.getElementById(field).classList.add('text-success')
    } else {
      document.getElementById(field).classList.add('text-danger')
      document.getElementById(field).classList.remove('text-success')
    }
  }


  public passwordValidator() {
    let newPassword = <HTMLInputElement>document.getElementById('newPassword')
    let confirmNewPassword = <HTMLInputElement>document.getElementById('confirmNewPassword')
    if (newPassword.value != confirmNewPassword.value) {
      document.getElementById('match').style.display = 'block'
    } else {
      document.getElementById('match').style.display = 'none'
    }
  }

}
