<!-- Modal -->
<div class="modal fade" [id]="internalId" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="kuaaModalLabel" aria-hidden="true">
  <div [class]="'modal-dialog' + (verticalCentered ? '  modal-dialog-centered' : '') + (variant ? ' modal-' + variant : '')">
    <div class="modal-content">
      <div class="modal-header">
        <h1 *ngIf="title" class="modal-title fs-5" id="kuaaModalLabel">{{title}}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="handleBootstrapClose()"></button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
