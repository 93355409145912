
<div class="div container-fluid seccion_podcast py-5">
    <div class="container">
      <div class="row alinear_centro">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 justify-content-center alinear_centro">
          <div id="outer">
            <div class="videoWrapper">
              <video controls class="video" *ngIf="objCourseDto.fileContent" controls controlsList="nodownload" >
                  <source src="{{objCourseDto.fileContent}}" >
              </video>

              <div class="label_video_icon">
                  <!--
                    <img src="../../../../assets/icon_play_video.png" class="responsive_play_logo">
                    -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="div container-fluid seccion_temp" *ngIf=" objCourseDto.contentType=='serie'" >
    <div class="container">

      <div class="dropdown">
        <button id="btn-dropdown" type="button" class="btn btn-info">Temporadas <img id="btn-img"
            src="../../../../assets/flecha_abajo.png" alt="#"> </button>
        <div class="dropdown-content">
          <ul>
            <li><label for="#">Temporada 1</label> </li>
            <li><label for="#">Temporada 2</label> </li>
            <li><label for="#">Temporada 3</label> </li>
          </ul>
        </div>
      </div>
      <div class="capitulos py-5">
        <h1>Capítulos</h1>
      </div>
      <div class="scroll">
      <!--Cap 1-->
      <div class="container-caps">
        <div class="index">
          <label>1</label>
        </div>

        <div class="content-cap">
          <img id="imgcap" src="../../../../assets/img.jpg">
          <div class="videoplay_icon">
            <img id="imgplay" src="../../../../assets/icon_play_video.png" class="responsive_play_logo">
          </div>
        </div>

        <div class="info-cap">
          <div class="time-cap">
            <label>48 min</label>
          </div>
          <p id="pcap">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos assumenda asperiores dolore earum
            vel
            doloribus nobis sed ut, quo voluptate magnam nulla, nostrum iste, veritatis voluptates architecto
            rem aliquam. Autem!</p>
        </div>
      </div>

      <!--Cap 2-->
      <div class="container-caps">
        <div class="index">
          <label>2</label>
        </div>

        <div class="content-cap">
          <img id="imgcap" src="../../../../assets/img_article.png">
          <div class="videoplay_icon">
            <img id="imgplay" src="../../../../assets/icon_play_video.png" class="responsive_play_logo">
          </div>
        </div>

        <div class="info-cap">
          <div class="time-cap">
            <label>48 min</label>
          </div>
          <p id="pcap">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos assumenda asperiores dolore earum
            vel
            doloribus nobis sed ut, quo voluptate magnam nulla, nostrum iste, veritatis voluptates architecto
            rem aliquam. Autem!</p>
        </div>
      </div>

      <!--Cap 3-->
      <div class="container-caps">
        <div class="index">
          <label>3</label>
        </div>

        <div class="content-cap">
          <img id="imgcap" src="../../../../assets/category_3_bg.png">
          <div class="videoplay_icon">
            <img id="imgplay" src="../../../../assets/icon_play_video.png" class="responsive_play_logo">
          </div>
        </div>

        <div class="info-cap">
          <div class="time-cap">
            <label>48 min</label>
          </div>
          <p id="pcap">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos assumenda asperiores dolore earum
            vel
            doloribus nobis sed ut, quo voluptate magnam nulla, nostrum iste, veritatis voluptates architecto
            rem aliquam. Autem!</p>
        </div>
      </div>

      <!--Cap 4-->
      <div class="container-caps">
        <div class="index">
          <label>4</label>
        </div>

        <div class="content-cap">
          <img id="imgcap" src="../../../../assets/category_2_bg.png">
          <div class="videoplay_icon">
            <img id="imgplay" src="../../../../assets/icon_play_video.png" class="responsive_play_logo">
          </div>
        </div>

        <div class="info-cap">
          <div class="time-cap">
            <label>48 min</label>
          </div>
          <p id="pcap">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos assumenda asperiores dolore earum
            vel
            doloribus nobis sed ut, quo voluptate magnam nulla, nostrum iste, veritatis voluptates architecto
            rem aliquam. Autem!</p>
        </div>
      </div>

      <!--Cap 5-->
      <div class="container-caps">
        <div class="index">
          <label>5</label>
        </div>

        <div class="content-cap">
          <img id="imgcap" src="../../../../assets/category_4_bg.png">
          <div class="videoplay_icon">
            <img id="imgplay" src="../../../../assets/icon_play_video.png" class="responsive_play_logo">
          </div>
        </div>

        <div class="info-cap">
          <div class="time-cap">
            <label>48 min</label>
          </div>
          <p id="pcap">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos assumenda asperiores dolore earum
            vel
            doloribus nobis sed ut, quo voluptate magnam nulla, nostrum iste, veritatis voluptates architecto
            rem aliquam. Autem!</p>
        </div>
      </div>
    </div>
    </div>
  </div>


  <div class="div container-fluid seccion_similarvideos py-5">
    <div class="container">
      <div class="row">
        <div class="title-similarvideos">
          <h1>Videos relacionados</h1>
        </div>
        <div class="col-md-3">
          <div class="content-similarvideos">
            <img id="imgsimilarvideos" src="../../../../assets/img.jpg">
            <div class="label_video_icon">
              <a href="#"><img src="../../../../assets/icon_play_video.png" class="responsive_play_logo"></a>
            </div>
          </div>
          <div class="content-similarvideos-title">
            <h1 id="titletsimilarvideos">Lorem, ipsum.</h1>

          </div>
          <div class="content-similarvideos-text">
            <p id="textsimilarvideos">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus, maiores. Illo
              impedit explicabo mollitia eos!</p>

          </div>
        </div>
        <div class="col-md-3">
          <div class="content-similarvideos">
            <img id="imgsimilarvideos" src="../../../../assets/img.jpg">
            <div class="label_video_icon">
              <a href="#"><img src="../../../../assets/icon_play_video.png" class="responsive_play_logo"></a>
            </div>
          </div>
          <div class="content-similarvideos-title">
            <h1 id="titletsimilarvideos">Lorem, ipsum.</h1>

          </div>
          <div class="content-similarvideos-text">
            <p id="textsimilarvideos">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus, maiores. Illo
              impedit explicabo mollitia eos!</p>

          </div>
        </div>
        <div class="col-md-3">
          <div class="content-similarvideos">
            <img id="imgsimilarvideos" src="../../../../assets/img.jpg">
            <div class="label_video_icon">
              <a href="#"> <img src="../../../../assets/icon_play_video.png" class="responsive_play_logo"></a>
            </div>
          </div>
          <div class="content-similarvideos-title">
            <h1 id="titletsimilarvideos">Lorem, ipsum.</h1>

          </div>
          <div class="content-similarvideos-text">
            <p id="textsimilarvideos">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus, maiores. Illo
              impedit explicabo mollitia eos!</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="content-similarvideos">
            <img id="imgsimilarvideos" src="../../../../assets/img.jpg">
            <div class="label_video_icon">
              <a href="#"> <img src="../../../../assets/icon_play_video.png" class="responsive_play_logo"></a>
            </div>
          </div>
          <div class="content-similarvideos-title">
            <h1 id="titletsimilarvideos">Lorem, ipsum.</h1>

          </div>
          <div class="content-similarvideos-text">
            <p id="textsimilarvideos">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus, maiores. Illo
              impedit explicabo mollitia eos!</p>

          </div>
        </div>
      </div>
    </div>
  </div>

<footer class="container-fluid py-5 div_footer">
  <div class="container">
      <div class="row">
          <div class="col-12 col-md col-sm-12 mb-5 logo_footer">
              <img src="../../../../assets/logo_footer.png" class="logo_footer">
          </div>
          <div class="col-6 col-md">
              <h5 class="title_footer">EXPLORAR</h5>
              <ul class="list-unstyled text-small">
                  <li><a class="link-secondary" href="#">Módulos</a></li>
                  <li><a class="link-secondary" href="#">Lecciones</a></li>
                  <li><a class="link-secondary" href="#">Instructores</a></li>
              </ul>
          </div>
          <div class="col-6 col-md">
              <h5 class="title_footer">LEGAL</h5>
              <ul class="list-unstyled text-small">
                  <li><a class="link-secondary" href="#">Privacidad</a></li>
                  <li><a class="link-secondary" href="#">Términos</a></li>
                  <li><a class="link-secondary" href="#">Condiciones</a></li>
                  <li><a class="link-secondary" href="#">Soporte</a></li>
              </ul>
          </div>
          <div class="col-6 col-md">
              <h5 class="title_footer">SOCIAL</h5>
              <img src="../../../../assets/social_icons.png">
          </div>
          <div class="col-6 col-md mb-5">
              <h5 class="title_footer">CENTRO DE AYUDA</h5>
          </div>
          <div class="col-12 col-md col-sm-12">
              <ul class="list-unstyled text-small">
                  <li><a class="link-secondary" href="#">© KUAÁ 2021. Todos los derechos reservados</a></li>
              </ul>
          </div>
      </div>
  </div>
</footer>
