import SlideContent from 'src/app/shared/types/slide-content'

import { Component, OnInit } from '@angular/core'

import { InstructorService } from '../../../service/instructor/instructor.service'

@Component({
  selector: 'app-instructors',
  templateUrl: './instructors.component.html',
  styleUrls: ['./instructors.component.scss']
})
export class InstructorsComponent implements OnInit {

  slides: SlideContent[]
  slidesOrdered: SlideContent[]

  constructor(
    private objInstructorService: InstructorService,
  ) { }

  ngOnInit(): void {
    //this.loadInstructorDefault();
    this.getAll();
  }

  async loadInstructorDefault(): Promise<void> {
    try {
      const data = await this.objInstructorService.listByStatusOrderByOrderSequence().toPromise()
      this.slides = data.map((i) => ({
          imgSrc: i.banner1,
          path: ['/home/instructor', i.id, '0']
        }))
    } catch (error) {
      console.error('Error getting instructors: ', error)
    }
  }


  public getAll(){
    this.objInstructorService.getAll().subscribe(
      response => {
        this.slidesOrdered = response.map((i) => ({
          imgSrc: i.banner1,
          path: ['/home/instructor', i.id, '0']
        }))
      }
    )
  }
}
