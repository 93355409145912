import { EmbedVideoService } from 'ngx-embed-video'
import { Helperutil } from 'src/app/common/helper/helperutil'
import { KuaaResponseDto } from 'src/app/model/dto/kuaa-response/kuaa-response-dto'

import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http'
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'

import { KuaaTransactionDto } from '../../../common/trx/kuaa-transaction-dto'
import { KuaaTransactionGenericDto } from '../../../common/trx/kuaa-transaction-generic-dto'
import { CourseDto } from '../../../model/dto/course/course-dto'
import { TokenService } from '../../../service/authorization/token.service'
import { CourseService } from '../../../service/course/course.service'

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {

  objCourseDto: CourseDto = new CourseDto();
  objCourseVideoBaseDTO: any = {};
  objKuaaTransactionGenericDto: KuaaResponseDto<CourseDto>;
  entityTransfer: CourseDto[] = [];

  isLogged = false;
  nombreUsuario = '';
  name = "Angular";
  //@ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  isPlay: boolean = false;
  btnPlayStop = "fa fa-play-circle-o"
  fileContent: any;
  private _window: Window;
  binContent: ArrayBuffer;
  blobContent: any;
  yt_iframe_html: any;
  certBlob: Blob;

  constructor(  private activatedRoute: ActivatedRoute
              , private tokenService:TokenService
              , private objCourseService: CourseService
              , private sanitizer: DomSanitizer
              , private embedService: EmbedVideoService
              ,private httpClient: HttpClient
               ) {

    //this.getContentKuaa();
    //this.generateCertificate();
    //this.loadContentStreaming();
    this.objCourseDto =  new CourseDto();
    this.loadContent();
  }

  ngOnInit(): void {
    //this.objCourseDto =  new CourseDto();
    //this.loadCourse();/

    //this.loadContent();
    //this.generateCertificate();
    //this.getVideo();
    var video = document.querySelector('.video');
    var juice: any  = document.querySelector('.orange-juice');
    var btn = document.getElementById('play-pause');

    //this._window = window.nativeWindow;


    this.btnPlayStop = "fa fa-play-circle-o";
    //var myVideo: any = document.getElementById("my_video_1");
    /*
    if( myVideo != null ){
      if (myVideo.paused){
        //myVideo.play();
        btn.className = 'play';
        this.btnPlayStop = "fa fa-play-circle-o";
      }else{
        btn.className = 'pause';
        this.btnPlayStop = "fa fa-pause-circle-o";
        myVideo.pause();
      }
      myVideo.addEventListener('timeupdate', function() {
          var juicePos = myVideo.currentTime / myVideo.duration;
          juice.style.width = juicePos * 100 + "%";
          if(myVideo.ended) {
              btn.className = "play";
            myVideo.currentTime = 0;
              this.playPause();
          }
      });
    }
    */

  }

  ngAfterViewInit(){

  }


  loadContentStreaming(){
    this.objCourseDto =  new CourseDto();
    //this.objCourseDto.fileContent = "http://192.168.1.20:8081/video/stream/bf5l9rvzmafbc";
    //this.objCourseDto.fileContent = "http://192.168.1.19:8080/video/stream/v5";

    //For node
    //this.objCourseDto.fileContent = "http://192.168.1.19:8080/streaming/87";
    //this.objCourseDto.fileContent = "http://192.168.1.19:8080/video";
    //this.objCourseDto.fileContent = "http://192.168.1.19:8080/content/streaming/v6";
    this.objCourseDto.fileContent = "http://35.194.30.173:8080/content/streaming/v4";
    //this.objCourseDto.fileContent =this.sanitizer.bypassSecurityTrustUrl("http://192.168.1.20:8081/video/stream/wg8rqnaib8gbc");
  }


  generateCertificate( ) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const sToken = this.tokenService.getToken();
    var  CourseDto ={ code:'ffnntcp74tbc' }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + sToken
        , responseType: 'application/json'
      })
    };

    /*
    this.httpClient.post("http://192.168.10.23:8080/course/generateStreaming/ffnntcp74tbc", CourseDto,httpOptions ).subscribe(
     (data) => {
         //let dataType = data.type;
         let binaryData = [];
         binaryData.push(data);
         this.certBlob = new Blob(binaryData);
     });
     */
     //return this.certBlob;
     var myHeaders = new Headers();
     myHeaders.append("Authorization", "Bearer "+sToken);
     myHeaders.append("Content-Type", "video/mp4");

    var raw = JSON.stringify({"code":"ffnntcp74tbc"});

    var requestOptions = {
      method: 'GET',
      //headers: myHeaders,
      //body: raw
    };

     fetch("http://192.168.1.21:8081/video/stream/bf5l9rvzmafbc", requestOptions)
     //fetch("http://192.168.10.23:8080/course/videos/ffnntcp74tbc", requestOptions)
          .then(response => response.text())
          .then(result => {
            let binaryData = [];
            binaryData.push( result );
            const fileType = "video/mp4";
            const blob = new Blob( binaryData , {type: fileType });
            const urlVIdeo = URL.createObjectURL(blob);

            const src = this.sanitizer.bypassSecurityTrustUrl( urlVIdeo )as string;
            // this.objCourseDto =  new CourseDto();
            // this.objCourseDto.fileContent = urlVIdeo;
            //this.objCourseDto.fileContent ="data:video/mp4;base64,"+result;
            //this.objCourseDto.fileContent = this.sanitizer.bypassSecurityTrustUrl(urlVIdeo);
            //this.createImageFromBlob( blob );

          })
          .catch(error => console.error('error', error));

  }

  getVideo( ) {

    const sToken = this.tokenService.getToken();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+sToken);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      responseType : 'blob',
      observe : 'response'
      //redirect: 'follow'
    };

    //this.objCourseDto =  new CourseDto();
    //this.objCourseDto.fileContent ="http://192.168.10.23:8090/video/stream/mp4/toystory";

    //fetch("http://192.168.10.23:8080/course/videos/ffnntcp74tbc", requestOptions)
    /*
    fetch("http://192.168.10.23:8090/video/stream/mp4/toystory", requestOptions)
    .then(response => response.text())
    .then(result => {
      let binaryData = [];
      binaryData.push(result);

      const fileType = "video/mp4";
      const blob = new Blob( binaryData , {type: fileType });
      const urlVIdeo = URL.createObjectURL(blob);

      const src = this.sanitizer.bypassSecurityTrustUrl( urlVIdeo )as string;
      this.objCourseDto =  new CourseDto();
      this.objCourseDto.fileContent = urlVIdeo;
      //this.objCourseDto.fileContent ="data:video/mp4;base64,"+blob;
      //this.createImageFromBlob( blob );

      })
      .catch(error => console.error('error', error));
      */

  }




  loadContent(){
    const id = this.activatedRoute.snapshot.params.id;
    this.isLogged = true;
    const sToken = this.tokenService.getToken();

    this.objCourseService.getCompleteVideoBaseFindById(id).subscribe(
      async data =>{
        //this.objCourseDto.fileContent = data;
        //this.objCourseDto.fileContent = "http://35.194.30.173:8080/content/streaming/" + "v4";
        this.objKuaaTransactionGenericDto = data;
        if (this.objKuaaTransactionGenericDto.code == '00000') {
             this.entityTransfer = [];
             this.entityTransfer = this.objKuaaTransactionGenericDto.entityTransfer;
             /*
             this.objCourseDto =  new CourseDto();
             this.objCourseDto = this.entityTransfer[0];
             */
             this.objCourseVideoBaseDTO = this.entityTransfer[0];
             //this.objCourseDto.fileContent = "https://35.194.30.173:8080/content/streaming/" + this.objCourseDto.link;
             //this.objCourseDto.fileContent = "http://api.kw.kuaa.co:8080/content/streaming/" + this.objCourseDto.link;
             //this.objCourseDto.fileContent = "http://kuaa.life:8080/content/streaming/" + this.objCourseDto.link;
             //this.objCourseVideoBaseDTO.CourseDto.fileContent = "";
             //this.objCourseVideoBaseDTO.CourseDto.fileContent = "http://api.streaming.kuaa.co:8080/content/streaming/" + this.objCourseDto.link;
             //this.objCourseDto.fileContent = "http://kuaa-api-5pxt2bdb.uc.gateway.dev/content/streaming/" + this.objCourseDto.link;
             this.objCourseDto =  new CourseDto();
             this.objCourseDto =  this.objCourseVideoBaseDTO.CourseDto;

        }

      });

     /*
      this.objCourseService.getContentDownload('ffnntcp74tbc', sToken).subscribe(
        //results => this.objCourseDto.fileContent = results );
        (data) => {
          let dataType = data.type;
          let binaryData = [];
          binaryData.push(data);
          this.certBlob = new Blob(binaryData);
        });
        */

  }

  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.objCourseDto.fileContent = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getContentKuaa(){

    const id = this.activatedRoute.snapshot.params.id;
    this.isLogged = true;
    this.objCourseDto =  new CourseDto();
    this.objCourseDto.code =id;
    this.objCourseDto.fileContent =  Helperutil.S_CORE_SERVICE_API_V1_COURSE_STREAMING +  id;
    //this.objCourseDto.fileContent =  Helperutil.S_CORE_SERVICE_API_V1_COURSE_STREAMING;


  }

  loadCourse(){
    const id = this.activatedRoute.snapshot.params.id;
    this.isLogged = true;
    this.objCourseService.findById(id).subscribe(
      async data =>{
        this.objKuaaTransactionGenericDto = data;
            if (this.objKuaaTransactionGenericDto.code == '00000') {
                 this.entityTransfer = [];
                 this.entityTransfer = this.objKuaaTransactionGenericDto.entityTransfer;
                 this.objCourseDto =  new CourseDto();
                 this.objCourseDto = this.entityTransfer[0];


                 this.objCourseService.downloadFile(this.objCourseDto.fileContent).subscribe(data => {
                    this.createImageFromBlob(data);
                  }, error => {
                    console.error(error);
                  });

                 this.yt_iframe_html = this.embedService.embed( this.objCourseDto.fileContent );
                 //const blobUrl = URL.createObjectURL(this.objCourseDto.fileContent );
                 //const safeblobUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
                 var img_url = this.objCourseDto.fileContent;
                 //var blob = new Blob([img_url]);
                 //let reader = new FileReader;

                 const videoTag: any = document.getElementById("my_video_1");
                 const myMediaSource = new MediaSource();
                 //const url = URL.createObjectURL(myMediaSource);
                 //const url = "https://storage.googleapis.com/kuaacontentstreaming/kuaacontentstreaming/content/335d052klcnbc/335d052klcnbc.mp4";
                 //const url   = "https://storage.googleapis.com/kuaacontentstreaming/kuaacontentstreaming/content/335d052klcnbc/335d052klcnbc.mp4";
                 const url = this.objCourseDto.fileContent;
                 var URL = window.URL;
                 //this.fileContent = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(url));
                 //var myVideo: any = document.getElementById("my_video_1");
                 //myVideo.src = URL.createObjectURL(myMediaSource);
                 //const videoSourceBuffer = myMediaSource.addSourceBuffer('video/mp4; codecs="avc1.64001e"');


                 //videoTag.src = url;

                 //videoTag.src = this.objCourseDto.fileContent
                 /*
                 const audioSourceBuffer = myMediaSource.addSourceBuffer('audio/mp4; codecs="mp4a.40.2"');
                 const videoSourceBuffer = myMediaSource.addSourceBuffer('video/mp4; codecs="avc1.64001e"');
                 */

                /*
                reader.readAsDataURL(blob); // read file as data url
                reader.onload = () => { // when file has loaded

                  };
                  */
                  //const response = await fetch(img_url);
                  //var mediaSource = new MediaSource(img_url);
                  /*
                  const httpOptions = {
                    headers: new HttpHeaders({
                      'Content-Type': 'application/octet-strea',
                      Authorization: 'Bearer ' + sToken
                    })
                  };

                  this.httpClient.get(  url  ).subscribe((resp: any) => {

                  },
                  err => {
                  }
                  );
                  */


                 //this.yt_iframe_html = this.embedService.embed( "https://www.youtube.com/watch?v=iHhcHTlGtRs" );


                 //let loco =  this.sanitizer.bypassSecurityTrustUrl( this.objCourseDto.fileContent );
                 /*

                 let cont = this.str2ab( this.objCourseDto.content );
                 this.binContent = cont;

                 this.blobContent = new Blob([this.binContent], {type: "video/mp4" });
                */

                 //this.fileContent = loco;
                 /*
                 //this.fileContent = this._window.URL.createObjectURL(image)
                 this.objCourseService.getImage(this.objCourseDto.fileContent).subscribe(x => {
                      this.fileContent = this.fileContent

                   }
                   );
                   */
                 /*
                 var blob = new Blob([this.objCourseDto.fileContent], {type: 'application/octet-binary'}); // pass a useful mime type here
                 var url = URL.createObjectURL(blob);
                 this.fileContent = blob;

                 var config = { responseType: 'blob' };
                 $http.get(this.objCourseDto.fileContent, config).then(function onSuccess(response) {
                  var blob = response.data;
                  var contentType = response.headers("content-type");
                  var fileURL = URL.createObjectURL(blob);
                  window.open(fileURL);
              });
              */
            }
      }
    );


  }

 unpack(str) {
    var bytes = [];
    for(var i = 0; i < str.length; i++) {
        var char = str.charCodeAt(i);
        bytes.push(char >>> 8);
        bytes.push(char & 0xFF);
    }
    return bytes;
}

str2ab(str) {
  var buf = new ArrayBuffer(str.length*2); // 2 bytes for each char
  var bufView = new Uint16Array(buf);
  for (var i=0, strLen=str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

  getEmberUrl(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.objCourseDto.fileContent);
  }

  toggleVideo(event: any) {
    try {
      //this.videoplayer.nativeElement.play();
    } catch (error) {
      console.error('Error: ', error );
    }

  }
  playPause() {

    //this.loadCourse();

    var myVideo: any = document.getElementById("my_video_1");
    var juice: any  = document.querySelector('.orange-juice');
    var btn = document.getElementById('play-pause');
    this.btnPlayStop = "fa fa-play-circle-o";

    if (myVideo.paused){
      myVideo.play();
      btn.className = 'play';
      this.btnPlayStop = "fa fa-play-circle-o";
    }else{
      btn.className = 'pause';
      this.btnPlayStop = "fa fa-pause-circle-o";
      myVideo.pause();
    }
    myVideo.addEventListener('timeupdate', function() {
      var juicePos = myVideo.currentTime / myVideo.duration;
      juice.style.width = juicePos * 100 + "%";
      if(myVideo.ended) {
          btn.className = "play";
        // At the end of the movie, reset the position to the start and pause the playback.
        myVideo.currentTime = 0;
          this.toggleVideo();
      }
  });

  }

  makeBig() {
    var myVideo: any = document.getElementById("my_video_1");
    myVideo.width = window.innerWidth+560;
  }

  makeSmall() {
    var myVideo: any = document.getElementById("my_video_1");
    myVideo.width = 320;
  }

  makeNormal() {
    var myVideo: any = document.getElementById("my_video_1");
    myVideo.width = 420;
  }

  skip(value) {
    let video: any = document.getElementById("my_video_1");
    video.currentTime += value;
  }

  restart() {
    //this.loadCourse();
    let video: any = document.getElementById("my_video_1");
    video.currentTime = 0;
  }

}
