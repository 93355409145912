import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
//import { Http, Headers, Response } from '@angular/http';
import { Observable, from } from 'rxjs';
import { SuscriptorDto } from '../../model/dto/suscriptor/suscriptor-dto';
import { Helperutil } from '../../common/helper/helperutil';
import { __param } from 'tslib';

@Injectable({
  providedIn: 'root'
})
export class SuscriptorService {

  constructor(private httpClient: HttpClient) { }

  private getTokenSesion(sToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sToken
      })
    };
    return httpOptions;
  }

  public findById(sToken: string): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_SUSCRIPTOR_FINDBYID, httpOptions );
  }

  public getAllIconst(sToken: string): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_ICONS_GET_ALL, httpOptions );
  }

  public save( suscriptorDto: SuscriptorDto, sToken): Observable<any> {
    const httpOptions = this.getTokenSesion(sToken);
    return this.httpClient.post<any>(Helperutil.S_CORE_SERVICE_API_V1_SUSCRIPTOR_UPDATE, suscriptorDto, httpOptions);
  }

  public addToMyList(formData, sToken: string): Observable<any> {

    

    return this.httpClient.put<any>(Helperutil.S_CORE_SERVICE_API_V1_ADD_TO_MY_LIST, formData,this.httpOptions(sToken) );
  }

  public getMyList(sToken: string): Observable<any> {

    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_API_V1_ADD_TO_MY_LIST, this.httpOptions(sToken) );
  }

  public httpOptions(sToken){
    return {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + sToken
      })
    };
  }


}
