<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
      aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div *ngFor="let data of dataCarousel" [class]="data.class" class="carousel-item">
      <img src="{{data.BackgroungImage}}" alt="{{data.alt}}" style="width: 100%; height: auto;">
      <div class="carousel-caption carousel-kuaa">
        <div class="just-added" *ngIf="data.new">
          <p class="btn-play">Recién Agregado</p>
        </div>
        <img src="{{data.urlImage}}" />
        <p>{{data.title}}</p>
        <div class="btn-play-div">
          <a id="btn-play" class="btn-play" href="{{data.href}}">{{data.btn}}</a>
        </div>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

<div class="div container-fluid modules pb-5 ps-5 pe-5">

  <app-modules></app-modules>
  <div class="row">
    <div class="col-12 justify-content-start  ">
      <h1 class="sections_title"><b>Experiencias</b> para todos</h1>
    </div>
  </div>
  <app-fullness></app-fullness>
  <app-series></app-series>
  <app-instructors></app-instructors>
  <app-level5></app-level5>
  <app-more-popular></app-more-popular>
  <div class="d-none d-sm-none d-sm-block d-lg-block d-xl-block">
    <app-level6></app-level6>
  </div>
</div>
