import { Component, NgZone, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { CourseDto } from '../../../model/dto/course/course-dto'
import { TokenService } from '../../../service/authorization/token.service'
import { CourseService } from '../../../service/course/course.service'
import { AuthService } from '../../../shared/services/auth.service'

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.css']
})
export class CourseListComponent implements OnInit {

  objListCourses: CourseDto[] = [];

  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    private objCourseService: CourseService,
    private tokenService:TokenService
  ) {
  }

  ngOnInit(): void {
    this.loadCoursesDefault();
  }


  loadCoursesDefault(): void{
    //const sToken = this.authService.userData.idToken;
    const sToken = this.tokenService.getToken();
    this.objCourseService.getCoursesDefault( sToken ).subscribe(
      data => {
          this.objListCourses = data
        },
        err => {
          console.error(err);
        }
    );
  }

}
