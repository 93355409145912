import { SharedModule } from 'src/app/shared/shared.module'

import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'

import { BillingComponent } from './billing/billing.component'
import { CreditCardFormComponent } from './credit-card-form/credit-card-form.component'
import { CreditCardsComponent } from './credit-cards/credit-cards.component'
import { PaymentListComponent } from './payment-list/payment-list.component'
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { SubscriptionStatusComponent } from './subscription-status/subscription-status.component';
import { ShowCreditCardActiveComponent } from './show-credit-card-active/show-credit-card-active.component'

@NgModule({
  declarations: [
    BillingComponent,
    CreditCardsComponent,
    PaymentStatusComponent,
    PaymentListComponent,
    CreditCardFormComponent,
    SubscriptionStatusComponent,
    ShowCreditCardActiveComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule
  ],
  exports: [
    BillingComponent
  ]
})
export class PaymentModule { }
