import { PaymentsService } from 'src/app/service/payments/payments.service'
import { SubscriptionPlansService } from 'src/app/service/payments/subscription-plans.service'
import { UserService } from 'src/app/service/payments/user.service'
import User from 'src/app/shared/types/user'
import DateTime from 'src/app/shared/utils/datetime'
import Swal from 'sweetalert2'
import { Router } from '@angular/router'

import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'subscription-status',
  templateUrl: './subscription-status.component.html',
  styleUrls: ['./subscription-status.component.css']
})
export class SubscriptionStatusComponent implements OnInit, OnChanges {
  formSubscription: FormGroup;
  expireAt = '';
  expired = true;
  saving = false;
  openNewForm = false;
  openCrediCardActive = false;
  plans = [];
  @Input() userSubscription: User = null;
  @Output() changeSubscription = new EventEmitter();
  @Output() newPayment = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private paymentsService: PaymentsService,
    private subscriptionPlansService: SubscriptionPlansService,
    private userService: UserService,
    public router: Router
    ) {
      this.formSubscription = this.formBuilder.group({
        subscriptionPlanId: [this.userSubscription ? this.userSubscription.activePlanId : '', Validators.required],
      });
    }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.userSubscription) {
      const subscription = changes.userSubscription as unknown as User;
      this.calculateExpiration();
      if (this.plans.length === 0 && (this.expired || !subscription.subscriptionExpiration)) {
        this.loadPlans();
      }
    }
  }

  loadPlans() {
    this.subscriptionPlansService.getAll()
      .subscribe(
        (plans) => {
          this.plans = plans;
        },
        (error) => {
          console.error("Error getting available plans: ", error);
        }
      )
  }

  calculateExpiration() {
    if (this.userSubscription && this.userSubscription.subscriptionExpiration && this.userSubscription.subscriptionExpiration._seconds) {
      const currenDate = DateTime.now();
      const expirationDate = DateTime.fromSeconds(this.userSubscription.subscriptionExpiration._seconds);

      this.expired = currenDate > expirationDate;
      this.expireAt = expirationDate.toFormat('LLLL dd, yyyy');
    }
  }

  subscribe(event) {
    const form = event.target as unknown as HTMLFormElement;
    if (form) {
      form.classList.add('was-validated');
    }

    if (this.formSubscription.valid) {
      this.saving = true;
      Swal.fire('Por favor espere...', '', 'info');
      this.paymentsService.create( this.formSubscription.value )
        .subscribe((response) => {
          if (response.status === 'approved') {
            Swal.fire({
              title: '¡Genial!',
              text: 'Tu plan ha sido actualizado exitosamente',
              icon: 'success',
              confirmButtonText: 'OK'
            })
            /*setTimeout(() => {
              Swal.close();
            }, 3000);*/
            this.changeSubscription.emit();
          } else {
            Swal.fire({
              title: '¡No pudimos procesar el pago!',
              text: 'Verifica que los datos de tu tarjeta sean correctos y que tenga fondos disponibles. O prueba agregando una nueva tarjeta.',
              icon: 'error',
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: 'Registrar nueva tarjeta',
              denyButtonText: 'Ver tarjeta',
              cancelButtonText: 'Salir',
              confirmButtonColor: '#7A9718',
              denyButtonColor: '#049DD4',
              cancelButtonColor: '#ED412A',
            }).then((result) => {
              if(result.isConfirmed){
                this.openNewForm = true
              }else if(result.isDenied){
                this.openCrediCardActive = true;
              }
            })
          }
          this.newPayment.emit();
        },
        (error: { error: { error?: string, description: string } }) => {
          console.error('Error making subscription: ', error);
          Swal.fire(error.error.error || 'Error', error.error.description, 'error');
        },
        () => {
          this.saving = false;
        });
    }
  }

  enableAutoRenewal() {
    this.saving = true;
    const payload = {
      autoRenewal: true,
      subscriptionCancelledAt: null,
    };

    this.userService.update( payload )
      .subscribe(
        () => {
          Swal.fire({
            title: '¡Excelente! ',
            text: 'Tu débito automático ha sido activado correctamente',
            icon: 'success',
            confirmButtonText: 'OK'
          })
          this.changeSubscription.emit();
        },
        (error) => {
          Swal.fire('Lo sentimos', "Algo ha salido mal, por favor intenta de nuevo más tarde", 'error');
          console.error('Error enabling auto-renewal subscription: ', error);
        },
        () => {
          this.saving = false;
        }
      )
  }

  onCloseNewForm(): void {
    this.openNewForm = false;
    window.location.reload();
  }

  onCloseCreditCardActive(): void {
    this.openCrediCardActive = false;
  }

  onCreate() {
    this.onCloseNewForm();
  }
}
