import { Injectable, NgZone } from '@angular/core';
import { User } from "../services/user";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { TokenService } from '../../service/authorization/token.service';
import { Helperutil } from '../../common/helper/helperutil';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/service/payments/user.service';


@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private tokenService: TokenService,
    private userService: UserService
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        //this.userData.idToken= user.xa;

        this.userData ={
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified,
          idToken: JSON.parse(JSON.stringify(this.afAuth.auth.currentUser)).stsTokenManager.accessToken
        }
        /*
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        })
        */
        //localStorage.setItem('user', JSON.stringify(this.userData));
        //JSON.parse(localStorage.getItem('user'));
      } else {
        //localStorage.setItem('user', null);
        //JSON.parse(localStorage.getItem('user'));
        this.router.navigate(['login']);
      }

    })
  }

  getCurrentUser(){
    return new Promise<any>((resolve, reject) => {
      var user = this.afAuth.auth.onAuthStateChanged(function(user){
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
        }
      })
    })
  }

  // Sign in with email/password
  SignIn(email, password, typeRequest = '', acceptedPrivacyPolicy = false, acceptedTermsAndConditions = false) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          //if(result.user.emailVerified){
                //this.router.navigate(['home']);
                //this.tokenService.setToken(JSON.parse(JSON.stringify(result.user)).stsTokenManager.accessToken );
                //this.tokenService.setToken( result.user.toJSON().stsTokenManager.accessToken );
                //this.tokenService.setUserName( result.user.email );
                //this.tokenService.setAuthorities(  Helperutil.S_CORE_AUTORITIE_ROL_STS );

                this.afAuth.idTokenResult.subscribe((user: any) => {
                  //localStorage.setItem('bearerToken', user.token + '' || '');
                  //resolve(this.getRole(email));
                  //const expirationDate = new Date(user.expirationTime);
                  //localStorage.setItem('expirationTime', expirationDate+"");
                  this.tokenService.setExpirationTime( user.expirationTime );
                  this.tokenService.setToken( user.token );
                  this.tokenService.setUserName( user.claims.email );
                  this.tokenService.setUserID( user.claims.user_id );
                  this.tokenService.setAuthorities(  Helperutil.S_CORE_AUTORITIE_ROL_STS );
                  if(typeRequest == 'is_payment'){
                    //this.updateUser(acceptedPrivacyPolicy, acceptedTermsAndConditions, this.tokenService.getToken())
                    this.router.navigate(['/login/plans-payment'])
                  }else{
                    this.router.navigate(['home']);
                  }
                });
          /*}else{
            window.alert('Verifique el correo electrónico registrado.')
          }*/




        });
        this.SetUserData(result.user);
      }).catch((error) => {
        let errorMessageTitle = 'Error al iniciar sesión'
        let errorMessageMessage = error.message
        console.error(error)
        if(error.code == 'auth/wrong-password'){
          errorMessageTitle = 'Usuario o contraseña incorrectos'
          errorMessageMessage = 'Verifica la información e intenta de nuevo.'
        }else if(error.code == 'auth/user-not-found'){
          errorMessageTitle = 'Usuario no registrado'
          errorMessageMessage = 'No encontramos algún usuario registrado con esta cuenta de correo electrónico, por favor verifícalo e intenta nuevamente.'
        }else if(error.code == 'auth/invalid-email'){
          errorMessageTitle = 'Campos sin llenar'
          errorMessageMessage = 'Ingresa correo electrónico y contraseña para continuar.'
        }
        Swal.fire({
          title: errorMessageTitle,
          text: errorMessageMessage,
          icon: 'error',
          confirmButtonColor: '#14b8ec',
          background: 'black',
          color: '#14b8ec'
        })
      })
  }

  // Sign up with email/password
  async SignUp(email, password) {
    return await this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        //this.SendVerificationMail();
        this.SetUserData(result.user);
        return true
      }).catch((error) => {
        Swal.fire({
          title: 'Usuario registrado',
          text: 'El correo electrónico que ingresó ya está registrado',
          icon: 'error',
          confirmButtonColor: '#14b8ec',
          background: 'black',
          color: '#14b8ec'
        })
        return false
      })
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
    .then(() => {
      this.router.navigate(['verify-email-address']);
    })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail, isChange = false) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      let title = 'Recuperación de contraseña'
      let text = 'Revisa tu correo electronico para recuperar tu contraseña'
      if(isChange){
        title = 'Cambio de contraseña'
        text = 'Revisa tu correo electronico para cambiar tu contraseña'
      }
      Swal.fire({
        title: title,
        text: text,
        icon: 'success',
        confirmButtonColor: '#14b8ec',
        background: 'black',
        color: '#14b8ec'
      })
    }).catch((error) => {
      Swal.fire({
        title: 'Usuario no registrado',
        text: 'No encontramos algún usuario registrado con esta cuenta de correo electrónico, por favor verifícalo e intenta nuevamente.',
        icon: 'error',
        confirmButtonColor: '#14b8ec',
        background: 'black',
        color: '#14b8ec'
      })
    })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
  //isLoggedIn()  {
    //const user = JSON.parse(localStorage.getItem('user'));
    //const user = JSON.parse(JSON.stringify(this.afAuth.auth.currentUser)).stsTokenManager.accessToken;

    const user = this.userData ;

    this.afAuth.authState.subscribe(user => {
      return true;
    });
    //return (user !== null && user.emailVerified !== false) ? true : false;
    const isIn = (user !== undefined ) ? true : false;
    return isIn;
  }


  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
    .then((result) => {
       this.ngZone.run(() => {
          this.router.navigate(['home']);
          this.afAuth.idTokenResult.subscribe((user: any) => {
            this.tokenService.setToken( user.token );
            this.tokenService.setUserName( user.claims.email );
            this.tokenService.setAuthorities(  Helperutil.S_CORE_AUTORITIE_ROL_STS );
            this.router.navigate(['home']);

          });
        })
      this.SetUserData(result.user);
    }).catch((error) => {
      window.alert(error);
    })
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      //photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      idToken: user.xa
    }
    return userRef.set(userData, {
      merge: true
    });
    //return userData;

  }

  // Sign out
  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['login']);
    })
  }

  resetPassword(oobCode, newPassword){
    return this.afAuth.auth.confirmPasswordReset(oobCode, newPassword)
  }

  private updateUser(acceptedPrivacyPolicy, acceptedTermsAndConditions, token) {
    const newUserData = {
      acceptedPrivacyPolicy: acceptedPrivacyPolicy,
      acceptedTermsAndConditions: acceptedTermsAndConditions
    }
    this.userService.update(newUserData).subscribe(
      result => {
        this.router.navigate(['/login/plans-payment'])
      },
      error => {
        console.error(error)
      }
    )
  }

}
