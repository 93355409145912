<head> 
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width,initial-scale=1">
    <link rel="icon" href="https://storage.googleapis.com/publickuaa/publickuaa/assets/img/icon.png">
    <title>Kuaa</title>
    <script href="https://storage.googleapis.com/publickuaa/home/js/about.75040066.js" rel="prefetch" as="script"  crossorigin="anonymous"></script>
    <link href="https://storage.googleapis.com/publickuaa/home/css/app.04180b6e.css" rel="preload" as="style">
    <link href="https://storage.googleapis.com/publickuaa/home/css/chunk-vendors.51c0f58e.css" rel="preload" as="style">
    <script href="https://storage.googleapis.com/publickuaa/home/js/app.fb182164.js" rel="preload" as="script" crossorigin="anonymous"></script>
    <link href="https://storage.googleapis.com/publickuaa/home/js/chunk-vendors.5bca92e9.js" rel="preload" as="script" />
    <link href="https://storage.googleapis.com/publickuaa/home/css/chunk-vendors.51c0f58e.css" rel="stylesheet" />
    <link href="https://storage.googleapis.com/publickuaa/home/css/app.04180b6e.css" rel="stylesheet" />
    <style type="text/css">
        html {
            height: 100%
        }

        .bm-burger-button {
            cursor: pointer;
            height: 30px;
            left: 36px;
            position: absolute;
            top: 36px;
            width: 36px
        }

        .bm-burger-button.hidden {
            display: none
        }

        .bm-burger-bars {
            background-color: #373a47
        }

        .line-style {
            height: 20%;
            left: 0;
            position: absolute;
            right: 0
        }

        .cross-style {
            cursor: pointer;
            position: absolute;
            right: 2px;
            top: 12px
        }

        .bm-cross {
            background: #bdc3c7
        }

        .bm-cross-button {
            height: 24px;
            width: 24px
        }

        .bm-cross-button.hidden {
            display: none
        }

        .bm-menu {
            background-color: #3f3f41;
            height: 100%;
            left: 0;
            overflow-x: hidden;
            padding-top: 60px;
            position: fixed;
            top: 0;
            transition: .5s;
            width: 0;
            z-index: 1000
        }

        .bm-overlay {
            background: rgba(0, 0, 0, .3)
        }

        .bm-item-list {
            color: #b8b7ad;
            font-size: 20px;
            margin-left: 10%
        }

        .bm-item-list>* {
            display: flex;
            padding: .7em;
            text-decoration: none
        }

        .bm-item-list>*>span {
            color: #fff;
            font-weight: 700;
            margin-left: 10px
        }
    </style>
    <link rel="stylesheet" href="https://storage.googleapis.com/publickuaa/home/css/app.css">
    <link rel="stylesheet" href="https://storage.googleapis.com/publickuaa/home/css/styles.css">
    <link rel="stylesheet" href="https://storage.googleapis.com/publickuaa/home/css/menu.css">

    
</head>


