<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <style>

    </style>
  </head>

          <div class="container mx-auto">
            <div class="my-8">
                <div class="mb-12 glide glide--ltr glide--slider glide--swipeable">

                    <div class="container-fluid px-0">
                        <div class="row bg-dark h-100">
                            <div class="col-12 px-0">
                                <app-carousel [items]="carouselData"></app-carousel>
                            </div>
                        </div>
                    </div>

                    <!--
                    <div data-glide-el="track" class="glide__track">
                        <ul class="glide__slides"
                            style="transition: transform 0ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s; width: 6205px; transform: translate3d(0px, 0px, 0px);">
                            <li data-glide-index="0" class="glide__slide glide__slide--active"
                                style="width: 1233px; margin-right: 5px;"><a href="/about" class=""><img
                                        src="https://storage.googleapis.com/publickuaa/static/img/bannermain.jpg"
                                        alt="mandalorian" class="rounded"></a></li>
                            <li data-glide-index="1" class="glide__slide"
                                style="width: 1233px; margin-left: 5px; margin-right: 5px;"><a href="/about"
                                    class=""><img
                                        src="https://storage.googleapis.com/publickuaa/static/img/1280x386.png"
                                        alt="mandalorian" class="rounded"></a></li>
                            <li data-glide-index="2" class="glide__slide"
                                style="width: 1233px; margin-left: 5px; margin-right: 5px;"><a href="/about"
                                    class=""><img
                                        src="https://storage.googleapis.com/publickuaa/static/img/1280x386.png"
                                        alt="mandalorian" class="rounded"></a></li>
                            <li data-glide-index="3" class="glide__slide"
                                style="width: 1233px; margin-left: 5px; margin-right: 5px;"><a href="/about"
                                    class=""><img
                                        src="https://storage.googleapis.com/publickuaa/static/img/1280x386.png"
                                        alt="mandalorian" class="rounded"></a></li>
                            <li data-glide-index="4" class="glide__slide"
                                style="width: 1233px; margin-left: 5px;">
                                <a href="/about" class=""><img
                                        src="https://storage.googleapis.com/publickuaa/static/img/1280x386.png"
                                        alt="mandalorian" class="rounded"></a>
                            </li>
                        </ul>

                    </div>
                    <div data-glide-el="controls"><button data-glide-dir="<"
                            class="absolute w-8 h-8 rounded-full bg-gray-500"
                            style="top: calc(50% - 16px); left: 10px;"><svg width="18" height="18"
                                viewBox="0 0 24 24" style="margin-left: 6px;">
                                <path
                                    d="M0 12l10.975 11 2.848-2.828-6.176-6.176H24v-3.992H7.646l6.176-6.176L10.975 1 0 12z">
                                </path>
                            </svg></button><button data-glide-dir=">"
                            class="absolute w-8 h-8 rounded-full bg-gray-500"
                            style="top: calc(50% - 16px); right: 10px;"><svg width="18" height="18"
                                viewBox="0 0 24 24" style="margin-left: 6px;">
                                <path
                                    d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z">
                                </path>
                            </svg></button></div>
                    <div data-glide-el="controls[nav]" class="glide__bullets"><button data-glide-dir="=0"
                            class="glide__bullet glide__bullet--active"></button><button data-glide-dir="=1"
                            class="glide__bullet"></button><button data-glide-dir="=2"
                            class="glide__bullet"></button><button data-glide-dir="=3"
                            class="glide__bullet"></button><button data-glide-dir="=4"
                            class="glide__bullet"></button>
                    </div>
                    -->
                </div>
                <div class="categories mb-12">
                    <div class="glide glide--ltr glide--slider glide--swipeable">
                        <div data-glide-el="track" class="glide__track">

                            <ul class="glide__slides"
                                style="transition: transform 0ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s; width: 1120px; transform: translate3d(0px, 0px, 0px);">

                                <li data-glide-index="0" class="glide__slide glide__slide--active kuaCategory"
                                    *ngFor="let item of objListCategories">
                                    <a routerLink="/home/category/{{item.code}}" class="bg-gray-800 h-32 flex justify-center items-center shadow-custom border border-gray-700 rounded-lg p-4 hover:bg-gray-900"><img
                                            src="{{item.banner1}}"
                                            alt="{{item.name}}"></a>
                                </li>

                               </ul>
                        </div>
                        <!--
                        <div data-glide-el="controls"><button data-glide-dir="<"
                                class="absolute w-8 h-8 rounded-full bg-gray-500"
                                style="top: calc(50% - 16px); left: 10px;"><svg width="18" height="18"
                                    viewBox="0 0 24 24" style="margin-left: 6px;">
                                    <path
                                        d="M0 12l10.975 11 2.848-2.828-6.176-6.176H24v-3.992H7.646l6.176-6.176L10.975 1 0 12z">
                                    </path>
                                </svg></button><button data-glide-dir=">"
                                class="absolute w-8 h-8 rounded-full bg-gray-500"
                                style="top: calc(50% - 16px); right: 10px;"><svg width="18" height="18"
                                    viewBox="0 0 24 24" style="margin-left: 6px;">
                                    <path
                                        d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z">
                                    </path>
                                </svg></button>
                            </div>
                            -->
                    </div>
                </div>
                <div class="recommended px-6 mb-12">
                    <h3 class="text-gray-500 mb-1">Recommended For You</h3>
                    <div class="glide glide--ltr glide--slider glide--swipeable">
                        <div data-glide-el="track" class="glide__track">
                            <ul class="glide__slides"
                                style="transition: transform 0ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s; width: 1342px; transform: translate3d(0px, 0px, 0px);">
                                <li data-glide-index="0" class="glide__slide glide__slide--active"
                                    style="width: 207px; margin-right: 10px;"
                                    *ngFor="let item of objListCourses">
                                    <a routerLink="/home/preview/{{item.code}}/0" class="" title="{{item.name}}">
                                        <img src="{{item.banner1}}" alt="star wars">
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div data-glide-el="controls"><button data-glide-dir="<"
                                class="absolute w-8 h-8 rounded-full bg-gray-500"
                                style="top: calc(50% - 16px); left: 10px;"><svg width="18" height="18"
                                    viewBox="0 0 24 24" style="margin-left: 6px;">
                                    <path
                                        d="M0 12l10.975 11 2.848-2.828-6.176-6.176H24v-3.992H7.646l6.176-6.176L10.975 1 0 12z">
                                    </path>
                                </svg></button><button data-glide-dir=">"
                                class="absolute w-8 h-8 rounded-full bg-gray-500"
                                style="top: calc(50% - 16px); right: 10px;"><svg width="18" height="18"
                                    viewBox="0 0 24 24" style="margin-left: 6px;">
                                    <path
                                        d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z">
                                    </path>
                                </svg></button></div>
                    </div>
                </div>
                <div class="continue-watching px-6 mb-12">
                    <h3 class="text-gray-500 mb-1">Alimentacion</h3>
                    <div class="glide glide--ltr glide--slider glide--swipeable">
                        <div data-glide-el="track" class="glide__track">
                            <ul class="glide__slides"
                                style="transition: transform 0ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s; width: 1342px; transform: translate3d(0px, 0px, 0px);">

                                <li data-glide-index="0" class="glide__slide glide__slide--active"
                                    style="width: 207px; margin-right: 10px;"
                                    *ngFor="let item of objListCatAlimens">
                                    <a routerLink="/home/preview/{{item.code}}/0" class="" title="{{item.name}}">
                                        <img src="{{item.banner1}}" alt="star wars">
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div data-glide-el="controls"><button data-glide-dir="<"
                                class="absolute w-8 h-8 rounded-full bg-gray-500"
                                style="top: calc(50% - 16px); left: 10px;"><svg width="18" height="18"
                                    viewBox="0 0 24 24" style="margin-left: 6px;">
                                    <path
                                        d="M0 12l10.975 11 2.848-2.828-6.176-6.176H24v-3.992H7.646l6.176-6.176L10.975 1 0 12z">
                                    </path>
                                </svg></button><button data-glide-dir=">"
                                class="absolute w-8 h-8 rounded-full bg-gray-500"
                                style="top: calc(50% - 16px); right: 10px;"><svg width="18" height="18"
                                    viewBox="0 0 24 24" style="margin-left: 6px;">
                                    <path
                                        d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z">
                                    </path>
                                </svg></button></div>
                    </div>
                </div>
                <div class="disney-plus-originals px-6 mb-12">
                    <h3 class="text-gray-500 mb-1">Artes & oficios</h3>
                    <div class="glide glide--ltr glide--slider glide--swipeable">
                        <div data-glide-el="track" class="glide__track">
                            <ul class="glide__slides"
                                style="transition: transform 0ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s; width: 1342px; transform: translate3d(0px, 0px, 0px);">
                                <li data-glide-index="0" class="glide__slide glide__slide--active"
                                style="width: 207px; margin-right: 10px;"
                                *ngFor="let item of objListCatArte">
                                    <a routerLink="/home/preview/{{item.code}}/0" class="" title="{{item.name}}">
                                        <img src="{{item.banner1}}" alt="star wars">
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div data-glide-el="controls"><button data-glide-dir="<"
                                class="absolute w-8 h-8 rounded-full bg-gray-500"
                                style="top: calc(50% - 16px); left: 10px;"><svg width="18" height="18"
                                    viewBox="0 0 24 24" style="margin-left: 6px;">
                                    <path
                                        d="M0 12l10.975 11 2.848-2.828-6.176-6.176H24v-3.992H7.646l6.176-6.176L10.975 1 0 12z">
                                    </path>
                                </svg></button><button data-glide-dir=">"
                                class="absolute w-8 h-8 rounded-full bg-gray-500"
                                style="top: calc(50% - 16px); right: 10px;"><svg width="18" height="18"
                                    viewBox="0 0 24 24" style="margin-left: 6px;">
                                    <path
                                        d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z">
                                    </path>
                                </svg></button></div>
                    </div>
                </div>
                <div class="trending-now px-6 mb-12">
                    <h3 class="text-gray-500 mb-1">Mente & cuerpo</h3>
                    <div class="glide glide--ltr glide--slider glide--swipeable">
                        <div data-glide-el="track" class="glide__track">
                            <ul class="glide__slides"
                                style="transition: transform 0ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s; width: 1342px; transform: translate3d(0px, 0px, 0px);">
                                <li data-glide-index="0" class="glide__slide glide__slide--active"
                                style="width: 207px; margin-right: 10px;"
                                *ngFor="let item of objListCatMenteCuerpo">
                                    <a routerLink="/home/preview/{{item.code}}/0" class="" title="{{item.name}}">
                                        <img src="{{item.banner1}}" alt="star wars">
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div data-glide-el="controls"><button data-glide-dir="<"
                                class="absolute w-8 h-8 rounded-full bg-gray-500"
                                style="top: calc(50% - 16px); left: 10px;"><svg width="18" height="18"
                                    viewBox="0 0 24 24" style="margin-left: 6px;">
                                    <path
                                        d="M0 12l10.975 11 2.848-2.828-6.176-6.176H24v-3.992H7.646l6.176-6.176L10.975 1 0 12z">
                                    </path>
                                </svg></button><button data-glide-dir=">"
                                class="absolute w-8 h-8 rounded-full bg-gray-500"
                                style="top: calc(50% - 16px); right: 10px;"><svg width="18" height="18"
                                    viewBox="0 0 24 24" style="margin-left: 6px;">
                                    <path
                                        d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z">
                                    </path>
                                </svg></button></div>
                    </div>
                </div>
            </div>
        </div>


