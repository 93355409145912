import { finalize } from 'rxjs/operators'
import { KuaaResponseDto } from 'src/app/model/dto/kuaa-response/kuaa-response-dto'
import { SerieDto } from 'src/app/model/dto/serie/serie-dto'
import { PodcastService } from 'src/app/service/podcast/podcast.service'
import { SerieService } from 'src/app/service/serie/serie.service'
import SlideContent from 'src/app/shared/types/slide-content'

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { CourseDto } from '../../../model/dto/course/course-dto'
import { TokenService } from '../../../service/authorization/token.service'
import { CourseService } from '../../../service/course/course.service'
import { InstructorService } from '../../../service/instructor/instructor.service'
import { SuscriptorService } from '../../../service/suscriptor/suscriptor.service'

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  fondo: 'https://storage.googleapis.com/publickuaa/static/img/bannermain.jpg';
  objCourseDto: CourseDto = new CourseDto();
  objKuaaTransactionGenericDto: KuaaResponseDto<CourseDto>;
  entityTransfer: CourseDto[] = [];
  modules: any = []
  objListCourses: CourseDto[] = [];
  objListCatAlimens: CourseDto[] = [];
  serie: SerieDto = {} as SerieDto;
  idInstructors: any = []
  idModules: any = []
  instructorsName: any = []
  modulesName: any = []
  types: any = []
  seasonsNumber: any = []
  seasonChapter: any = []
  arrayData: any = []
  arrayVideos: any = []
  idVideos: any = []
  sinopsis: any = []
  public items
  public end = true
  slides: SlideContent[]

  sToken = this.tokenService.getToken();
  serieId: string
  videoBaseCode: string
  public idVideoSerie: string
  public url: string = '/home'
  public trailer
  public allModules: any = []
  public isShowingSeasonsMenu = false
  isLogged = false;
  nombreUsuario = '';
  seasonName = '';
  dropdownOpen = false;
  isTherePrevSlide = false;
  isThereNextSlide = true;


  constructor(
    private activatedRoute: ActivatedRoute,
    private tokenService: TokenService,
    private objCourseService: CourseService,
    private objSerieService: SerieService,
    private podcastService: PodcastService,
    private instructorService: InstructorService,
    private suscriptorService: SuscriptorService
  ) {
    this.serie.banner2 = '/assets/banner_negro.jpg'
    //this.getModules();
    this.loadCourse();
    //this.loadCoursesDefault();
    //this.loadCoursesByCatCAA();
  }

  ngOnInit(): void {
    this.idVideoSerie = this.activatedRoute.snapshot.params.id
    if(this.activatedRoute.snapshot.params.idModule != 0){
        this.url = `/home/category/${this.activatedRoute.snapshot.params.idModule}`
    }else{
      this.url = '/home'
    }
    this.objCourseDto = new CourseDto();
  }

  getModules() {
    this.objCourseService.getModuleList(this.sToken, 'f5557664-8332-45f8-90e1-7e9b3f371272').subscribe(
      data => {
        this.modules.push(data)
      },
      err => {
        console.error(err);
      }
    );
  }

  loadCourse() {
    this.serieId = this.activatedRoute.snapshot.params.id;

    this.objCourseDto.banner1 = this.fondo;
    this.isLogged = true;
    this.nombreUsuario = 'user';
    this.objSerieService.findById(this.serieId)
      .toPromise()
      .then((data) => {
        this.serie = data.entityTransfer[0]
        this.sinopsis = this.serie.sinopsis.split('salto_linea')

        if (this.serie.objListSerieContent) {
          this.serie.objListSerieContent.forEach(element => {
            if (!this.seasonsNumber.includes(element.season)) {
              this.seasonsNumber.push(element.season)
            }
          });
          this.seasonName = this.seasonsNumber[0]
          this.loadSeasonChapter(this.seasonsNumber[0], true)
          this.getInstructorsData(data.entityTransfer[0].autores)
          this.getModules()
        }
      })
      .catch((error) => {
        console.error('Error: ', error)
      })
      .finally(() => {
        this.loadSerieDefault()
      })

    this.objCourseService.findById(this.serieId).subscribe(
      data => {
        this.objKuaaTransactionGenericDto = data;
        if (this.objKuaaTransactionGenericDto.code == '00000') {
          this.entityTransfer = [];
          this.entityTransfer = this.objKuaaTransactionGenericDto.entityTransfer;
          this.objCourseDto = this.entityTransfer[0];
        }
      }
    );

  }

  addToMyList() {
    const formData = new FormData();
    formData.append('opcion', '23d406fa-8ac0-11ec-a8a3-0242ac120002');
    formData.append('id', this.serieId);

    const sToken = this.tokenService.getToken();
    this.suscriptorService.addToMyList(formData, sToken).subscribe(
      data => {
      }
    );
  }

  getItems() {
    return this.serie.objListSerieContent
  }

  getItemsCurrentSeason() {
    return this.arrayData
  }

  loadSeasonChapter(data, firstTime = false, wasClicked = false) {
    if(wasClicked && this.seasonsNumber.length > 1){
      let dropdown = <HTMLInputElement>document.getElementById('dropdown')
      dropdown.disabled = true
    }
    const season = firstTime ? data : data.target.value
    this.arrayData = []

    this.items = this.getItems()
    this.items.forEach((element, i) => {
      if (element.season === season) {
        this.arrayData.push(element)
      }
    })

    if (this.end == true || season != this.seasonName) {
      this.seasonChapter = []
      this.loadData(season, 0)
    }
    this.seasonName = season
  }

  public printMenu() {
    document.getElementById('allSeasons').className = 'all-seasons d-block'
  }

  loadData(season, currentIndex = 0) {
    const item = this.getItemsCurrentSeason()[currentIndex]
    if (item && item.season === season) {
      if (item.contentType === 'podcast') {
        // this.getDataPodcast(item.contentId, currentIndex)
        this.getDataPodcast(item.contentId)
      } else if (item.contentType === 'video') {
        this.getDataVideo(item.contentId, season, currentIndex)
      }
    }
  }

  getDataVideo(id, season, currentIndex = null) {
    this.idVideos.push(id)
    this.objCourseService.findById(id)
      .pipe(
        finalize(() => {
          const nextIndex = currentIndex + 1
          if (this.arrayData.length == nextIndex) {
            this.end = true
            let dropdown = <HTMLInputElement>document.getElementById('dropdown')
            dropdown.disabled = false
          } else {
            this.end = false
          }
          this.loadData(season, nextIndex)
        })
      )
      .subscribe(
        data => {
          const course: Partial<CourseDto & { type: string }> = <CourseDto>(data.entityTransfer[0])
          course.type = 'Video'
          this.arrayVideos.push(course.videoBaseCode)
          if (course.videoBaseCode != null) {
            course.videoBaseCode = course.videoBaseCode.replace('https://storage.cloud.google.com/kuaastreaming/content/', '')
          }
          if (course.title.includes('TRÁILER')) {
            this.trailer = course.videoBaseCode
          }

          if (!this.allModules.includes(course.moduleCode)) {
            this.allModules.push(course.moduleCode)
          }
          course.sinopsis = course.sinopsis.replace('salto_linea', ' ')
          this.seasonChapter.push(course)
          //this.getNoduloName(course.moduleCode)
          this.getForma(course.type)
        },
        err => {
          console.error(err);
        },
      );
  }

  getInstructorsData(instructors) {
    instructors.forEach(instructor => {
      this.instructorService.findById(instructor, this.sToken).subscribe(
        infoInstructor => {
          if (infoInstructor.entityTransfer !== null) {
            this.instructorsName.push( {id: infoInstructor.entityTransfer[0].id, name: `${infoInstructor.entityTransfer[0].firstName} ${infoInstructor.entityTransfer[0].lastName} ${infoInstructor.entityTransfer[0].surname} ${infoInstructor.entityTransfer[0].secondSurname}`} )
          }
        }
      )
    });

  }

  getNoduloName(moduleCode) {
    if (!this.idModules.includes(moduleCode)) {
      this.idModules.push(moduleCode)
      this.modules[0].forEach(element => {
        if (moduleCode === element.id) {
          this.modulesName.push(element.name)
        }
      });
    }
  }

  getForma(type) {
    if (!this.types.includes(type)) {
      this.types.push(type)
    }
  }

  getContextNumberSeasons(numberseasons) {
    return numberseasons > 1 ? 'temporadas' : 'temporada'

  }

  getDataPodcast(id) {
    this.podcastService.getById(id, this.sToken).subscribe(
      data => {
        data.entityTransfer[0].type = 'Podcast'
        this.seasonChapter.push(data.entityTransfer[0])
      },
      err => {
        console.error(err);
      }
    );
  }

  loadCoursesDefault(): void {
    const sToken = this.tokenService.getToken();
    this.objCourseService.getCoursesDefault(sToken).subscribe(
      data => {
        this.objListCourses = data
      },
      err => {
        console.error(err);
      }
    );
  };

  loadCoursesByCatCAA(): void {
    const sToken = this.tokenService.getToken();
    this.objCourseService.getByCategory(sToken, 'f5557664-8332-45f8-90e1-7e9b3f371272').subscribe(
      data => {
        this.objListCatAlimens = data
      },
      err => {
        console.error(err);
      }
    );
  };

  async loadSerieDefault(): Promise<void> {
    try {
      this.slides = []
      const data = await this.objSerieService.findAll().toPromise()
      let coursesList: any[] = []
      data.forEach((element) => {
        if (this.serieId != element.id) {
          element.autores.forEach(async (idInstructor) => {
            const infoInstructor = await this.instructorService.findById(idInstructor).toPromise()

            infoInstructor.entityTransfer.forEach(dataInstructor => {
              this.allModules.forEach(idModule => {
                if (dataInstructor.moduleCode.includes(idModule)) {
                  if (!coursesList.includes(element.id)) {
                    coursesList.push(element.id)
                    this.slides.push({
                      title: element.title,
                      imgSrc: element.banner1,
                      path: ['/home/preview', element.id, '0']
                    })
                  }
                }
              });
            });
          });
        }
      });
    } catch(err) {
        console.error(err);
      }
  }

  toggleDropdown(e: any) {
    this.dropdownOpen = !this.dropdownOpen
  }
}
