import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from "../../shared/services/auth.service";
import { Observable } from 'rxjs';
import { TokenService } from '../../service/authorization/token.service';
//import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  
  realRol: string; 

  constructor(
    public authService: AuthService,
    public router: Router,
    private tokenService: TokenService,

  ){ }

  /*
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(this.authService.isLoggedIn !== true) {
      this.router.navigate(['sign-in'])
    }
    */
   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
   //const expectedRol = route.data.expectedRol;
   const expectedRol = this.tokenService.getExpectedRol();
    const roles = this.tokenService.getAuthorities();
    this.realRol = 'sts';
    roles.forEach(rol => {
      if (rol === 'ROLE_ADMIN') {
        this.realRol = 'admin';
      }
    });
 
     const valid =  this.tokenService.isValidToken();
    //if (!this.tokenService.getToken() || expectedRol.indexOf(this.realRol) === -1) {
      if (!valid || expectedRol.indexOf(this.realRol) === -1) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }

}