import { TokenService } from 'src/app/service/authorization/token.service'
import CreditCard from 'src/app/shared/types/credit-card'
import Swal from 'sweetalert2'

import { Component, OnInit } from '@angular/core'

import { CreditCardsService } from '../../../service/payments/credit-cards.service'

@Component({
  selector: 'credit-cards',
  templateUrl: './credit-cards.component.html',
  styleUrls: ['./credit-cards.component.css']
})
export class CreditCardsComponent implements OnInit {

  isLoading = true;
  openNewForm = false;
  creditCards: CreditCard[] = [];

  constructor(
    private creditCardsService: CreditCardsService,
    private tokenService: TokenService
  ) { }

  ngOnInit(): void {
    this.getCreditCards();
  }

  getCreditCards() {
    this.isLoading = true;
    this.creditCardsService.getAll().subscribe(
      creditCards => {
        this.creditCards = creditCards;
      },
      error => {
        console.error("Error getting user's credit cards: ", error);
      },
      () => {
        this.isLoading = false;
      }
    )
  }

  addCreditCard() {
    this.openNewForm = true;
  }

  onCloseNewForm(): void {
    this.openNewForm = false;
  }

  refreshList() {
    this.creditCards = [];
    this.getCreditCards();
  }

  onCreate() {
    this.onCloseNewForm();
    this.refreshList();
  }

  trackByFn(index: number, item: CreditCard) {
    return item.id || index
  }

  deleteCreditCard(creditCardId: CreditCard['id']) {
    Swal.fire({
      title: '¡Hey!',
      text: '¿Estás seguro de eliminar la tarjeta?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        this.isLoading = true;
        Swal.fire('Por favor espere...', '', 'info');
        this.creditCardsService.delete(creditCardId)
          .subscribe((response) => {
            Swal.fire('La tarjeta ha sido eliminada', '', 'success');
            /*setTimeout(() => {
              Swal.close();
            }, 2000);*/
            this.refreshList();
          }, (error: { error: { error?: string, description: string } }) => {
            console.error('Error deleting credit card: ', error);
            Swal.fire('Error eliminando tarjeta: ', error.error.description, 'error');
            this.isLoading = false;
          })
      }
    })
  }

  selectCreditCard(creditCardId: CreditCard['id']) {
    this.isLoading = true;
    Swal.fire('Por favor espere...', '', 'info');
    this.creditCardsService.select(creditCardId)
      .subscribe((response) => {
        Swal.fire('Tarjeta activada', '', 'success');
        setTimeout(() => {
          Swal.close();
        }, 2000);
        this.refreshList();
      }, (error: { error: { error?: string, description: string } }) => {
        console.error('Error activating credit card: ', error);
        Swal.fire('Error activando tarjeta: ', error.error.description, 'error');
        this.isLoading = false;
      })
  }
}
