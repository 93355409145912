<div class="row" >
    <div class="col-12 justify-content-start  ">
        <h1 class="sections_title" id="exploByModules"><b>Navega por</b> módulos</h1>
    </div>
</div>
<div class="row" >
    <div class="col-12 col-md-6 col-lg-4 col-xl-3 mt-3"
        *ngFor="let module of objListModules; let i = index;">
        <a href="/home/category/{{module.id}}">
            <div *ngIf="module" class="d-flex justify-content-center px-5 px-md-0">
                <div style="background-image: url('{{module.banner1}}');" class="d-flex  justify-content-center align-items-center image-modules" (mouseover)="showHideVideo(i, 'show')" (mouseout)="showHideVideo(i, 'hide')" >
                    <div style="background-image: url('{{module.banner3}}'); background-size: cover; background-position: center center; background-repeat: no-repeat; height: 100% !important;" class="video-image flex-grow-1" id="video_image_{{i}}"></div>
                    <img src="{{module.banner2}}" alt="{{module.name}}" style="width: 10rem; height: 10rem;" />
                </div>
                
            </div>
        </a>
    </div>
</div>               