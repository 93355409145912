import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { TokenService } from '../../../service/authorization/token.service';
import { map, switchMap, debounceTime, catchError } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CategoryDto } from '../../../model/dto/category/category-dto';
import { Helperutil } from '../../../common/helper/helperutil';
import { ToastrService } from 'ngx-toastr';
import { CourseService } from '../../../service/course/course.service';
import { CategoryService } from '../../../service/category/category.service';
import { KuaaTransactionDto } from '../../../common/trx/kuaa-transaction-dto';
import { ResponseImageDTO } from 'src/app/common/trx/response-image-dto';



@Component({
  selector: 'app-categoryadd',
  templateUrl: './categoryadd.component.html',
  styleUrls: ['./categoryadd.component.css']
})
export class CategoryaddComponent implements OnInit {

  isSaveProcess: boolean = false;
  objCategoryDto: CategoryDto= new CategoryDto();
  name = '';
  file: File;
  fileBanner2: File;
  completed: boolean = false;
  uploadPercent: Observable<number>;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadState: Observable<string>;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;
  uploads: any[];
  idClient: string;
  entityTransfer: ResponseImageDTO[] = []; 
  objKuaaTransactionDto: KuaaTransactionDto = new KuaaTransactionDto();


  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    private toastr: ToastrService,
    //private objCourseService: CourseService,
    private objCategoryService: CategoryService,
    private tokenService: TokenService,
    private storage: AngularFirestore,
    private store: AngularFireStorage
  ) {

  }

  ngOnInit(): void {
    this.idClient  = Math.random().toString(36).substring(2);
    this.uploads = [];
  }

  onFileSelect(event, bannerDestine) {
    if (event.target.files.length > 0) { 
     const uploadTrack = { file: event.target.files[0],
                            subpath: bannerDestine
                         };
      this.uploads.push(uploadTrack);
 
    }
  }

  save(){
    this.toastr.info('Process...', Helperutil.S_TOAS_TITLE_HEAD, {
      timeOut: Helperutil.I_TOAS_TIMEOUT, positionClass: Helperutil.S_TOAS_POSITIONCLASS_TOP_RIGHT
    });
    const sToken = this.tokenService.getToken();
    this.isSaveProcess = true;
    this.uploadImages(sToken).then(data1 => {
      this.processSave(sToken);
    });
  }

  async uploadImages(sToken) {
    return new Promise(resolve => {
      let iCount =0;
      for (const file of this.uploads) {
        const http$ = this.objCategoryService.uploadFile(file.file, sToken,this.idClient+file.subpath );
        http$.pipe(
          map(el => {
            this.toastr.clear();
            this.objKuaaTransactionDto = el;
            if (this.objKuaaTransactionDto.code == '00000') {
              this.entityTransfer = [];
              this.entityTransfer =  this.objKuaaTransactionDto.entityTransfer;
              this.entityTransfer[0];
              let sFileUrl = this.entityTransfer[0].sFileUrl;
              iCount = iCount + 1;
              if( file.subpath == 'b1' ){
                this.objCategoryDto.banner1 = sFileUrl;
              }else if( file.subpath == 'b2'){
                this.objCategoryDto.banner2 = sFileUrl;
              }else if( file.subpath == 'b3'){
                this.objCategoryDto.banner3 = sFileUrl;
              }else {
                this.objCategoryDto.banner3 = sFileUrl;
              }
              if( iCount == 3 ){
                iCount =0;
                resolve( this.entityTransfer ); 
              }              
            }
            return el;
          })).subscribe(
            el => {

            },err => {
              this.toastr.clear();
              if (err.error.error == Helperutil.S_SESSION_NOT_AUTORIZED) {
                this.toastr.clear();
                this.toastr.error(Helperutil.S_SESSION_ENDED, Helperutil.S_TOAS_TITLE_HEAD, {
                  timeOut: 3000, positionClass: Helperutil.S_TOAS_POSITIONCLASS_BTN_CENTER
                });
                this.isSaveProcess = false;
              } else {
                this.toastr.warning(err.error.mensaje, Helperutil.S_TOAS_TITLE_HEAD, {
                  timeOut: Helperutil.I_TOAS_TIMEOUT, positionClass: Helperutil.S_TOAS_POSITIONCLASS_BTN_RIGHT
                });
              }
            },
          );
      }
    });
  }


  processSave(sToken){    
    this.objCategoryDto.idClient =  this.idClient;
    const http$ = this.objCategoryService.save(this.objCategoryDto, sToken);
    http$.pipe(
      map(el => {
        this.toastr.clear();
        this.objKuaaTransactionDto = el;
        if (this.objKuaaTransactionDto.code == '00000') {
          this.toastr.success(this.objKuaaTransactionDto.mensaje, Helperutil.S_TOAS_TITLE_HEAD, {
            timeOut: Helperutil.I_TOAS_TIMEOUT, positionClass: Helperutil.S_TOAS_POSITIONCLASS_TOP_RIGHT
          });         
          setTimeout(() => {
            this.isSaveProcess = false;
            this.router.navigate(['/dashboard/categorylist']);
          }, 1000);
        }
        return el;
      })).subscribe(
        err => {
          this.toastr.clear();
          if (err.error.error == Helperutil.S_SESSION_NOT_AUTORIZED) {
            this.toastr.clear();
            this.toastr.error(Helperutil.S_SESSION_ENDED, Helperutil.S_TOAS_TITLE_HEAD, {
              timeOut: 3000, positionClass: Helperutil.S_TOAS_POSITIONCLASS_BTN_CENTER
            });
            this.isSaveProcess = false;
          } else {
            this.toastr.warning(err.error.mensaje, Helperutil.S_TOAS_TITLE_HEAD, {
              timeOut: Helperutil.I_TOAS_TIMEOUT, positionClass: Helperutil.S_TOAS_POSITIONCLASS_BTN_RIGHT
            });
          }
        },
      );
  }


}
