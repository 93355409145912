 <!-- Main content -->
 <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
    <div class="inner-adjust">
  
      <div class="pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Configuracion usuario: </h1>
      </div>
      <!-- Show user data when logged in -->
      <div class="row" *ngIf="authService.userData as user">
        <div class="col-md-12">
            <div class="container">
                <div class="row"> 

                    <img class="align-self-start mr-5 img-thumbnail rounded-circle" src="{{(user.photoURL) ? user.photoURL : '/assets/dummy-user.png'}}"
                    alt="{{user.displayName}}">
                    
                  <div class="media-body">
                    <h1>Hello: <strong>{{(user.displayName) ? user.displayName : 'User'}}</strong></h1>
                    <p>User ID: <strong>{{user.uid}}</strong></p>
                    <p>Email: <strong>{{user.email}}</strong></p>
                    <p>Email Verified: <strong>{{user.emailVerified}}</strong></p>

                    <div class="col-md-12">
                        <p> Nombre:
                            <input class="form-control form-control-lg" type="text" 
                            placeholder=".form-control-lg" aria-label=".form-control-lg example"
                            value="{{(user.displayName) ? user.displayName : 'User'}}"
                            >
                         </p>
                    </div>
                    <div class="col-md-12">
                     <p> Apellidos:
                        <input class="form-control form-control-lg" type="text" 
                        placeholder=".form-control-lg" aria-label=".form-control-lg example"
                        value="{{(user.displayName) ? user.displayName : 'User'}}">
                     </p>   
                    </div>
                    <div class="col-md-12">
                     <p> Telefono:
                        <input class="form-control form-control-lg" type="text" 
                        placeholder=".form-control-lg" aria-label=".form-control-lg example"
                        value="{{(user.displayName) ? user.displayName : 'User'}}">
                     </p>
                    </div>
                     
                    <p>Token: <small>{{user.idToken}}</small></p>
                     
                    <div class="col-md-12"></div>
                    <div class="col-md-12"> <hr> </div>
                    <div class="col-md-12">
                        <div class="row" >
                            <div class="col-md-2"></div>
                            <div class="col-md-4 d-grid gap-2 col-6 mx-auto">
                                <button  type="button" class="btn btn-primary btn-lg "  >
                                    <span  >Guardar</span>  
                                </button> 
                            </div> 
                            <div class="col-md-4 d-grid gap-2 col-6 mx-auto">
                                    <a href="/dashboard" class="btn btn-secondary btn-lg">Cancelar</a>
                            </div>
                            <div class="col-md-2"></div>
                        </div>    
                    </div>



                  </div>

                </div>
              </div> 
        </div>
      </div>
  
    </div>
  </main>