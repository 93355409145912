import { Component, OnInit, Input } from '@angular/core';
import { TokenService } from '../../../service/authorization/token.service';
import { CourseService } from '../../../service/course/course.service';

@Component({
  selector: 'app-related-content',
  templateUrl: './related-content.component.html',
  styleUrls: ['./related-content.component.css']
})
export class RelatedContentComponent implements OnInit {

  @Input()
  idModules: string;
  objListCourses: any[] = [];
  relatedCourses: any[] = [];
  sToken = this.tokenService.getToken()

  constructor(
    private tokenService: TokenService,
    private objCourseService: CourseService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      let modules = <HTMLInputElement>document.getElementById('idModules')
      this.getCoursesBySerie(modules.value)
    }, 1000);
    //this.loadCoursesDefault()
  }

  loadCoursesDefault(): void {
    this.objCourseService.getCoursesDefault(this.sToken).subscribe(
      data => {
        this.objListCourses = data
      },
      err => {
        console.error(err);
      }
    );
  };


  public getCoursesBySerie(id) {
    this.objCourseService.getCoursesBySerie(this.sToken, id).subscribe(
      data => {
        this.relatedCourses = data['entityTransfer']
        this.relatedCourses.forEach(element => {
          element.sinopsisCut = `${element.sinopsis.substr(0,150)} ...`
        });
      },
      err => {
        console.error(err);
      }
    );
  }

  public showSinopsis(id){
    document.getElementById(`sinopsis_${id}`).style.display = 'block'
    document.getElementById(`play_${id}`).style.display = 'block'
  }

  public hideSinopsis(id){
    document.getElementById(`sinopsis_${id}`).style.display = 'none'
    document.getElementById(`play_${id}`).style.display = 'none'
  }

}
