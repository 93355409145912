import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PlayVideosService {

  constructor(
    private router: Router
  ) { }

  playVideos(idReturn, videoBaseCode = '', bandera) {
    videoBaseCode = videoBaseCode.replace('https://storage.cloud.google.com/kuaastreaming/content/', '')
    const cutVideo = videoBaseCode.split('/')
    this.router.navigate([`/home/play-video/${idReturn}${bandera}namVi=${cutVideo.toString()}`])
  }
}
