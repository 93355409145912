import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "../../../shared/services/auth.service";
import { TokenService } from '../../../service/authorization/token.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  documentdata = '';

  constructor(
    public authService: AuthService,
    public router: Router,
    private tokenService: TokenService,
  ) {
    /*
    const roles = this.tokenService.getAuthorities();
    if (this.tokenService.getToken() ) {
      this.router.navigate(['/home']);
    }
    */

    //this.documentdata =  "Height: "+window.screen.height +" Width: "+window.screen.width;
    this.documentdata = " Width: "+window.innerWidth + "  Height: "+window.innerHeight ;



   }

  ngOnInit(): void {
  }

}
