 <!-- Main content -->
 <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
    <div class="inner-adjust">
  
      <div class="pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Categorias: </h1>
      </div>
      <!-- Show user data when logged in -->
      <div class="row" *ngIf="authService.userData as user">
        <div class="col-md-12">
            <div class="container">
                <div class="row">
                  <div class="col-3" *ngFor="let item of objListCategories">
                    <img src="{{item.banner1}}" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h5 class="card-title">{{item.name}}</h5>
                      <p class="card-text">{{item.subtitle}}</p>
                      <a href="#" class="btn btn-primary">Ver...</a>
                    </div>
                  </div> 
                </div>
              </div> 
        </div>
      </div>
  
    </div>
  </main>