<app-slider
  *ngIf="slides"
  [breakpoints]="breakpoints"
  [loop]="true"
  [navigation]="true"
  [spaceBetween]="20"
  [extraClasses]="'mt-5'"
  [slides]="slides"
>
</app-slider>

<app-slider
  *ngIf="slidesOrdered"
  [breakpoints]="breakpoints"
  [loop]="true"
  [navigation]="true"
  [spaceBetween]="20"
  [extraClasses]="'mt-5'"
  [slides]="slidesOrdered"
>
</app-slider>
