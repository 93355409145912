<h2 class="mb-3">Mis Pagos</h2>

<div class="table-responsive">
  <table class="table table-hover">
    <thead>
      <tr>
        <th>#</th>
        <th>Descripción</th>
        <th>Medio de Pago</th>
        <th>Fecha</th>
        <th>Estado</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let payment of payments; let i = index" [id]="payment.id">
        <td>{{(i+1)}}.</td>
        <td>{{payment.description}}</td>
        <td>{{payment.creditCardMaskedNumber}}</td>
        <td>{{payment.createdAt._seconds ? (payment.createdAt._seconds | dateFromSeconds) : ''}}</td>
        <td>
          <payment-status [status]="payment.status" [error]="payment.transactionError"></payment-status>
        </td>
      </tr>
    </tbody>
  </table>
</div>
