import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";


@Component({
  selector: 'app-policyhabeasdata',
  templateUrl: './policyhabeasdata.component.html',
  styleUrls: ['./policyhabeasdata.component.css']
})
export class PolicyhabeasdataComponent implements OnInit {

  constructor(  public router: Router,) { }

  ngOnInit(): void {
  }

  goToLogin(){
    this.router.navigate(['/login']);
  }

}
