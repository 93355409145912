import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../../service/authorization/token.service';
import { Router } from "@angular/router";
import { AuthService } from "../../../shared/services/auth.service";
import { UserService } from 'src/app/service/payments/user.service';
import Swal from 'sweetalert2';

declare function getContens(String): String

@Component({
  selector: 'app-hostelheader',
  templateUrl: './hostelheader.component.html',
  styleUrls: ['./hostelheader.component.scss']
})
export class HostelheaderComponent implements OnInit {

  public searchCriteria = ''

  constructor(
    public authService: AuthService,
    public router: Router,
    private tokenService: TokenService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.getSuscriptor()
  }

  onLogOut(): void {
    this.tokenService.logOut();
    window.location.reload();
    this.router.navigate(['/']);
  }

  search(event) {
    this.tokenService.getToken()
    this.searchCriteria += event.key

    if (this.searchCriteria.length >= 3) {
      Swal.fire({
        html: `<div style="height: 90vh;" >
                  <div style="display: flex; background: transparent; border-radius: 30px;
                  border: 2px solid rgb(40, 156, 202); margin-top: 25px; width: 100%;" >
                    <input class="search-input-kuaa" type="text" onkeyup="searchContens()" id="inputSearch" placeholder="Buscar" >
                    <img id="iconsearch" src="/assets/search_icon.png">
                  </div>
                  <div class="container mt-5 d-flex flex-row flex-wrap" id="foundContents" ></div>
              </div>`,
        width: '95%',
        heightAuto: true,
        background: '#000000b0',
        showCloseButton: true,
        showConfirmButton: false
      })
    }
    getContens(this.tokenService.getToken())
  }

  private getSuscriptor(): void {
    this.userService.get().subscribe(
      data => {
        let mainImage = <HTMLImageElement>document.getElementById('main-image')
        document.getElementById('icon-main-user').style.display = 'none'
        document.getElementById('main-image').style.display = 'block'
        mainImage.src = data['photoURL'] != null ? data['photoURL'] : '/assets/img-profile.png'
      }
    )
  }

  showHideMenu () {
    const class_menu = document.getElementById('main-menu-kuaa')
    class_menu.classList.toggle('open')
  }
}
