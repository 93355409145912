import {
    AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output,
    SimpleChanges, ViewChild
} from '@angular/core'
import { SafeUrl } from '@angular/platform-browser'

type videoType = 'video/mp4'

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('video', { read: ElementRef }) video: ElementRef;
  @Input() title: string
  @Input() type: videoType = 'video/mp4'
  @Input() source: string | SafeUrl
  @Input() backUrl: any[] = []

  // Navigation Controls
  @Input() enablePrev = false
  @Input() enableNext = false
  @Output() prev = new EventEmitter()
  @Output() next = new EventEmitter()

  // Media Controls
  currentTimeInSeconds = 0
  durationInSeconds = 0
  isLoading = true
  showControls = false
  mouseChecker = null
  progressChecker = null
  progressBarBgColor: string = ''
  progress: number = 0
  paused = true
  volumeBarBgColor: string = ''
  volume: number = 100
  muted = false

  // Custom Events
  @Output() ended = new EventEmitter()
  @Output() error = new EventEmitter()

  constructor() { }

  ngAfterViewInit() {
    if (this.video && this.video.nativeElement) {
      this.setVideoControls()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.source && changes.source.previousValue && changes.source.currentValue) {
      this.isLoading = true
      this.video.nativeElement.load()
    }
  }

  ngOnDestroy(): void {
    if (this.mouseChecker) {
      clearInterval(this.mouseChecker)
    }
    this.inactiveProgressChecker()
  }

  setVideoControls () {
    const video = <HTMLVideoElement> this.video.nativeElement
    this.paused = video.paused
    this.volume = video.volume * 100
    if (!this.paused) {
      this.activeProgressChecker(video)
    }
  }

  activeProgressChecker (video) {
    this.inactiveProgressChecker()
    this.progressChecker = setInterval(() => {
      this.currentTimeInSeconds = video.currentTime
      this.progress = video.duration ? Math.floor(video.currentTime * 100 / video.duration) : 0
      this.updateProgressBar(this.progress)
    }, 200)
  }

  inactiveProgressChecker () {
    if (this.progressChecker) {
      clearInterval(this.progressChecker)
    }
  }

  prevVideo (event) {
    if (this.prev && this.prev.emit) {
      this.source = null
      this.prev.emit(event)
    }
  }

  nextVideo (event) {
    if (this.next && this.next.emit) {
      this.source = null
      this.next.emit(event)
    }
  }

  mouseMove() {
    this.showControls = true
    if (this.mouseChecker) {
      clearInterval(this.mouseChecker)
    }
    this.mouseChecker = setTimeout(() => {
      this.showControls = false
      clearInterval(this.mouseChecker)
    }, 2000)
  }

  updateProgressBar (percentage = 0) {
    this.progressBarBgColor = `linear-gradient(to right, var(--bs-primary) 0%, var(--bs-primary) ${percentage}%, #fff ${percentage}%, #fff 100%)`
  }

  activeFullscreen() {
    const videoPlayer = document.querySelector('.video-player')
    if (videoPlayer) {
      if (document.fullscreenElement) {
        document.exitFullscreen()
      } else if (videoPlayer.requestFullscreen) {
        videoPlayer.requestFullscreen()
      }
    }
  }

  pause() {
    const video = <HTMLVideoElement> this.video.nativeElement
    video.pause()
  }

  play() {
    const video = <HTMLVideoElement> this.video.nativeElement
    video.play()
  }

  muteOrUnmute() {
    const video = <HTMLVideoElement> this.video.nativeElement
    this.muted = !video.muted
    video.muted = this.muted
  }

  playOrPause() {
    if (this.paused) {
      this.play()
    } else {
      this.pause()
    }
  }

  changeVolume() {
    const video = <HTMLVideoElement> this.video.nativeElement
    video.volume = this.volume / 100
  }

  selectStreamPart() {
    this.pause()
    const video = <HTMLVideoElement> this.video.nativeElement
    video.currentTime = video.duration * (this.progress / 100)
    this.play()
  }

  videoEnded (event) {
    if (this.ended && this.ended.emit) {
      this.ended.emit(event)
    }
  }

  videoError (event) {
    console.error('Video Player Error: ', event)
    this.isLoading = false
    if (this.error && this.error.emit) {
      this.error.emit(event)
    }
  }

  videoPause() {
    this.paused = true
    this.inactiveProgressChecker()
  }

  videoPlay() {
    this.paused = false
    this.activeProgressChecker(this.video.nativeElement)
  }

  videoCanplaythrough() {
    this.durationInSeconds = this.video.nativeElement.duration
    this.isLoading = false
  }
}
