

 <div class="div container-fluid header_background">
    <div class="container">
      <div class="header">
        <div class="container_100">
          <div class="col justify-content-start align-middle" id="branding">
            <h2 class="logo align-middle">
              <a href="https://www.kuaa.co/">
              <img src="/assets/logos/MARCA-KUAÁ-tag-line.png" class="desktop logo_width img-fluid" />
              <img src="/assets/logos/mobile_slogan.png" class="mobile logo_width_mobile img-fluid" />
            </a>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>