import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Helperutil } from '../../common/helper/helperutil'
import { UserService } from './user.service'

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlansService {

  constructor(
    private httpClient: HttpClient,
    private userService: UserService
  ) { }

  public getAll(){
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_API_PAYMENT_SUBCRIPTION_PLANS_LIST, this.userService.createHeader());
  }

  public getById(id){
    return this.httpClient.get<any>(`${Helperutil.S_CORE_SERVICE_API_PAYMENT_SUBCRIPTION_PLANS_LIST}/${id}`, this.userService.createHeader() );
  }




}
