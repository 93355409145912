import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.css']
})
export class PaymentListComponent implements OnInit {
  @Input() payments = [];

  constructor() { }

  ngOnInit(): void { }
}
