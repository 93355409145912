<div *ngIf="userSubscription">
  <div class="row">
    <div class="col-12 col-md-6 mb-3">
      <strong>Mi plan actual: </strong> <span>{{expired ? 'Sin plan' : userSubscription.activePlanName}}</span>
    </div>
    <div class="col-12 col-md-6 mb-3" *ngIf="expireAt">
      <strong>Fecha de Expiración: </strong> <span [class]="expired && expireAt ? 'text-danger' : ''">{{expireAt}}</span>
    </div>
  </div>

  <div class="row">
    <div class="col-12 mb-4">
      <div class="alert alert-warning" *ngIf="!expired && !userSubscription.autoRenewal">
        <h3>Los <b>pagos automáticos</b> se encuentran <b>desactivados</b></h3>

        <hr/>

        <div>
          <h6>Para que no pierdas el acceso. Por favor:</h6>

          <ol>
            <li class="mb-3">Revisa los datos de tu tarjeta de crédito (agrega otra de ser necesario)</li>
            <li class="mb-3">
              Revisa que la tarjeta dice "Activa"
              <img src="assets/active-credit-card.png" alt="" class="img-fluid" />
            </li>
            <li>Cuando todo esté listo, da click en el siguiente botón <app-button variant="warning" (click)="enableAutoRenewal()" [loading]="saving">Activar Débito Automático</app-button></li>
          </ol>
        </div>
      </div>

      <div class="alert alert-primary" *ngIf="expired">
        <h3><b>Actualiza</b> tu <b>Plan</b></h3>

        <hr/>

        <div>
          <h6>Para que puedas acceder a todo el contenido de la plataforma te invitamos a que actualices tu plan.</h6>

          <form class="needs-validation" novalidate [formGroup]="formSubscription" (ngSubmit)="subscribe($event)">
            <ol>
              <li class="mb-3">Revisa que tengas al menos una tarjeta de crédito activa.</li>
              <li class="mb-3">
                <div class="row">
                  <div class="d-flex align-items-center flex-wrap">
                    <label for="subscriptionPlanId" class="form-label me-2">Selecciona el plan de tu preferencia</label>
                    <select class="form-select w-auto" id="subscriptionPlanId" formControlName="subscriptionPlanId" required>
                      <option></option>
                      <option *ngFor="let plan of plans" [value]="plan.id">{{plan.name}} - {{plan.value | currency}} {{plan.currency}}</option>
                    </select>
                  </div>
                </div>
              </li>
              <li class="mb-3">Cuando todo esté listo, da click en el siguiente botón <app-button type="submit" variant="primary">Actualizar plan</app-button></li>
            </ol>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<modal [show]="openNewForm" title="Nueva Tarjeta de Crédito" (close)="onCloseNewForm()">
  <credit-card-form (create)="onCreate()"></credit-card-form>
</modal>

<modal [show]="openCrediCardActive" title="Tarjeta de crédito activa" (close)="onCloseCreditCardActive()">
  <show-credit-card-active></show-credit-card-active>
</modal>
