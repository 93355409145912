<div class="modules-login position-relative" style="background-color: black;">
  <img src="assets/inicioSesion/IMAGEN-DE-FONDO.png" class="w-100 background-image h-auto" alt="Encuentra balance y disfruta la vida con Kuaá">
  <img src="assets/inicioSesion/IMAGEN-DE-FONDO-MOBILE.jpg" class="w-100 background-image-mobile h-auto" alt="Encuentra balance y disfruta la vida con Kuaá">
  <div class="d-flex justify-content-center position-absolute" style="top: 8vh; left: 0; right: 0;">
    <div class="container">
      <div class="row justify-content-start">
        <div class="col-lg-7 col-md-7 d-none d-sm-block justify-content-center alinear_centro mt-10">
          <div class="pt-5">
            <hr class="linea_azul" style="width: 50%; float: right;">
            <p class="register_login_main_title">¿Olvidaste tu contraseña? ¡Restablécela! </p>
          </div>
          <hr class="linea_azul">
        </div>
        <div class="col-lg-5 col-md-5 col-sm-12 pb-5 mt-10">
          <div class="register_login_form">
            <fieldset>

              <legend class="register_login_form_main_title">Recupera tu contraseña</legend>

              <div class="form-group pt-3 pb-3">
                <div class="col-12">
                  <input #passwordResetEmail required type="email" placeholder="Correo electrónico"
                    class="form-control input-md input_login">
                </div>
              </div>

              <div class="form-group pt-3">
                <div class="col-12">
                  <button class="btn btn-primary btn_poopins_black" style="letter-spacing: 1px;"
                    (click)="authService.ForgotPassword(passwordResetEmail.value)">CAMBIAR CONTRASEÑA</button>
                </div>
              </div>

              <div class="form-group pt-3 pb-3 row">
                <div class="col-12">
                  <label routerLink="/login" style="cursor: pointer;" class="control-label"
                    for="textinput">Volver</label>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
