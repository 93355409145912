import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Helperutil } from '../../common/helper/helperutil'
import { UserService } from './user.service'

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private httpClient: HttpClient,
    private userService: UserService
  ) { }

  public create(paymentData){
    return this.httpClient.post<any>(Helperutil.S_CORE_SERVICE_PAYMENT_PAYMENTS, paymentData, this.userService.createHeader());
  }

  public getAll(){
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_PAYMENT_PAYMENTS, this.userService.createHeader());
  }

  public getByID(idCreditCard){
    return this.httpClient.get<any>(`${Helperutil.S_CORE_SERVICE_PAYMENT_PAYMENTS}${idCreditCard}`, this.userService.createHeader());
  }
}
