//import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs'
import { KuaaResponseDto } from 'src/app/model/dto/kuaa-response/kuaa-response-dto'
import { __param } from 'tslib'

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

import { Helperutil } from '../../common/helper/helperutil'
import { InstructorDto } from '../../model/dto/instructor/instructor-dto'

@Injectable({
  providedIn: 'root'
})
export class InstructorService {

  constructor(private httpClient: HttpClient,private sanitizer: DomSanitizer) { }

  private getHttpSettings() {
    const httpSettings = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return httpSettings;
  }

  public getList(sToken, code: string): Observable<any[]> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.get<any[]>(Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR_LIST, httpSettings);
  }

  public findById(id: string, sToken = ''): Observable<KuaaResponseDto<InstructorDto>> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.get<KuaaResponseDto<InstructorDto>>(Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR_FINDBYID + `/${id}`, httpSettings );
  }

  public listByStatusOrderByOrderSequence(sToken = ''): Observable<InstructorDto[]> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.get<InstructorDto[]>(Helperutil.S_CORE_SERVICE_API_V1_INSTRUCTOR_LIST_BY_SEQUENCE, httpSettings);
  }

  public getCoursesByInstructor(sToken, id): Observable<any> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.get<InstructorDto[]>(Helperutil.S_CORE_SERVICE_API_V1_SERIE_FINDBYINSTRUCTOR + `/${id}`, httpSettings);
  }

  public getAll(): Observable<any> {
    const httpSettings = this.getHttpSettings();
    return this.httpClient.get<any>(Helperutil.S_CORE_SERVICE_GET_ALL_PUBLIC_INSTRUCTORS, httpSettings)
  }

}
