import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/service/payments/user.service';
import { SubscriptionPlansService } from 'src/app/service/payments/subscription-plans.service';
import { CreditCardsService } from 'src/app/service/payments/credit-cards.service';
import { CountriesService } from 'src/app/service/payments/countries.service';
import { PaymentsService } from 'src/app/service/payments/payments.service';
import { TokenService } from '../../../../service/authorization/token.service';
import Swal from 'sweetalert2';
var md5 = require('md5');

@Component({
  selector: 'app-plans-payment',
  templateUrl: './plans-payment.component.html',
  styleUrls: ['./plans-payment.component.css', '../register/bootstrap.css']
})
export class PlansPaymentComponent implements OnInit {

  public subscriptionPlans = []
  public countries = []
  public years = []
  public months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  public states = ['Bogotá D.C.', 'Antioquia', 'Boyacá', 'Córdoba', 'Chocó', 'Nariño', 'Santander', 'Meta', 'Atlántico', 'Bolívar', 'Caldas', 'Caquetá', 'Cauca', 'Cesar', 'Cundinamarca', 'Huila', 'La Guajira', 'Magdalena', 'Quindío', 'Risaralda', 'Sucre', 'Tolima', 'Arauca', 'Casanare', 'Putumayo', 'Amazonas', 'Guainía', 'Vaupés', 'Vichada', 'Guaviare', 'Archipiélago de San Andrés, Providencia y Santa Catalina', 'Norte de Santander', 'Valle del Cauca']
  private deviceSessionId

  constructor(
    private userService: UserService,
    private subscriptionPlansService: SubscriptionPlansService,
    private creditCardsService: CreditCardsService,
    private tokenService: TokenService,
    private countriesService: CountriesService,
    private paymentsService: PaymentsService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.createDeviceSessionId()
    this.createArrayYears()
    this.getSubscriptionPlans()
    this.getCountries()

  }

  formRegister = new FormGroup({
    plan: new FormControl('', [
      Validators.required]),
    card_name: new FormControl('', [Validators.required]),
    card_number: new FormControl('', [Validators.required, Validators.minLength(14), Validators.maxLength(19)]),
    //expiration_date: new FormControl('', [Validators.required]),
    year: new FormControl('', [Validators.required]),
    month: new FormControl('', [Validators.required]),
    security_code: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.min(3), Validators.min(4)]),
    address: new FormControl('', [Validators.required]),
    postalCode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    //type_identification: new FormControl('', [Validators.required]),
    dniNumber: new FormControl('', [Validators.required])
  })

  private createDeviceSessionId() {
    const ct = new Date()
    this.deviceSessionId = md5(`${this.tokenService.getUserID()}${ct.toISOString()}`)
    const script = document.createElement('script')
    script.src = `https://maf.pagosonline.net/ws/fp/tags.js?id=${this.deviceSessionId}80200`
    script.async = true
    document.body.appendChild(script)
  }

  private createArrayYears() {
    const cy = new Date().getFullYear()
    for (let i = cy; i <= cy + 20; i++) {
      this.years.push(i)
    }
  }

  private getSubscriptionPlans() {
    this.subscriptionPlansService.getAll().subscribe(
      data => {
        data.forEach(element => {
          if (element.status == 1) {
            const formatterPeso = new Intl.NumberFormat('es-CO', {
              style: 'currency',
              currency: 'COP',
              minimumFractionDigits: 0
            })
            element.value = formatterPeso.format(element.value)
            this.subscriptionPlans.push(element)
          }
        });
      },
      error => {
        console.error(error)
      }
    )
  }

  private getCountries() {
    this.countriesService.getAll().subscribe(
      data => {
        this.countries = data
      },
      error => {
        console.error(error)
      }
    )
  }

  public converNumberToPesos() {
    const formatterPeso = new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0
    })
  }

  public goToPayment() {
    if (!this.formRegister.valid) {
      const error = '2px red solid'
      const ok = '1px #14b8ec solid'
      for (const property in this.formRegister.controls) {
        const field = property == 'plan' ? 'planSelected' : property
        this.formRegister.controls[property].errors === null ? document.getElementById(field).style.border = ok : document.getElementById(field).style.border = error
      }
    } else {
      document.getElementById('loader-kuaa').style.zIndex = '5'
      this.updateUser()
    }
  }

  private updateUser() {

    this.subscriptionPlansService.getById(this.formRegister.value.plan).subscribe(
      result => {
        const newUserData = {
          acceptedPrivacyPolicy: true,
          acceptedTermsAndConditions: true,
          billingAddress: {
            address: this.formRegister.value.address,
            city: this.formRegister.value.city,
            state: this.formRegister.value.state,
            country: this.formRegister.value.country,
            postalCode: this.formRegister.value.postalCode,
            phone: this.formRegister.value.phone,
          },
          dniNumber: this.formRegister.value.dniNumber,
          autoRenewal: result.autoRenewal,
          activePlanId: this.formRegister.value.plan,
          activePlanName: result.name,
        }
        this.userService.update(newUserData).subscribe(
          () => {
            this.createCreditCard()
          },
          error => {
            console.error(`Error al actualizar el usuario --> ${error}`)
          }
        )
      },
      error => {
        console.error(`Error al obtener los planes --> ${error}`)
      }
    )
  }

  private createCreditCard() {
    const creditCardData = {
      dniNumber: this.formRegister.value.dniNumber,
      number: this.formRegister.value.card_number,
      securityCode: this.formRegister.value.security_code,
      expirationDate: `${this.formRegister.controls['year'].value}/${this.formRegister.controls['month'].value}`,
      name: this.formRegister.value.card_name
    }
    this.creditCardsService.create(creditCardData).subscribe(
      result => {
        this.activeGracePeriod()
      },
      error => {
        console.error('Error --> ', error)
      }
    )
  }

  private activeGracePeriod(){
    this.userService.activeGracePeriod().subscribe(
      result => {
        document.getElementById('loader-kuaa').style.zIndex = '-5'
        this.printMessage('Proceso de inscripción correcto', `<p>Ahora puedes disfrutar del contenido Kuaá.<p>`, 'success', 'Empezar a disfrutar')
      },
      error => {
        console.error('Error en el activeGracePeriod ', error)
      }
    )
  }

  /*private createPayment() {
    const creditCardData = {
      subscriptionPlanId: this.formRegister.value.plan,
      deviceInfo: {
        deviceSessionId: this.deviceSessionId,
        cookie: this.tokenService.getUserID()
      }
    }

    this.paymentsService.create(creditCardData).subscribe(
      result => {
        document.getElementById('loader-kuaa').style.zIndex = '-5'
        if (result['status'] == 'rejected') {
          this.printMessage('Error al registrar la tarjeta', `<p>Se presentó el siguiente error con la tarjeta <b>${result['transactionError']}</b><p>`, 'error', 'Revisar mis tarjetas')
        } else {
          this.printMessage('Proceso de inscripción correcto', `<p>Ahora puedes disfrutar del contenido Kuaá.<p>`, 'success', 'Empezar a disfrutar')
        }
      },
      error => {
        console.error('Error -> ', error)
      }
    )
  }*/

  private printMessage(title, html, icon, textButton) {
    Swal.fire({
      title: title,
      html: html,
      icon: icon,
      confirmButtonColor: '#14b8ec',
      confirmButtonText: textButton,
      background: 'black',
      color: '#14b8ec'
    }).then((res) => {
      if (icon == 'error') {
        this.router.navigate(['/home/credit_cards']);
      } else {
        this.router.navigate(['/home']);
      }
    })
  }

}
