import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login-head',
  templateUrl: './login-head.component.html',
  styleUrls: ['./login-head.component.css']
})
export class LoginHeadComponent implements OnInit {

  public href: string = "";
  public isPathLogin: string = "";
  public isPathSignUp: string = "";
  public isPathForgotPswd: string = "";

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.href = this.router.url;

    if(this.href =="/login/signup" ){
      this.isPathSignUp = "signup";
      this.isPathLogin = null;
      this.isPathForgotPswd = null;
    }else if(this.href == "/login/forgot-password" ) {
      this.isPathLogin = null;
      this.isPathSignUp = null;
      this.isPathForgotPswd = "/login/forgot-password";
    }else{
      this.isPathLogin = "/login";
      this.isPathSignUp = null;
      this.isPathForgotPswd = null;
    }


  }

}
