import { ToastrService } from 'ngx-toastr'
import { Observable } from 'rxjs'
import { catchError, debounceTime, finalize, map, switchMap } from 'rxjs/operators'
import { ResponseImageDTO } from 'src/app/common/trx/response-image-dto'

import { Component, NgZone, OnInit } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import {
    AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask
} from '@angular/fire/storage'
import { FormControl } from '@angular/forms'
import { Router } from '@angular/router'

//import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
//import { AngularFirestore } from 'angularfire2/firestore';
import { Helperutil } from '../../../common/helper/helperutil'
import { KuaaTransactionDto } from '../../../common/trx/kuaa-transaction-dto'
import { CategoryDto } from '../../../model/dto/category/category-dto'
import { CourseDto } from '../../../model/dto/course/course-dto'
import { TokenService } from '../../../service/authorization/token.service'
import { CategoryService } from '../../../service/category/category.service'
import { CourseService } from '../../../service/course/course.service'
import { AuthService } from '../../../shared/services/auth.service'

@Component({
  selector: 'app-course-add',
  templateUrl: './course-add.component.html',
  styleUrls: ['./course-add.component.css']
})
export class CourseAddComponent implements OnInit {

  isSaveProcess: boolean = false;
  objCourseDto: CourseDto = new CourseDto();
  objKuaaTransactionDto: KuaaTransactionDto = new KuaaTransactionDto();
  name = '';
  file: File;
  fileBanner2: File;
  completed: boolean = false;
  uploadPercent: Observable<number>;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadState: Observable<string>;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;
  uploads: any[];
  idClient: string;
  entityTransfer: ResponseImageDTO[] = [];
  objListCategories: CategoryDto[] = [];


  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    private toastr: ToastrService,
    private objCourseService: CourseService,
    private objCategoryService: CategoryService,
    private tokenService: TokenService,
    private storage: AngularFirestore,
    private store: AngularFireStorage
  ) {

  }

  ngOnInit(): void {
    //this.objCourseDto.name ='CURSO ';
    this.idClient  = Math.random().toString(36).substring(2);
    this.uploads = [];
    this.loadCategory();
  }

  loadCategory(): void{
    const sToken = this.tokenService.getToken();
    this.objCategoryService.getCategories( sToken ).subscribe(
      data => {
          this.objListCategories = data
        },
        err => {
          console.error(err);
        }
    );
  };


  saveHead() {
    this.toastr.info('Process...', Helperutil.S_TOAS_TITLE_HEAD, {
      timeOut: Helperutil.I_TOAS_TIMEOUT, positionClass: Helperutil.S_TOAS_POSITIONCLASS_TOP_RIGHT
    });


    //this.objCourseDto.name ='CURSO 6';
    /*
    this.objCourseDto.title ='CURSO 6';
    this.objCourseDto.subtitle ='CURSO 6';
    this.objCourseDto.description ='CURSO 6';
    this.objCourseDto.link ='';
    this.objCourseDto.banner1 ='https://storage.googleapis.com/publickuaa/static/img/courses.jpg';
    this.objCourseDto.banner2 ='https://storage.googleapis.com/publickuaa/static/img/courses.jpg';
    this.objCourseDto.banner3 ='https://storage.googleapis.com/publickuaa/static/img/courses.jpg';
    this.objCourseDto.banner4 ='https://storage.googleapis.com/publickuaa/static/img/courses.jpg';
    this.objCourseDto.banner5 ='https://storage.googleapis.com/publickuaa/static/img/courses.jpg';
    this.objCourseDto.status ='1';
    this.objCourseDto.durationContent ='2';
    this.objCourseDto.durationPractice ='3';
    this.objCourseDto.startDate ='';
    this.objCourseDto.knowledgeArea ='';
    this.objCourseDto.department ='';
    this.objCourseDto.lavel ='';
    this.objCourseDto.classNumber ='2';
    this.objCourseDto.codeParent ='';
    */
    //this.objCourseDto.banner1 = 'https://storage.googleapis.com/publickuaa/static/img/courses.jpg';
    //const id = Math.random().toString(36).substring(2);
    //const path = 'course'+'/'+this.idClient+'/'+this.file.name;
    //this.saveFile(this.file, path,'b1');
    //this.saveFile(this.fileBanner2, path,'b2');
    const sToken = this.tokenService.getToken();
    this.isSaveProcess = true;
    this.uploadImages(sToken).then(data1 => {
      this.processSave(sToken);
    });



  }

  onFileSelect(event, bannerDestine) {
    if (event.target.files.length > 0) {
      /*
      if( bannerDestine == 'b1' ){
        this.file = event.target.files[0];
      }else if( bannerDestine == 'b1' ){
        this.fileBanner2 = event.target.files[0];
      }else{
      }
      */
     const uploadTrack = { file: event.target.files[0],
                            subpath: bannerDestine
                         };
      this.uploads.push(uploadTrack);

    }
  }

  async importImages() {
    return new Promise(resolve => {
      for (const file of this.uploads) {

        const path = 'course'+'/'+this.idClient+'/'+file.subpath+'/'+file.file.name;
        const ref = this.store.ref(path);
        const task = this.store.upload(path, file.file).then((f) => {
          return f.ref.getDownloadURL().then((url) => {
            if( file.subpath == 'b1' ){
              this.objCourseDto.banner1 = url;
            }else if( file.subpath == 'b2'){
              this.objCourseDto.banner2 = url;
            }else if( file.subpath == 'b3'){
              this.objCourseDto.banner3 = url;
            }else if( file.subpath == 'b4'){
              this.objCourseDto.banner4 = url;
            }else if( file.subpath == 'bc'){
              this.objCourseDto.fileContent = url;
            }else {
              this.objCourseDto.banner5 = url;
            }
          })
        });
        resolve(task);
      }
    });

  }

  uploadFile() {
    this.completed = false;
    //const id = Math.random().toString(36).substring(2);
    const path = 'course'+'/'+this.idClient+'/'+this.file.name;
    this.saveFile(this.file,path,'b1');
    /*
    const task = this.store.upload(filePath, this.file);
    task.snapshotChanges().pipe(
      finalize(() => this.downloadURL = this.store.ref(filePath).getDownloadURL() )
   )
  .subscribe()
  */
    /*
    const task = this.store.upload(filePath, this.file);
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
      map( s => {
        })
      ).subscribe();
    };
    */
   /*
   const id = Math.random().toString(36).substring(2);
   this.ref = this.store.ref(id);
   this.task = this.ref.put(this.file);
   this.uploadState = this.task.snapshotChanges().pipe(map(s => s.state));
   this.uploadProgress = this.task.percentageChanges();
   //this.downloadURL =  this.task.downloadURL();
   //this.downloadURL =  this.task.then(  );
   */
  }

  saveFile(file, filePath, destine ){
    //const filePath = this.file.name;
    //const filePath = file.name;
    const task = this.store.upload(filePath, file).then(() => {
                const ref = this.store.ref(filePath);
                const downloadURL =  ref.getDownloadURL().subscribe(url => {
                    const Url = url; // for ts
                    if( destine == 'b1' ){
                      this.objCourseDto.banner1 = Url
                    }else if( destine == 'b2'){
                      this.objCourseDto.banner2 = Url
                    }else if( destine == 'b3'){
                      this.objCourseDto.banner3 = Url
                    }else if( destine == 'b4'){
                      this.objCourseDto.banner4 = Url
                    }else {
                      this.objCourseDto.banner5 = Url
                    }
                    return Url;
                })
              });
  }

  async uploadImages(sToken) {
    return new Promise(resolve => {
      let iCount =0;
      for (const file of this.uploads) {

        //const path = 'course'+'/'+this.idClient+'/'+file.subpath+'/'+file.file.name;
        const http$ = this.objCourseService.uploadFile(file.file, sToken,this.idClient+file.subpath );
        http$.pipe(
          map(el => {
            this.toastr.clear();
            this.objKuaaTransactionDto = el;
            if (this.objKuaaTransactionDto.code == '00000') {

              this.entityTransfer = [];

              this.entityTransfer =  this.objKuaaTransactionDto.entityTransfer;
              this.entityTransfer[0];
              let sFileUrl = this.entityTransfer[0].sFileUrl;

              //entity = entityTransfer[0];
              iCount = iCount + 1;
              if( file.subpath == 'b1' ){
                this.objCourseDto.banner1 = sFileUrl;
              }else if( file.subpath == 'b2'){
                this.objCourseDto.banner2 = sFileUrl;
              }else if( file.subpath == 'b3'){
                this.objCourseDto.banner3 = sFileUrl;
              }else if( file.subpath == 'b4'){
                this.objCourseDto.banner4 = sFileUrl;
              }else if( file.subpath == 'bc'){
                this.objCourseDto.fileContent = sFileUrl;
              }else {
                this.objCourseDto.banner5 = sFileUrl;
              }

              if( iCount == 4 ){
                iCount =0;
                resolve( this.entityTransfer );
              }

              /*
              this.toastr.success(this.objKuaaTransactionDto.mensaje, Helperutil.S_TOAS_TITLE_HEAD, {
                timeOut: Helperutil.I_TOAS_TIMEOUT, positionClass: Helperutil.S_TOAS_POSITIONCLASS_TOP_RIGHT
              });
              */

              //this.router.navigate(['/dasboard/planlist']);
            }
            return el;
          })).subscribe(
            el => {
                  //return el;

            },err => {
              this.toastr.clear();
              if (err.error.error == Helperutil.S_SESSION_NOT_AUTORIZED) {
                this.toastr.clear();
                this.toastr.error(Helperutil.S_SESSION_ENDED, Helperutil.S_TOAS_TITLE_HEAD, {
                  timeOut: 3000, positionClass: Helperutil.S_TOAS_POSITIONCLASS_BTN_CENTER
                });
                this.isSaveProcess = false;
                //this.router.navigate(['/']);
              } else {
                this.toastr.warning(err.error.mensaje, Helperutil.S_TOAS_TITLE_HEAD, {
                  timeOut: Helperutil.I_TOAS_TIMEOUT, positionClass: Helperutil.S_TOAS_POSITIONCLASS_BTN_RIGHT
                });
              }
            },
          );

        /*
        const ref = this.store.ref(path);
        const task = this.store.upload(path, file.file).then((f) => {
          return f.ref.getDownloadURL().then((url) => {
            if( file.subpath == 'b1' ){
              this.objCourseDto.banner1 = url;
            }else if( file.subpath == 'b2'){
              this.objCourseDto.banner2 = url;
            }else if( file.subpath == 'b3'){
              this.objCourseDto.banner3 = url;
            }else if( file.subpath == 'b4'){
              this.objCourseDto.banner4 = url;
            }else {
              this.objCourseDto.banner5 = url;
            }
          })
        });
        */
        //resolve(task);
      }
    });

  }

  processSave(sToken){
    //const sToken = this.tokenService.getToken();

    this.objCourseDto.idClient =  this.idClient;
    const http$ = this.objCourseService.save(this.objCourseDto, sToken);
    http$.pipe(
      map(el => {
        this.toastr.clear();
        this.objKuaaTransactionDto = el;
        if (this.objKuaaTransactionDto.code == '00000') {
          this.toastr.success(this.objKuaaTransactionDto.mensaje, Helperutil.S_TOAS_TITLE_HEAD, {
            timeOut: Helperutil.I_TOAS_TIMEOUT, positionClass: Helperutil.S_TOAS_POSITIONCLASS_TOP_RIGHT
          });
          setTimeout(() => {
            this.isSaveProcess = false;
            this.router.navigate(['/dashboard/courselist']);
          }, 1000);
          //this.router.navigate(['/dasboard/planlist']);
        }
        return el;
      })).subscribe(
        err => {
          this.toastr.clear();
          if (err.error.error == Helperutil.S_SESSION_NOT_AUTORIZED) {
            this.toastr.clear();
            this.toastr.error(Helperutil.S_SESSION_ENDED, Helperutil.S_TOAS_TITLE_HEAD, {
              timeOut: 3000, positionClass: Helperutil.S_TOAS_POSITIONCLASS_BTN_CENTER
            });
            this.isSaveProcess = false;
            //this.router.navigate(['/']);
          } else {
            this.toastr.warning(err.error.mensaje, Helperutil.S_TOAS_TITLE_HEAD, {
              timeOut: Helperutil.I_TOAS_TIMEOUT, positionClass: Helperutil.S_TOAS_POSITIONCLASS_BTN_RIGHT
            });
          }
        },
      );
  }



}

