import { Component, OnInit } from '@angular/core';
import { devActive } from 'src/environments/environment';

@Component({
  selector: 'app-logindefault',
  templateUrl: './logindefault.component.html',
  styleUrls: ['./logindefault.component.css']
})
export class LogindefaultComponent implements OnInit {

  devActive = devActive;

  constructor() { }

  ngOnInit(): void {
  }

}
