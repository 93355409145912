<div class="row">
    <div class="col-12 justify-content-start  ">
        <div>
            <h1 class="sections_title" style="margin-bottom: 1vw !important;" >Lo que <b>nuestros guías</b> están diciendo </h1>
        </div>
    </div>
</div>

<div class="container-mate">
    <img style="width: 85vw;" src="https://storage.googleapis.com/publickuaa/static/img/nivel6/PERFIL-INSTRUCTOR.png">
    <div class="centrado text-white">
        <div class="row">
            <div class="col-12">
                <h4 class="title-experience">MOVE TO TRANSFORM</h4>
            </div>
            <div class="col-12">
                <p class="name"><b>Óscar</b> Quintero</p>
            </div>
            <div class="d-none d-sm-none d-sm-block d-lg-block d-xl-block">
                <div style="width: 50%; font-size: 20px;">
                    <p class="blue-text">“A través del movimiento encuentras niveles de transformación y desarrollo más allá de tu propia comprensión”</p>
                </div>
            </div>
        </div>
        <a href="/home/preview/eef2fb52-9588-4f99-a859-2c13e06989a7/0" class="btn btn-more" >¡IR A LA <b>EXPERIENCIA!</b></a>
    </div>
</div>