<!-- 
<div class="displayTable">
  <div class="displayTableCell">
    <div class="authBlock">
      <h3>Reset Password</h3>
      <p class="text-center">Please enter your email address to request a password reset.</p>
      <div class="formGroup">
        <input type="email" class="formControl" placeholder="Email Address" #passwordResetEmail required>
      </div>
      <div class="formGroup">
        <input type="submit" class="btn btnPrimary" value="Reset Password" (click)="authService.ForgotPassword(passwordResetEmail.value)">
      </div>
    </div>
    <div class="redirectToLogin">
      <span>Go back to ? <span class="redirect" routerLink="/sign-in">Log In</span></span>
    </div>
  </div>
</div>
-->

<body>
  <div class="divBody">
      <div class="row bodyRow">
          <div class="col-md-2 mx-auto divFakeOne"></div>
          <div class="col-md-4 mx-auto divMsgWelcome">
              <div class="row  ">
                  <div class="col-md-12 mx-auto contentBannerBody">
                      <p class="loginBodyBannerTitle1"><strong>Bienvenido,</strong>
                          <br> Inicia sesión con tu
                          <br>cuenta <strong>KUAÁ</strong> 
                      </p>
                      <hr class="lineHorizon">                      
                  </div>
                  <img class="imgFindYou" src="https://storage.googleapis.com/publickuaa/static/img/login/FRASE-FIND-YOUR.png">
              </div>
          </div> 
          <div class="col-md-4 mx-auto loginFormContent">
              <div class="row menuItemLoginSelect contentFormBody">
                  <div class="col-md-12 mx-auto loginBodyBannerFormTitle ">
                      <span class="formloginTitle" > RECUPERAR CUENTA </span>
                  </div>
                  <div class="col-md-12 mx-auto">
                      <div class="row"> 
                          <div class="col-md-12 mx-auto">
                            <input type="text" class="formControlLogin" #passwordResetEmail required placeholder="Usuario correo">
                        </div> 
                          <div class="col-md-12 mx-auto">
                              <br>
                          </div> 
                          <div class="col-md-12 mx-auto">
                              <input type="button" class="btn btnPrimary formControlButton" value="RECUPERAR" (click)="authService.ForgotPassword(passwordResetEmail.value)">                              
                          </div>
                          <div class="col-md-12 mx-auto">
                              <br>
                              <a href="/login" class="formloginTitle" >
                                  <span class="formloginTitle" > Regresar </span>
                              </a>
                          </div>
                          <div class="col-md-12 mx-auto">
                              <br>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row" >
                  <div class="col-md-4 mx-auto">                      
                  </div>
                  <div class="col-md-4 mx-auto">
                      <hr class="lineHorizon2">
                  </div>
                  <div class="col-md-4 mx-auto">
                  </div>
              </div>
          </div>
          <div class="col-md-2 mx-auto"></div>
      </div>
  </div>
</body>