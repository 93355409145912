import { DateTime } from 'luxon'
import { CreditCardsService } from 'src/app/service/payments/credit-cards.service'
import { SubscriptionPlansService } from 'src/app/service/payments/subscription-plans.service'
import { UserService } from 'src/app/service/payments/user.service'
import { AuthService } from 'src/app/shared/services/auth.service'
import Swal from 'sweetalert2'

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css', '../profile-home/bootstrap.css']
})
export class AccountComponent implements OnInit {

  public userData: object = { firstName: '', lastName: '', cityCode: '', displayName: '', email: '', billingAddress: { phone: '' }, activePlanName: '', subscriptionExpiration: '', photoURL: '', autoRenewal: true }
  public nextBilling
  public userID
  public methodData: string
  public planValue: string
  public colorText: string
  constructor(
    private userService: UserService,
    private creditCardsService: CreditCardsService,
    private subscriptionPlansService: SubscriptionPlansService,
    public authService: AuthService,
    private router: Router,
    private routeParams: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.userID = this.routeParams.snapshot.paramMap.get('id')
    this.getSuscriptor()
    this.getCurrentCreditCards()
  }

  private getSuscriptor(): void {
    this.userService.get().subscribe(
      data => {
        const d1 = DateTime.fromSeconds(data['subscriptionExpiration']['_seconds'])
        this.nextBilling = d1.toFormat('yyyy-MM-dd')
        if (!data['autoRenewal']) {
          const cancelDate = DateTime.fromSeconds(data['subscriptionCancelledAt']['_seconds'])
          this.colorText = 'text-danger'
          this.nextBilling = `Tu suscripción a Kuaá fue cancelada el ${cancelDate.toFormat('yyyy-MM-dd H:mm a')}, puedes disfrutar de nuestro contenido hasta: ${d1.toFormat('yyyy-MM-dd')}  `
        }
        this.userData = data
        this.getSubscriptionPlansService(data['activePlanId'])
      }
    )
  }

  public getCurrentCreditCards() {
    this.creditCardsService.getAll().subscribe(
      creditcardData => {
        creditcardData.forEach(creditcard => {
          if (creditcard.selected) {
            this.methodData = `${creditcard.franchise} - ${creditcard.maskedNumber.slice(-6)}`
          }
        })
      }
    )
  }

  public getSubscriptionPlansService(id) {
    this.subscriptionPlansService.getById(id).subscribe(
      result => {
        const formatterPeso = new Intl.NumberFormat('es-CO', {
          style: 'currency',
          currency: 'COP',
          minimumFractionDigits: 0
        })
        this.planValue = formatterPeso.format(result.value)
      },
      error => {
        console.error(`Error al obtener los planes --> ${error}`)
      }
    )
  }

  public cancelPlan() {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¡No te vayas! Estamos trabajando para mejorar tu experiencia.',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Continuar'
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        this.userService.unsubscribe()
          .subscribe(
            () => {

              Swal.fire({
                title: '¡Hasta pronto! ',
                text: 'Cuéntanos cómo podemos mejorar para tenerte de vuelta.',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Omitir y salir',
                confirmButtonText: 'Escríbenos'
              }).then((email) => {
                if (email.isConfirmed) {
                  window.location.href = "mailto:ayuda@kuaa.com.co";
                }
              })
              this.getSuscriptor()
            },
            (error) => {
              Swal.fire('Lo sentimos', "Algo ha salido mal, por favor intenta de nuevo más tarde", 'error');
              console.error('Error cancelling subscription: ', error);
            }
          )
      }
    })
  }



  public changePlan() {

    this.router.navigate([`/home/plans/${this.userID}/${this.userData['activePlanId']}`])
  }
}
