<div class="modules">

  <div class="container-fluid px-0">
    <div class="hero-bg" style="background-image: url({{objModuleDto.banner4}});">
      <div class="btn-back">
        <a href="/home"> <img id="back" src="/assets/back_icon.png"></a>
      </div>
      <div style="text-align: center;" class="contain-module-icon d-flex justify-content-center">
        <img src="{{objModuleDto.banner2}}" class="module-icon">
      </div>
    </div>
  </div>

  <div class="div container-fluid modules pb-5">
    <div class="ps-5 pe-5">
      <div class="row">
        <div class="col-12 justify-content-start  ">
          <h1 class="sections_title"> <b>Disponibles</b> en este módulo </h1>
        </div>
      </div>
      <app-slider *ngIf="slides" [slides]="slides" [navigation]="true" [spaceBetween]="100" [loop]="true"
        [extraImgClass]="'img-slide'">
      </app-slider>
    </div>

    <div class="ps-5 pe-5">
      <div class="row">
        <div class="col-12">
          <h2 class="sections_title">Guías <b>destacados</b></h2>
        </div>
      </div>
      <app-slider *ngIf="slidesInstructors" [slides]="slidesInstructors" [navigation]="true" [spaceBetween]="100"
        [loop]="true" [extraImgClass]="'img-slide'">
      </app-slider>
    </div>

    <div class="row">
      <div class="d-none d-md-block col-md-12">
        <div class="mt-5 ps-5 pe-5">
          <div class="row">
            <div class="col-12">
              <h2 class="sections_title">Lo que <b>nuestros guías</b> están diciendo</h2>
            </div>
          </div>

          <div class="container-mate">
            <img style="width: 85vw;" src="{{this.fellowsTraveler[0]['src']}}">
            <div class="centrado-modules text-white">
              <div class="row">
                <div class="col-12">
                  <h4 class="title-experience" style="text-transform: uppercase;">{{this.fellowsTraveler[0]['title']}}
                  </h4>
                </div>
                <div class="col-12">
                  <p class="name" [innerHtml]="instructor"></p>
                </div>
                <div class="d-none d-sm-none d-sm-block d-lg-block d-xl-block">
                  <div style="width: 50%; font-size: 20px;">
                    <p class="blue-text">“{{this.fellowsTraveler[0]['text']}}”</p>
                  </div>
                </div>
              </div>
              <a routerLink="{{this.fellowsTraveler[0]['link']}}"
                class="btn btn-more">{{this.fellowsTraveler[0]['btn']}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 ps-5 pe-5">
      <div class="row">
        <div class="col-12">
          <h2 class="sections_title"><b>Descubre</b> más <b>conocimiento</b> en otros módulos</h2>
        </div>
      </div>
      <app-fullness></app-fullness>
    </div>
  </div>
</div>
