import { BillingComponent } from 'src/app/components/payments/billing/billing.component'
import { SerieVideosComponent } from 'src/app/components/series/serie-videos/serie-videos.component'

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

//Article
import { ArticleComponent } from '../../components/article/article/article.component'
import { CategoryaddComponent } from '../../components/category/categoryadd/categoryadd.component'
import {
    CategorylistComponent
} from '../../components/category/categorylist/categorylist.component'
import {
    CategoryHomeComponent
} from '../../components/content/category/category-home/category-home.component'
import { PreviewComponent } from '../../components/content/preview/preview.component'
import { ViewComponent } from '../../components/content/view/view.component'
import { CourseAddComponent } from '../../components/course/course-add/course-add.component'
import { CourseListComponent } from '../../components/course/course-list/course-list.component'
import { DashboardComponent } from '../../components/dashboard/dashboard.component'
import { DefaultComponent } from '../../components/dashboard/default/default.component'
import { ForgotPasswordComponent } from '../../components/forgot-password/forgot-password.component'
import { HomeComponent } from '../../components/home/home/home.component'
import { HomedefaultComponent } from '../../components/home/homedefault/homedefault.component'
import { HostelComponent } from '../../components/hostel/hostel/hostel.component'
//Hostel
import {
    HosteldefaultComponent
} from '../../components/hostel/hosteldefault/hosteldefault.component'
import {
    InstructorHomeComponent
} from '../../components/instructor/instructor-home/instructor-home.component'
//import { LoginComponent } from '../../components/login/login/login.component';
import { LoginHeadComponent } from '../../components/login/login-head/login-head.component'
import {
    ChangePasswordComponent
} from '../../components/login/public/change-password/change-password.component'
//Login
import { LoginComponent } from '../../components/login/public/login/login.component'
import {
    LogindefaultComponent
} from '../../components/login/public/logindefault/logindefault.component'
import {
    PlansPaymentComponent
} from '../../components/login/public/plans-payment/plans-payment.component'
import {
    RecoverpasswordComponent
} from '../../components/login/public/recoverpassword/recoverpassword.component'
import { RegisterComponent } from '../../components/login/public/register/register.component'
//Podcast
import { PodcastComponent } from '../../components/podcast/podcast/podcast.component'
import { PodcastlistComponent } from '../../components/podcast/podcastlist/podcastlist.component'
import {
    PolicyhabeasdataComponent
} from '../../components/policyhabeasdata/policyhabeasdata.component'
import { PrivacypolicyComponent } from '../../components/privacypolicy/privacypolicy.component'
import { AccountComponent } from '../../components/profile/account/account.component'
import { ProfileHomeComponent } from '../../components/profile/profile-home/profile-home.component'
import { ShowPlansComponent } from '../../components/profile/show-plans/show-plans.component'
import { PublicComponent } from '../../components/public/public.component'
import { SearchComponent } from '../../components/search/search.component'
import { SignInComponent } from '../../components/sign-in/sign-in.component'
import { SignUpComponent } from '../../components/sign-up/sign-up.component'
import {
    SubscriberHomeComponent
} from '../../components/subscriber/subscriber-home/subscriber-home.component'
import { VerifyEmailComponent } from '../../components/verify-email/verify-email.component'
import { AuthGuard } from '../../shared/guard/auth.guard'
import { LoginActionsGuard } from '../../shared/guard/login-actions.guard'
import { PaymentGuard } from '../../shared/guard/payment.guard'

const routes: Routes = [
  //{ path: '', redirectTo: '/public', pathMatch: 'full' },
  { path: 'public', component: PublicComponent },
  { path: 'privacypolicy', component: PrivacypolicyComponent },
  { path: 'habeasdata', component: PolicyhabeasdataComponent },
  {
    path: 'login', component: LogindefaultComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'signup', component: RegisterComponent },
      { path: 'forgot-password', component: RecoverpasswordComponent },
      { path: 'plans-payment', component: PlansPaymentComponent, canActivate: [LoginActionsGuard] },
      { path: 'change-password', component: ChangePasswordComponent },
    ]
  },
  {
    path: 'dashboard', component: DefaultComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardComponent },
      { path: 'courselist', component: CourseListComponent },
      { path: 'courseadd', component: CourseAddComponent },
      { path: 'categoryadd', component: CategoryaddComponent },
      { path: 'categorylist', component: CategorylistComponent },
      { path: 'subscriber-home', component: SubscriberHomeComponent }
    ]
  },
  {
    path: 'home', component: HosteldefaultComponent, canActivate: [AuthGuard, PaymentGuard],
    children: [
      { path: '', component: HostelComponent },
      { path: 'preview/:id/:idModule', component: PreviewComponent },
      { path: 'view/:id', component: ViewComponent },
      { path: 'category/:id', component: CategoryHomeComponent },
      { path: 'instructor/:id', component: InstructorHomeComponent },
      { path: 'instructor/:id/:idCourse', component: InstructorHomeComponent },
      { path: 'profile/:id', component: ProfileHomeComponent },
      { path: 'account/:id', component: AccountComponent },
      { path: 'plans/:id/:idPlan', component: ShowPlansComponent },
      { path: 'podcast/:id', component: PodcastComponent },
      { path: 'article/:id', component: ArticleComponent },
      { path: 'series/:serieId/videos/:id', component: SerieVideosComponent },
      { path: 'allPodcast', component: PodcastlistComponent },
      { path: 'myList', component: SearchComponent },
      { path: 'billing-user', component: BillingComponent },
    ]
  },
  // { path: 'credit_cards', component: CreditCardsComponent },
  { path: 'billing', component: BillingComponent },
  { path: 'verify-email-address', component: VerifyEmailComponent },
  { path: 'searchBy/:criteria', component: SearchComponent },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }

