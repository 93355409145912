import { PaymentsService } from 'src/app/service/payments/payments.service'
import { UserService } from 'src/app/service/payments/user.service'
import User from 'src/app/shared/types/user'
import { ActivatedRoute, Router } from '@angular/router';
import DateTime from 'src/app/shared/utils/datetime'
import Swal from 'sweetalert2'

import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {
  payments = [];
  userSubscription: User;
  public path = ''

  constructor(
    private paymentsService: PaymentsService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) {
    this.path = this.activatedRoute.snapshot.routeConfig.path
  }

  ngOnInit(): void {
    this.loadSubscriptionInfo();
    this.loadPayments();
  }

  loadPayments() {
    this.paymentsService.getAll().subscribe(
      (payments) => {
        this.payments = payments;
      },
      (error) => {
        console.error("Error getting user's payments: ", error);
      }
    )
  }

  loadSubscriptionInfo() {
    this.userService.info()
      .subscribe(
        (userInfo) => {
          this.userSubscription = userInfo;
        },
        (error) => {
          console.error("Error getting the user's info: ", error);
        }
      )
  }

  refreshPayments() {
    this.payments = [];
    this.loadPayments();
  }

  cancelSubscription() {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¡No te vayas! Estamos trabajando para mejorar tu experiencia.',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Continuar'
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        this.userService.unsubscribe( )
          .subscribe(
            () => {

              Swal.fire({
                title: '¡Hasta pronto! ',
                text: 'Cuéntanos cómo podemos mejorar para tenerte de vuelta.',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Omitir y salir',
                confirmButtonText: 'Escríbenos'
              }).then( (email)=>{
                if(email.isConfirmed){
                  window.location.href = "mailto:ayuda@kuaa.com.co";
                }
              })
              this.loadSubscriptionInfo();
            },
            (error) => {
              Swal.fire('Lo sentimos', "Algo ha salido mal, por favor intenta de nuevo más tarde", 'error');
              console.error('Error cancelling subscription: ', error);
            }
          )
      }
    });
  }
}
