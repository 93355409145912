<div class="row">
    <div class="col-12 justify-content-start  ">
        <div>
            <h1 class="sections_title" style="margin-bottom: 1vw !important;" ><b>Conoce</b> a los guías </h1>
            <!--<p class="sections_subtitle">Tus acompañantes en el viaje hacia el
                bienestar y el balance.</p>-->
        </div>

    </div>
</div>

<!-- <app-slider
  *ngIf="slides"
  [loop]="true"
  [navigation]="true"
  [slides]="slides"
  [spaceBetween]="5"
  [extraClasses]="'mt-5'"
>
</app-slider> -->

<app-slider
  *ngIf="slidesOrdered"
  [loop]="true"
  [navigation]="true"
  [slides]="slidesOrdered"
  [spaceBetween]="5"
  [extraClasses]="'mt-5'"
>
</app-slider>
