import { DateTime } from 'luxon'
import { SubscriptionPlansService } from 'src/app/service/payments/subscription-plans.service'
import { UserService } from 'src/app/service/payments/user.service'
import Swal from 'sweetalert2'

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-show-plans',
  templateUrl: './show-plans.component.html',
  styleUrls: ['./show-plans.component.css', '../profile-home/bootstrap.css']
})
export class ShowPlansComponent implements OnInit {

  public idPlan
  public idUser
  public plans
  public nextBilling
  constructor(
    private subscriptionPlansService: SubscriptionPlansService,
    private routeParams: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.idUser = this.routeParams.snapshot.paramMap.get('id')
    this.idPlan = this.routeParams.snapshot.paramMap.get('idPlan')
    this.getAllPlans()
    //this.getUser()
  }

  public getAllPlans(){
    this.subscriptionPlansService.getAll().subscribe(
      response => {
        this.plans = response
      }
    )
  }

  public getUser(){
    this.userService.get().subscribe(
      data => {
        const d1 = DateTime.fromSeconds(data['subscriptionExpiration']['_seconds'])
        this.nextBilling = d1.plus({ year: 1 }).toFormat('yyyy-MM-dd H:mm a')

      }
    )
  }

  public updateUserPlan(id, name){
    let newSubscriptionExpiration = name == 'Mensual' ? { month: 1 } : { year: 1 }
    const newUserData = {
      activePlanId: id,
      activePlanName: name,
      //subscriptionExpiration: DateTime.now().plus(newSubscriptionExpiration).toFormat('yyyy-MM-dd H:mm a')
    }

    this.userService.update(newUserData).subscribe(
      data => {
        this.router.navigate([`/home/account/${this.idUser}`])
        Swal.fire({
          title: 'Cambio de plan exitoso',
          text: 'Se cambió correctamente el plan, puedes continuar disfrutando del mundo de Kuaá',
          icon: 'success',
          background: 'black',
          color: '#14b8ec',
          confirmButtonColor: '#14b8ec',
          confirmButtonText: 'Gracias'
        })
      },
      error => {
        Swal.fire({
          title: 'Error al cambiar el plan',
          text: 'Se presentó un error al cambiar tu plan de Kuaá; por favor comunícate con el administrador.',
          icon: 'error',
          background: 'black',
          color: '#14b8ec'
        })
      }
    )
  }

}
