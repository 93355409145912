<div class="card-list">
  <div class="card mb-4" *ngFor="let creditCard of creditCards;">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <h5 class="card-title">{{creditCard.franchise}}</h5>
      </div>

      <table class="table table-borderless">
        <tbody>
          <tr>
            <th>Número:</th>
            <td>{{creditCard.maskedNumber.substring(creditCard.maskedNumber.length - 6)}}</td>
          </tr>
          <tr>
            <th>Agregada en:</th>
            <td>{{creditCard.createdAt ? (creditCard.createdAt._seconds | dateFromSeconds) : ''}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
