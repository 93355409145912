<div class="d-flex align-items-center justify-content-between">
  <h2 class="mb-3">Mis T.C.</h2>

  <button type="button" class="btn btn-info mb-3 d-flex align-items-center" (click)="addCreditCard()"><span class="material-icons">add_card</span><span class="ms-2">Nueva</span></button>
</div>

<app-spinner *ngIf="isLoading; else creditCardList"></app-spinner>
<ng-template #creditCardList>
  <div class="card-list">
    <div class="card mb-4" *ngFor="let creditCard of creditCards; index as i; trackBy: trackByFn">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <h5 class="card-title">{{creditCard.franchise}}</h5>

          <div class="d-flex align-items-center mb-2">
            <span *ngIf="creditCard.selected; else elseCStatus" class="text-success">Activa</span>
            <ng-template #elseCStatus>
              <app-button variant="outline-danger" extraClasses="btn-sm" (click)="deleteCreditCard(creditCard.id)">Eliminar</app-button>
              <app-button variant="outline-primary" extraClasses="btn-sm ms-2" (click)="selectCreditCard(creditCard.id)">Activar</app-button>
            </ng-template>
          </div>
        </div>

        <table class="table table-borderless">
          <tbody>
            <tr>
              <th>Número:</th>
              <td>{{creditCard.maskedNumber.substring(creditCard.maskedNumber.length - 6)}}</td>
            </tr>
            <tr>
              <th>Agregada en:</th>
              <td>{{creditCard.createdAt ? (creditCard.createdAt._seconds | dateFromSeconds) : ''}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<modal [show]="openNewForm" title="Nueva Tarjeta de Crédito" (close)="onCloseNewForm()">
  <credit-card-form (create)="onCreate()"></credit-card-form>
</modal>
