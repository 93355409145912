// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  cryptoKey: '00f15c26-dabf-4f77-bf05-4592198cc0f7',
  firebase: {
    apiKey: "AIzaSyDnvWdhlVBKLDnDtO5iqIkkvgpp-iTgPks",
    authDomain: "kuaadev.firebaseapp.com",
    databaseURL: "https://kuaadev-default-rtdb.firebaseio.com",
    projectId: "kuaadev",
    storageBucket: "kuaadev.appspot.com",
    messagingSenderId: "448284579599",
    appId: '1:448284579599:web:0248f1c4ac0d3dff95d07a'
  },
  S_CORE_SERVICE_API_V1_BASE_URL: 'https://dev-apicore-cmlscukhsq-uc.a.run.app',
  // S_CORE_SERVICE_API_PAYMENT_BASE_URL: 'http://localhost:8080'
  // S_CORE_SERVICE_API_PAYMENT_BASE_URL: 'https://staging-kuaa-api.tintosoft.co'
  S_CORE_SERVICE_API_PAYMENT_BASE_URL: 'https://kuaa-api.tintosoft.co'
};
export const devActive = (environment.S_CORE_SERVICE_API_V1_BASE_URL.indexOf('https://dev') == 0 || environment.S_CORE_SERVICE_API_V1_BASE_URL.indexOf('https://loc') == 0)? true : false;
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
